import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxStripeModule } from 'ngx-stripe';
import { AppRoutingModule } from '../../app-routing.module';
import { MatImportModule } from '../../mat-import.module';
import { NavbarModule } from '../../navbar/navbar.module';
import { AdminHelpComponent } from './admin-help/admin-help.component';
import { AdminComponent } from './admin.component';
import { ClientListExperimentalCandidateDialogComponent } from './client-list/client-list-experimental-candidate-dialog/client-list-experimental-candidate-dialog.component';
import { ClientListComponent } from './client-list/client-list.component';
import { CustomRequestListUpdateDialogComponent } from './custom-request-list/custom-request-list-update-dialog/custom-request-list-update-dialog.component';
import { CustomRequestListComponent } from './custom-request-list/custom-request-list.component';
import { ExtractInvoicesComponent } from './extract-invoices/extract-invoices.component';
import { OrganizationListExpirationDateDialogComponent } from './organization-list/organization-list-expiration-date-dialog/organization-list-expiration-date-dialog.component';
import { OrganizationListFreezeDialogComponent } from './organization-list/organization-list-freeze-dialog/organization-list-freeze-dialog.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AdminComponent,
    AdminHelpComponent,
    ExtractInvoicesComponent,
    ClientListComponent,
    OrganizationListComponent,
    ClientListExperimentalCandidateDialogComponent,
    OrganizationListExpirationDateDialogComponent,
    OrganizationListFreezeDialogComponent,
    CustomRequestListComponent,
    CustomRequestListUpdateDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxStripeModule,
    AppRoutingModule,
    MatImportModule,
    NavbarModule,
    TranslateModule.forChild({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [],
  bootstrap: [],
})
export class AdminModule {}
