import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { CustomValidators } from 'src/app/shared/validators';
import { CustomErrorStateMatcher } from '../../../customer-profile/customer-profile.component';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-client-list-experimental-candidate-dialog',
  templateUrl: 'client-list-experimental-candidate-dialog.component.html',
})
export class ClientListExperimentalCandidateDialogComponent implements OnInit {
  formGroup = new FormGroup(
    {
      expirationDate: new FormControl(null, Validators.required),
    },
    {
      updateOn: 'change',
      validators: CustomValidators.Form,
    },
  );
  errorStateMatcher = new CustomErrorStateMatcher();
  minDate: Date;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private clientService: ClientService,
    public dialogRef: MatDialogRef<ClientListExperimentalCandidateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const expirationDate = this.formGroup.value.expirationDate;
    // Sadly JS Date parser is ignoring timezone with / format
    let formattedExpirationDate = null;
    if (expirationDate) {
      formattedExpirationDate = new Date(
        Date.UTC(
          expirationDate.getFullYear(),
          expirationDate.getMonth(),
          expirationDate.getDate(),
          expirationDate.getHours(),
          expirationDate.getMinutes(),
          expirationDate.getSeconds(),
        ),
      );
    }
    this.clientService.registerExperimentalCandidate(this.data.clients, formattedExpirationDate).then(
      ({ updated, count }) => {
        if (updated) {
          this.dialogRef.close({ count });
        }
      },
      (error) => {
        if (error.status === 400) {
          this.snackbar.error(this.translate.instant('error.wrongData'));
        }
      },
    );
  }
}
