import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Cookie } from './tools';

export const SkipAuthInterceptorHeader = 'X-Skip-Auth-Interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private api: ApiService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = Cookie.get('access_token');

    if (req.headers.has(SkipAuthInterceptorHeader)) {
      const headers = req.headers.delete(SkipAuthInterceptorHeader);
      return next.handle(req.clone({ headers }));
    }

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }

    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 401 || errorResponse.status === 403) {
          if (errorResponse.error.code === 'token_not_valid') {
            if (!errorResponse.url?.endsWith('/api/token/refresh')) {
              return this.api
                .post(
                  '/api/token/refresh',
                  {
                    refresh: Cookie.get('refresh_token'),
                  },
                  {
                    observe: 'response',
                  },
                )
                .pipe(
                  mergeMap((response: HttpResponse<any>): ObservableInput<any> => {
                    if (response.status === 200) {
                      Cookie.set('access_token', response.body.access);
                      req = req.clone({
                        setHeaders: {
                          Authorization: `Bearer ${response.body.access}`,
                        },
                      });
                      return next.handle(req).pipe(
                        catchError((anotherErrorResponse) =>
                          // Catch another error
                          throwError(anotherErrorResponse),
                        ),
                      );
                    } else {
                      this.api.repudiate();
                      return this.router.navigate(['']);
                    }
                  }),
                );
            } else {
              this.api.repudiate();
              this.router.navigate(['']);
            }
          } else {
            this.api.repudiate();
            this.router.navigate(['']);
          }
        }
        return throwError(errorResponse);
      }),
    );
  }
}
