import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  panelOpenState = false;
  target = '';
  expandedQuestionId = '';
  questions = [
    {
      id: 'forgot-email',
      i18nKey: 'app.help.component.questions.forgotEmail',
    },
    {
      id: 'forgot-password',
      i18nKey: 'app.help.component.questions.forgotPassword',
      path: '/change-password',
    },
    {
      id: 'resend-creation-link',
      i18nKey: 'app.help.component.questions.resendCreationLink',
      link: {
        path: '/resend-creation-link',
      },
    },
    {
      id: 'update-user-limit',
      i18nKey: 'app.help.component.questions.updateUserLimit',
      link: {
        path: '/panel/billing',
      },
    },
  ];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.expandedQuestionId = '';
      if (params.has('question')) {
        if (this.questions.some((q) => q.id === params.get('question'))) {
          this.expandedQuestionId = params.get('question');
        } else {
          this.router.navigate(['/help']);
        }
      }
    });
  }

  showQuestion(question: any): boolean {
    if (this.translate.instant(`${question.i18nKey}.title`).includes(this.target)) {
      return true;
    }
    return false;
  }
}
