export interface Country {
  code: string | undefined;
  label: string;
}

export const countries: Country[] = [
  {
    code: 'AF',
    label: 'Afghanistan',
  },
  {
    code: 'AL',
    label: 'Albanie',
  },
  {
    code: 'DZ',
    label: 'Alg\u00e9rie',
  },
  {
    code: 'AS',
    label: 'Samoa am\u00e9ricaines',
  },
  {
    code: 'AD',
    label: 'Andorre',
  },
  {
    code: 'AO',
    label: 'Angola',
  },
  {
    code: 'AI',
    label: 'Anguilla',
  },
  {
    code: 'AQ',
    label: 'Antarctique',
  },
  {
    code: 'AG',
    label: 'Antigua-et-Barbuda',
  },
  {
    code: 'AR',
    label: 'Argentine',
  },
  {
    code: 'AM',
    label: 'Arm\u00e9nie',
  },
  {
    code: 'AW',
    label: 'Aruba',
  },
  {
    code: 'AU',
    label: 'Australie',
  },
  {
    code: 'AT',
    label: 'Autriche',
  },
  {
    code: 'AZ',
    label: 'Azerba\u00efdjan',
  },
  {
    code: 'BS',
    label: 'Bahamas',
  },
  {
    code: 'BH',
    label: 'Bahre\u00efn',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
  },
  {
    code: 'BB',
    label: 'Barbade',
  },
  {
    code: 'BY',
    label: 'B\u00e9larus',
  },
  {
    code: 'BE',
    label: 'Belgique',
  },
  {
    code: 'BZ',
    label: 'Belize',
  },
  {
    code: 'BJ',
    label: 'B\u00e9nin',
  },
  {
    code: 'BM',
    label: 'Bermudes',
  },
  {
    code: 'BT',
    label: 'Bhoutan',
  },
  {
    code: 'BO',
    label: 'Bolivie (\u00c9tat plurinational de)',
  },
  {
    code: 'BQ',
    label: 'Bonaire, Saint-Eustache et Saba',
  },
  {
    code: 'BA',
    label: 'Bosnie-Herz\u00e9govine',
  },
  {
    code: 'BW',
    label: 'Botswana',
  },
  {
    code: 'BV',
    label: '\u00cele Bouvet',
  },
  {
    code: 'BR',
    label: 'Br\u00e9sil',
  },
  {
    code: 'IO',
    label: "Territoire britannique de l'oc\u00e9an Indien",
  },
  {
    code: 'VG',
    label: '\u00celes Vierges britanniques',
  },
  {
    code: 'BN',
    label: 'Brun\u00e9i Darussalam',
  },
  {
    code: 'BG',
    label: 'Bulgarie',
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
  },
  {
    code: 'BI',
    label: 'Burundi',
  },
  {
    code: 'CV',
    label: 'Cabo Verde',
  },
  {
    code: 'KH',
    label: 'Cambodge',
  },
  {
    code: 'CM',
    label: 'Cameroun',
  },
  {
    code: 'CA',
    label: 'Canada',
  },
  {
    code: 'KY',
    label: '\u00celes Ca\u00efmanes',
  },
  {
    code: 'CF',
    label: 'R\u00e9publique centrafricaine',
  },
  {
    code: 'TD',
    label: 'Tchad',
  },
  {
    code: 'CL',
    label: 'Chili',
  },
  {
    code: 'CN',
    label: 'Chine',
  },
  {
    code: 'HK',
    label: 'Chine, r\u00e9gion administrative sp\u00e9ciale de Hong Kong',
  },
  {
    code: 'MO',
    label: 'Chine, r\u00e9gion administrative sp\u00e9ciale de Macao',
  },
  {
    code: 'CX',
    label: '\u00cele Christmas',
  },
  {
    code: 'CC',
    label: '\u00celes des Cocos (Keeling)',
  },
  {
    code: 'CO',
    label: 'Colombie',
  },
  {
    code: 'KM',
    label: 'Comores',
  },
  {
    code: 'CG',
    label: 'Congo',
  },
  {
    code: 'CK',
    label: '\u00celes Cook',
  },
  {
    code: 'CR',
    label: 'Costa Rica',
  },
  {
    code: 'HR',
    label: 'Croatie',
  },
  {
    code: 'CU',
    label: 'Cuba',
  },
  {
    code: 'CW',
    label: 'Cura\u00e7ao',
  },
  {
    code: 'CY',
    label: 'Chypre',
  },
  {
    code: 'CZ',
    label: 'Tch\u00e9quie',
  },
  {
    code: 'CI',
    label: "C\u00f4te d'Ivoire",
  },
  {
    code: 'KP',
    label: 'R\u00e9publique populaire d\u00e9mocratique de Cor\u00e9e',
  },
  {
    code: 'CD',
    label: 'R\u00e9publique d\u00e9mocratique du Congo',
  },
  {
    code: 'DK',
    label: 'Danemark',
  },
  {
    code: 'DJ',
    label: 'Djibouti',
  },
  {
    code: 'DM',
    label: 'Dominique',
  },
  {
    code: 'DO',
    label: 'R\u00e9publique dominicaine',
  },
  {
    code: 'EC',
    label: '\u00c9quateur',
  },
  {
    code: 'EG',
    label: '\u00c9gypte',
  },
  {
    code: 'SV',
    label: 'El Salvador',
  },
  {
    code: 'GQ',
    label: 'Guin\u00e9e \u00e9quatoriale',
  },
  {
    code: 'ER',
    label: '\u00c9rythr\u00e9e',
  },
  {
    code: 'EE',
    label: 'Estonie',
  },
  {
    code: 'ET',
    label: '\u00c9thiopie',
  },
  {
    code: 'FK',
    label: '\u00celes Falkland (Malvinas)',
  },
  {
    code: 'FO',
    label: '\u00celes F\u00e9ro\u00e9',
  },
  {
    code: 'FJ',
    label: 'Fidji',
  },
  {
    code: 'FI',
    label: 'Finlande',
  },
  {
    code: 'FR',
    label: 'France',
  },
  {
    code: 'GF',
    label: 'Guyane fran\u00e7aise',
  },
  {
    code: 'PF',
    label: 'Polyn\u00e9sie fran\u00e7aise',
  },
  {
    code: 'TF',
    label: 'Terres australes fran\u00e7aises',
  },
  {
    code: 'GA',
    label: 'Gabon',
  },
  {
    code: 'GM',
    label: 'Gambie',
  },
  {
    code: 'GE',
    label: 'G\u00e9orgie',
  },
  {
    code: 'DE',
    label: 'Allemagne',
  },
  {
    code: 'GH',
    label: 'Ghana',
  },
  {
    code: 'GI',
    label: 'Gibraltar',
  },
  {
    code: 'GR',
    label: 'Gr\u00e8ce',
  },
  {
    code: 'GL',
    label: 'Groenland',
  },
  {
    code: 'GD',
    label: 'Grenade',
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
  },
  {
    code: 'GU',
    label: 'Guam',
  },
  {
    code: 'GT',
    label: 'Guatemala',
  },
  {
    code: 'GG',
    label: 'Guernesey',
  },
  {
    code: 'GN',
    label: 'Guin\u00e9e',
  },
  {
    code: 'GW',
    label: 'Guin\u00e9e-Bissau',
  },
  {
    code: 'GY',
    label: 'Guyana',
  },
  {
    code: 'HT',
    label: 'Ha\u00efti',
  },
  {
    code: 'HM',
    label: '\u00cele Heard-et-\u00celes MacDonald',
  },
  {
    code: 'VA',
    label: 'Saint-Si\u00e8ge',
  },
  {
    code: 'HN',
    label: 'Honduras',
  },
  {
    code: 'HU',
    label: 'Hongrie',
  },
  {
    code: 'IS',
    label: 'Islande',
  },
  {
    code: 'IN',
    label: 'Inde',
  },
  {
    code: 'ID',
    label: 'Indon\u00e9sie',
  },
  {
    code: 'IR',
    label: "Iran (R\u00e9publique islamique d')",
  },
  {
    code: 'IQ',
    label: 'Iraq',
  },
  {
    code: 'IE',
    label: 'Irlande',
  },
  {
    code: 'IM',
    label: '\u00cele de Man',
  },
  {
    code: 'IL',
    label: 'Isra\u00ebl',
  },
  {
    code: 'IT',
    label: 'Italie',
  },
  {
    code: 'JM',
    label: 'Jama\u00efque',
  },
  {
    code: 'JP',
    label: 'Japon',
  },
  {
    code: 'JE',
    label: 'Jersey',
  },
  {
    code: 'JO',
    label: 'Jordanie',
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
  },
  {
    code: 'KE',
    label: 'Kenya',
  },
  {
    code: 'KI',
    label: 'Kiribati',
  },
  {
    code: 'KW',
    label: 'Kowe\u00eft',
  },
  {
    code: 'KG',
    label: 'Kirghizistan',
  },
  {
    code: 'LA',
    label: 'R\u00e9publique d\u00e9mocratique populaire lao',
  },
  {
    code: 'LV',
    label: 'Lettonie',
  },
  {
    code: 'LB',
    label: 'Liban',
  },
  {
    code: 'LS',
    label: 'Lesotho',
  },
  {
    code: 'LR',
    label: 'Lib\u00e9ria',
  },
  {
    code: 'LY',
    label: 'Libye',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
  },
  {
    code: 'LT',
    label: 'Lituanie',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
  },
  {
    code: 'MG',
    label: 'Madagascar',
  },
  {
    code: 'MW',
    label: 'Malawi',
  },
  {
    code: 'MY',
    label: 'Malaisie',
  },
  {
    code: 'MV',
    label: 'Maldives',
  },
  {
    code: 'ML',
    label: 'Mali',
  },
  {
    code: 'MT',
    label: 'Malte',
  },
  {
    code: 'MH',
    label: '\u00celes Marshall',
  },
  {
    code: 'MQ',
    label: 'Martinique',
  },
  {
    code: 'MR',
    label: 'Mauritanie',
  },
  {
    code: 'MU',
    label: 'Maurice',
  },
  {
    code: 'YT',
    label: 'Mayotte',
  },
  {
    code: 'MX',
    label: 'Mexique',
  },
  {
    code: 'FM',
    label: 'Micron\u00e9sie (\u00c9tats f\u00e9d\u00e9r\u00e9s de)',
  },
  {
    code: 'MC',
    label: 'Monaco',
  },
  {
    code: 'MN',
    label: 'Mongolie',
  },
  {
    code: 'ME',
    label: 'Mont\u00e9n\u00e9gro',
  },
  {
    code: 'MS',
    label: 'Montserrat',
  },
  {
    code: 'MA',
    label: 'Maroc',
  },
  {
    code: 'MZ',
    label: 'Mozambique',
  },
  {
    code: 'MM',
    label: 'Myanmar',
  },
  {
    code: 'NA',
    label: 'Namibie',
  },
  {
    code: 'NR',
    label: 'Nauru',
  },
  {
    code: 'NP',
    label: 'N\u00e9pal',
  },
  {
    code: 'NL',
    label: 'Pays-Bas',
  },
  {
    code: 'NC',
    label: 'Nouvelle-Cal\u00e9donie',
  },
  {
    code: 'NZ',
    label: 'Nouvelle-Z\u00e9lande',
  },
  {
    code: 'NI',
    label: 'Nicaragua',
  },
  {
    code: 'NE',
    label: 'Niger',
  },
  {
    code: 'NG',
    label: 'Nig\u00e9ria',
  },
  {
    code: 'NU',
    label: 'Niou\u00e9',
  },
  {
    code: 'NF',
    label: '\u00cele Norfolk',
  },
  {
    code: 'MP',
    label: '\u00celes Mariannes du Nord',
  },
  {
    code: 'NO',
    label: 'Norv\u00e8ge',
  },
  {
    code: 'OM',
    label: 'Oman',
  },
  {
    code: 'PK',
    label: 'Pakistan',
  },
  {
    code: 'PW',
    label: 'Palaos',
  },
  {
    code: 'PA',
    label: 'Panama',
  },
  {
    code: 'PG',
    label: 'Papouasie-Nouvelle-Guin\u00e9e',
  },
  {
    code: 'PY',
    label: 'Paraguay',
  },
  {
    code: 'PE',
    label: 'P\u00e9rou',
  },
  {
    code: 'PH',
    label: 'Philippines',
  },
  {
    code: 'PN',
    label: 'Pitcairn',
  },
  {
    code: 'PL',
    label: 'Pologne',
  },
  {
    code: 'PT',
    label: 'Portugal',
  },
  {
    code: 'PR',
    label: 'Porto Rico',
  },
  {
    code: 'QA',
    label: 'Qatar',
  },
  {
    code: 'KR',
    label: 'R\u00e9publique de Cor\u00e9e',
  },
  {
    code: 'MD',
    label: 'R\u00e9publique de Moldova',
  },
  {
    code: 'RO',
    label: 'Roumanie',
  },
  {
    code: 'RU',
    label: 'F\u00e9d\u00e9ration de Russie',
  },
  {
    code: 'RW',
    label: 'Rwanda',
  },
  {
    code: 'RE',
    label: 'R\u00e9union',
  },
  {
    code: 'BL',
    label: 'Saint-Barth\u00e9lemy',
  },
  {
    code: 'SH',
    label: 'Sainte-H\u00e9l\u00e8ne',
  },
  {
    code: 'KN',
    label: 'Saint-Kitts-et-Nevis',
  },
  {
    code: 'LC',
    label: 'Sainte-Lucie',
  },
  {
    code: 'MF',
    label: 'Saint-Martin (partie fran\u00e7aise)',
  },
  {
    code: 'PM',
    label: 'Saint-Pierre-et-Miquelon',
  },
  {
    code: 'VC',
    label: 'Saint-Vincent-et-les Grenadines',
  },
  {
    code: 'WS',
    label: 'Samoa',
  },
  {
    code: 'SM',
    label: 'Saint-Marin',
  },
  {
    code: 'ST',
    label: 'Sao Tom\u00e9-et-Principe',
  },
  {
    // https://en.wikipedia.org/wiki/Sark
    code: 'CQ',
    label: 'Sercq',
  },
  {
    code: 'SA',
    label: 'Arabie saoudite',
  },
  {
    code: 'SN',
    label: 'S\u00e9n\u00e9gal',
  },
  {
    code: 'RS',
    label: 'Serbie',
  },
  {
    code: 'SC',
    label: 'Seychelles',
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
  },
  {
    code: 'SG',
    label: 'Singapour',
  },
  {
    code: 'SX',
    label: 'Saint-Martin (partie n\u00e9erlandaise)',
  },
  {
    code: 'SK',
    label: 'Slovaquie',
  },
  {
    code: 'SI',
    label: 'Slov\u00e9nie',
  },
  {
    code: 'SB',
    label: '\u00celes Salomon',
  },
  {
    code: 'SO',
    label: 'Somalie',
  },
  {
    code: 'ZA',
    label: 'Afrique du Sud',
  },
  {
    code: 'GS',
    label: 'G\u00e9orgie du Sud-et-les \u00celes Sandwich du Sud',
  },
  {
    code: 'SS',
    label: 'Soudan du Sud',
  },
  {
    code: 'ES',
    label: 'Espagne',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
  },
  {
    code: 'PS',
    label: '\u00c9tat de Palestine',
  },
  {
    code: 'SD',
    label: 'Soudan',
  },
  {
    code: 'SR',
    label: 'Suriname',
  },
  {
    code: 'SJ',
    label: '\u00celes Svalbard-et-Jan Mayen',
  },
  {
    code: 'SZ',
    label: 'Swaziland',
  },
  {
    code: 'SE',
    label: 'Su\u00e8de',
  },
  {
    code: 'CH',
    label: 'Suisse',
  },
  {
    code: 'SY',
    label: 'R\u00e9publique arabe syrienne',
  },
  {
    code: 'TJ',
    label: 'Tadjikistan',
  },
  {
    code: 'TH',
    label: 'Tha\u00eflande',
  },
  {
    code: 'MK',
    label: 'ex-R\u00e9publique yougoslave de Mac\u00e9doine',
  },
  {
    code: 'TL',
    label: 'Timor-Leste',
  },
  {
    code: 'TG',
    label: 'Togo',
  },
  {
    code: 'TK',
    label: 'Tok\u00e9laou',
  },
  {
    code: 'TO',
    label: 'Tonga',
  },
  {
    code: 'TT',
    label: 'Trinit\u00e9-et-Tobago',
  },
  {
    code: 'TN',
    label: 'Tunisie',
  },
  {
    code: 'TR',
    label: 'Turquie',
  },
  {
    code: 'TM',
    label: 'Turkm\u00e9nistan',
  },
  {
    code: 'TC',
    label: '\u00celes Turques-et-Ca\u00efques',
  },
  {
    code: 'TV',
    label: 'Tuvalu',
  },
  {
    code: 'UG',
    label: 'Ouganda',
  },
  {
    code: 'UA',
    label: 'Ukraine',
  },
  {
    code: 'AE',
    label: '\u00c9mirats arabes unis',
  },
  {
    code: 'GB',
    label: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
  },
  {
    code: 'TZ',
    label: 'R\u00e9publique-Unie de Tanzanie',
  },
  {
    code: 'UM',
    label: '\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis',
  },
  {
    code: 'VI',
    label: '\u00celes Vierges am\u00e9ricaines',
  },
  {
    code: 'US',
    label: "\u00c9tats-Unis d'Am\u00e9rique",
  },
  {
    code: 'UY',
    label: 'Uruguay',
  },
  {
    code: 'UZ',
    label: 'Ouzb\u00e9kistan',
  },
  {
    code: 'VU',
    label: 'Vanuatu',
  },
  {
    code: 'VE',
    label: 'Venezuela (R\u00e9publique bolivarienne du)',
  },
  {
    code: 'VN',
    label: 'Viet Nam',
  },
  {
    code: 'WF',
    label: '\u00celes Wallis-et-Futuna',
  },
  {
    code: 'EH',
    label: 'Sahara occidental',
  },
  {
    code: 'YE',
    label: 'Y\u00e9men',
  },
  {
    code: 'ZM',
    label: 'Zambie',
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
  },
  {
    code: 'AX',
    label: "\u00celes d'\u00c5land",
  },
];
