<div class="container">
  <div class="row align-items-center justify-content-center row-container">
    <mat-card class="col-12 col-lg-10">
      <div class="row align-items-center">
        <div class="col-8 offset-2">
          <h3 class="text-center form-label">{{ 'app.resendCreationLink.component.resendCreationLink' | translate }}</h3>
        </div>
      </div>
      <ng-container *ngIf="!mailSent">
        <form (keydown.enter)="sendMail(); $event.preventDefault()">
          <div class="row justify-content-center">
            <mat-form-field
              class="col-8"
              appearance="outline"
            >
              <mat-label>{{ 'app.resendCreationLink.component.email' | translate }}</mat-label>
              <mat-icon matPrefix>person</mat-icon>
              <input
                matInput
                [(ngModel)]="email"
                name="emailInput"
                type="text"
                required
              />
              <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="row justify-content-center">
            <div class="col-8 d-flex justify-content-end">
              <button
                mat-raised-button
                color="accent"
                (click)="sendMail()"
              >
                {{ 'app.resendCreationLink.component.sendMail' | translate }}
              </button>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="mailSent">
        <div class="row justify-content-center">
          <div class="col-8 logo d-flex justify-content-center align-items-center">
            <mat-icon class="check-icon text-center">check_circle_outline</mat-icon>
          </div>
        </div>
        <div class="row justify-content-center">
          <p>{{ 'app.resendCreationLink.component.mailSent' | translate }}</p>
        </div>
        <div class="row justify-content-center">
          <div class="col-8 d-flex justify-content-end">
            <button
              mat-raised-button
              color="accent"
              routerLink="/"
            >
              {{ 'app.global.backToHome' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </mat-card>
  </div>
</div>
