import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { ApiService } from 'src/app/shared/api.service';
import { SnackbarService } from '../shared/snackbar.service';

@Component({
  selector: 'app-change-card',
  templateUrl: './change-card.component.html',
  styleUrls: ['./change-card.component.scss'],
})
export class ChangeCardComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#000000',
        color: '#000000',
        lineHeight: '50px',
        fontWeight: '300',
        fontFamily: 'Montserrat, "Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: 'rgba(0,0,0,0.38)',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'fr',
  };
  userId = '';
  token = '';
  cardChanged = false;
  hasToken = false;
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private snackbar: SnackbarService,
    private stripeService: StripeService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.updateElementsLocale(event.lang);
    });
    this.route.paramMap.subscribe((params) => {
      this.hasToken = params.has('userId') && params.has('token');
      if (this.hasToken) {
        this.userId = params.get('userId');
        this.token = params.get('token');
      }
    });
  }

  updateElementsLocale(locale: string): void {
    type elementsLocale = 'auto' | 'da' | 'de' | 'en' | 'es' | 'fi' | 'fr' | 'it' | 'ja' | 'no' | 'nl' | 'sv' | 'zh';
    this.elementsOptions.locale = locale as elementsLocale;
  }

  changeCard(): void {
    if (this.hasToken) {
      this.stripeService.createPaymentMethod({ type: 'card', card: this.card.element }).subscribe((result) => {
        if (result.paymentMethod) {
          const url = `/users/${this.userId}/update_card?tk=${this.token}`;
          this.api
            .post(url, {
              payment_method: result.paymentMethod.id,
            })
            .subscribe(() => {
              this.cardChanged = true;
              this.snackbar.success(this.translate.instant('app.changeCard.component.cardChanged'));
            });
        } else if (result.error) {
          this.snackbar.error(result.error.message);
        }
      });
    }
  }
}
