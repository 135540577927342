import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'billing-remove-payment-method-dialog',
  templateUrl: 'billing-remove-payment-method-dialog.html',
})
export class BillingRemovePaymentDialog {
  constructor(
    public dialogRef: MatDialogRef<BillingRemovePaymentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
  ) {}

  submit(): void {
    const url = `/users/${this.data.user.pk}/clients/${this.data.user.client.pk}/delete_payment_method`;
    this.api
      .post(url, {
        payment_method: this.data.paymentMethod.id,
      })
      .subscribe(() => {
        this.dialogRef.close('removedPaymentMethod');
      });
  }
}
