<h1 mat-dialog-title>{{'app.panel.customerProfile.component.email' | translate}}</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="row justify-content-center">
      <mat-form-field appearance="outline">
        <mat-label>{{'app.panel.customerProfile.emailDialog.newEmail' | translate}}</mat-label>
        <input
          matInput
          formControlName="newEmail"
          required
          [errorStateMatcher]="errorStateMatcher"
        />
        <mat-error>{{'error.mandatoryField' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="row justify-content-center">
      <mat-form-field appearance="outline">
        <mat-label>{{'app.panel.customerProfile.emailDialog.password' | translate}}</mat-label>
        <input
          type="password"
          matInput
          formControlName="password"
          required
          [errorStateMatcher]="errorStateMatcher"
        />
        <mat-error>{{'error.mandatoryField' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    (click)="cancel()"
  >
    {{'app.panel.customerProfile.emailDialog.cancel' | translate}}
  </button>
  <button
    mat-raised-button
    color="accent"
    (click)="submit()"
    cdkFocusInitial
  >
    {{'app.panel.customerProfile.emailDialog.submit' | translate}}
  </button>
</div>
