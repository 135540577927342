export const notifications = {
  NEW_INVOICE: {
    type: 'info',
    link: '/panel/billing/invoices',
  },
  CHARGE_FAILED: {
    type: 'warning',
    link: '/panel/billing',
  },
  ORGANIZATION_EXPIRING: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  ORGANIZATION_EXPIRED: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  STORAGE_ALERT: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  FIRST_ORGANIZATION: {
    type: 'info',
    link: '/panel/organization/list/:organization_id',
  },
  NEW_ORGANIZATION: {
    type: 'info',
    link: '/panel/organization/list/:organization_id',
  },
  INVOICE_PAYMENT_SUCCEEDED: {
    type: 'info',
    link: '/panel/billing/invoices',
  },
  FREEZING_ORGANIZATION: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  FROZEN_ORGANIZATION: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  UNFROZEN_ORGANIZATION: {
    type: 'info',
    link: '/panel/organization/list/:organization_id',
  },
  SOURCE_EXPIRING: {
    type: 'warning',
    link: '/panel/billing',
  },
  USER_ALERT: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  PASSED_TO_BILL: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  PASSED_TO_NEW_BILLING_SLICE: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  PASSED_TO_FREE: {
    type: 'info',
    link: '/panel/organization/list/:organization_id',
  },
  MAX_USER_ALERT: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  PASSED_TO_MAX_USER_LIMIT: {
    type: 'warning',
    link: '/panel/organization/list/:organization_id',
  },
  CLIENT_EXPERIMENTAL_CANDIDATE_EXPIRED: {
    type: 'warning',
    link: '/panel/admin/clients',
  },
};
