import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxStripeModule } from 'ngx-stripe';
import { AppRoutingModule } from '../app-routing.module';
import { MatImportModule } from '../mat-import.module';
import { NavbarModule } from '../navbar/navbar.module';
import { AdminModule } from './admin/admin.module';
import { BillingAddPaymentDialog } from './billing/billing-add.component';
import { BillingDefaultPaymentDialog } from './billing/billing-default.component';
import { BillingRemovePaymentDialog } from './billing/billing-remove.component';
import { BillingComponent } from './billing/billing.component';
import { CustomerProfileComponent, CustomerProfileEmailDialog } from './customer-profile/customer-profile.component';
import { NotificationLogComponent } from './notification/notification-log.component';
import { OrganizationComponent, OrganizationUnsubscribeDialog } from './organization/organization.component';
import { PanelComponent } from './panel.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    PanelComponent,
    CustomerProfileComponent,
    BillingComponent,
    BillingDefaultPaymentDialog,
    BillingAddPaymentDialog,
    OrganizationComponent,
    OrganizationUnsubscribeDialog,
    NotificationLogComponent,
    CustomerProfileEmailDialog,
    BillingRemovePaymentDialog,
  ],
  imports: [
    AdminModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxStripeModule,
    AppRoutingModule,
    MatImportModule,
    NavbarModule,
    TranslateModule.forChild({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [],
  bootstrap: [],
})
export class PanelModule {}
