<h1 mat-dialog-title>{{ 'app.panel.admin.billingSystemLabels.EXPERIMENTAL_CANDIDATE' | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="row justify-content-center">
      <mat-form-field
        class="col-12"
        appearance="outline"
      >
        <mat-label>{{ 'app.panel.admin.clientList.experimentalCandidateDialog.expirationDate' | translate }}</mat-label>
        <input
          matInput
          [min]="minDate"
          [matDatepicker]="picker"
          formControlName="expirationDate"
          [errorStateMatcher]="errorStateMatcher"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div
  mat-dialog-actions
  class="d-flex justify-content-end"
>
  <button
    mat-raised-button
    (click)="cancel()"
  >
    {{ 'app.panel.admin.clientList.experimentalCandidateDialog.cancel' | translate }}
  </button>
  <button
    mat-raised-button
    [disabled]="!formGroup.get('expirationDate').value"
    color="accent"
    (click)="submit()"
    cdkFocusInitial
  >
    {{ 'app.panel.admin.clientList.experimentalCandidateDialog.submit' | translate }}
  </button>
</div>
