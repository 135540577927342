<div class="container">
  <div class="row align-items-center justify-content-center login">
    <mat-card class="col-10 col-xl-5 col-lg-7 text-center">
      <div class="row justify-content-center no-gutters title">
        <div class="col-8">
          <a href="https://parsec.cloud">
            <img
              class="img-fluid"
              src="assets/images/parsec-vert.png"
              [alt]="'app.global.parsecLogo' | translate"
            />
          </a>
          <p>{{ 'app.main.component.customerArea' | translate }}</p>
          <hr />
        </div>
      </div>
      <form (keydown.enter)="logIn(); $event.preventDefault()">
        <div class="row justify-content-center">
          <mat-form-field
            class="col-9"
            appearance="outline"
          >
            <mat-label>{{ 'app.main.component.email' | translate }}</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input
              matInput
              [(ngModel)]="email"
              name="emailInput"
              type="text"
              required
            />
            <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="row justify-content-center">
          <mat-form-field
            class="col-9"
            appearance="outline"
          >
            <mat-label>{{ 'app.main.component.password' | translate }}</mat-label>
            <mat-icon matPrefix>lock</mat-icon>
            <input
              matInput
              [(ngModel)]="password"
              name="passwordInput"
              [attr.type]="passwordVisibility ? 'text' : 'password'"
              required
            />
            <mat-icon
              matSuffix
              class="visibility"
              (click)="togglePasswordVisibility()"
              >visibility{{ passwordVisibility ? '' : '_off' }}</mat-icon
            >
            <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="row col-8 offset-2 align-items-center justify-content-center non-field">
          <mat-checkbox
            class="col-9 col-md-6"
            labelPosition="after"
            name="remember"
            [(ngModel)]="remember"
          >
            {{ 'app.main.component.rememberMe' | translate }}
          </mat-checkbox>
          <a
            routerLink="/change-password"
            class="col-9 col-md-6"
            >{{ 'app.main.component.forgotPassword' | translate }}</a
          >
        </div>
        <div class="row col-8 offset-2 align-items-center justify-content-center non-field link-to-showcase-website">
          <!-- TEMPORARY FIX
          <a href="https://parsec.cloud/get-parsec">{{'app.main.component.noAccount' | translate}}</a>
        --></div>
      </form>
      <div class="row justify-content-center no-gutters login-button">
        <div
          class="col left"
          (click)="logIn()"
        >
          <span class="login-button-background"></span>
          <span
            class="login-button-text"
            tabindex="0"
            (keydown.enter)="logIn(); $event.preventDefault()"
          >
            {{ 'app.main.component.login' | translate }}
          </span>
        </div>
      </div>
    </mat-card>
  </div>
</div>
