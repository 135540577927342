<app-navbar></app-navbar>
<div class="container">
  <div class="row align-items-center justify-content-center row-container">
    <mat-card class="col-12 col-lg-10">
      <div>
        <div class="row align-items-center">
          <div class="col-8 offset-2">
            <h3 class="text-center form-label">
              {{ 'app.feedback.component.somethingToReport' | translate }}
            </h3>
          </div>
        </div>
        <ng-container *ngIf="!feedbackSent">
          <form [formGroup]="formGroup">
            <div class="row align-items-center">
              <div class="col-8 col-md-4 offset-2">
                <p class="form-label">{{ 'app.feedback.component.feedbackType' | translate }} *</p>
                <mat-radio-group
                  formControlName="type"
                  required
                  (keydown.enter)="$event.preventDefault()"
                >
                  <mat-radio-button
                    class="col-9"
                    value="Problème"
                  >
                    {{ 'app.feedback.component.problem' | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    class="col-9"
                    value="Suggestion"
                  >
                    {{ 'app.feedback.component.suggestion' | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-8 col-md-4 offset-2 offset-md-0">
                <p class="form-label">{{ 'app.feedback.component.platform' | translate }} *</p>
                <mat-radio-group
                  formControlName="platform"
                  required
                  (keydown.enter)="$event.preventDefault()"
                >
                  <mat-radio-button
                    class="col-9"
                    value="Logiciel Parsec"
                  >
                    {{ 'app.feedback.component.parsecSoftware' | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    class="col-9"
                    value="Site marchand"
                  >
                    {{ 'app.feedback.component.merchantSite' | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-8 offset-2">
                <p class="form-label">{{ 'app.feedback.component.contactInfo' | translate }}</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <mat-form-field
                class="col-8 col-md-3"
                appearance="outline"
              >
                <mat-label>{{ 'app.feedback.component.name' | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="name"
                  [errorStateMatcher]="errorStateMatcher"
                  (keydown.enter)="$event.preventDefault()"
                />
              </mat-form-field>
              <mat-form-field
                class="col-8 col-md-5"
                appearance="outline"
              >
                <mat-label>{{ 'app.feedback.component.email' | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="email"
                  [errorStateMatcher]="errorStateMatcher"
                  (keydown.enter)="$event.preventDefault()"
                />
              </mat-form-field>
            </div>
            <div class="row align-items-center">
              <div class="col-8 offset-2">
                <p class="form-label">{{ 'app.feedback.component.whatToReport' | translate }} *</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <mat-form-field
                class="col-8"
                appearance="outline"
              >
                <mat-label>{{ 'app.feedback.component.title' | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="title"
                  required
                  [errorStateMatcher]="errorStateMatcher"
                  (keydown.enter)="$event.preventDefault()"
                />
                <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row justify-content-center">
              <mat-form-field
                class="col-8"
                appearance="outline"
              >
                <mat-label>{{ 'app.feedback.component.description' | translate }}</mat-label>
                <textarea
                  matInput
                  formControlName="description"
                  required
                  [errorStateMatcher]="errorStateMatcher"
                  rows="10"
                ></textarea>
                <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row justify-content-center">
              <div class="col-8 d-flex justify-content-end">
                <button
                  mat-raised-button
                  color="accent"
                  (click)="sendFeedback()"
                >
                  {{ 'app.feedback.component.sendFeedback' | translate }}
                </button>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="feedbackSent">
          <div class="row justify-content-center">
            <div class="col-8 logo d-flex justify-content-center align-items-center">
              <mat-icon class="check-icon text-center">check_circle_outline</mat-icon>
            </div>
          </div>
          <div class="row justify-content-center">
            <p>{{ 'app.feedback.component.feedbackSent' | translate }}</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-8 d-flex justify-content-end">
              <button
                mat-raised-button
                color="accent"
                routerLink="/"
              >
                {{ 'app.global.backToHome' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-card>
  </div>
</div>
