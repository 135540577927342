import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from 'src/app/shared/date.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { Filesize, FileSizeUnits, isEmptyOrNullObject } from 'src/app/shared/tools';
import { BillingSystem, CustomOrderStatus } from '../admin.global';
import { OrganizationListExpirationDateDialogComponent } from './organization-list-expiration-date-dialog/organization-list-expiration-date-dialog.component';
import { OrganizationListFreezeDialogComponent } from './organization-list-freeze-dialog/organization-list-freeze-dialog.component';
import { CustomOrderDetails, InvoiceElement, Organization, OrganizationData, OrganizationInfo, Status } from './organization-list.global';
import { OrganizationService } from './organization.service';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrganizationListComponent {
  public readonly BillingSystem: typeof BillingSystem = BillingSystem;
  public readonly CustomOrderStatus: typeof CustomOrderStatus = CustomOrderStatus;
  public readonly isEmptyOrNullObject = isEmptyOrNullObject;
  organizations: Organization[] = [];
  organizationsDisplayedColumns: (keyof OrganizationData | 'select')[] = [
    'select',
    'suffix',
    'created_at',
    'expiration_date',
    'client__billing_system',
  ];
  organizationsDataSource: MatTableDataSource<OrganizationData> = new MatTableDataSource();
  organizationsColumnsToDisplayWithExpand: (keyof OrganizationData | 'select' | 'options' | 'expand')[] = [
    ...this.organizationsDisplayedColumns,
    'expand',
    'options',
  ];
  selection = new SelectionModel<OrganizationData>(true, []);
  expandedOrganization: OrganizationData | null;
  organizationsInfo: { [organization_id: string]: OrganizationInfo } = {};
  customOrderDetails: { [organization_id: string]: CustomOrderDetails | Record<string, never> } = {};
  expandedOrganizationInfoRetrieved = false;
  expandedCustomOrderDetailsRetrieved = false;
  organizationsLoaded = false;
  _clients: any[] = [];
  customOrderStatus = {};
  customOrderStatusList = [
    {
      key: CustomOrderStatus.NothingLinked,
      color: 'primary',
    },
    {
      key: CustomOrderStatus.EstimateLinked,
      color: 'info',
    },
    {
      key: CustomOrderStatus.InvoiceToBePaid,
      color: 'warning',
    },
    {
      key: CustomOrderStatus.InvoicePaid,
      color: 'success',
    },
    {
      key: CustomOrderStatus.ContractEnded,
      color: 'danger',
    },
    {
      key: CustomOrderStatus.Unknown,
      color: 'danger',
    },
  ];
  invoiceElementsDisplayedColumns: string[] = ['name', 'quantity', 'unitAmount', 'taxAmount', 'totalAmount'];
  invoiceElementsDataSource: InvoiceElement[] = [];
  contractRemainingDurationAlertLimit = 30; // in days
  contractUsageDeviationLimit = 0.25; // percentage
  expandedCustomOrderLimits = {
    STANDARD: '',
    ADMIN: '',
    OUTSIDER: '',
    STORAGE: '',
    END_DATE: '',
  };

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.organizationsDataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.organizationsDataSource.sort = sort;
  }

  @Input() set client(value: any) {
    this._clients = value;
    this.loadOrganizations();
  }
  get client() {
    return this._clients;
  }
  @Output() goToClient: EventEmitter<any> = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    public organizationService: OrganizationService,
    public date: DateService,
  ) {}

  applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.organizationsDataSource.filter = filterValue.trim().toLowerCase();
    if (this.organizationsDataSource.paginator) {
      this.organizationsDataSource.paginator.firstPage();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.organizationsDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.organizationsDataSource.data);
  }

  loadOrganizations(): void {
    this.organizationsLoaded = false;
    const client_ids = this._clients ? this._clients.map((value) => value.id) : null;
    this.organizationService.list(client_ids).then((response) => {
      this.organizations = response.organizations;
      this.customOrderStatus = response.custom_order_status;
      this.organizationsDataSource.data = this.organizations;
      this.organizationsLoaded = true;
      this.selection.clear();
    });
  }

  async onExpand(event: Event, organization: any): Promise<void> {
    event.stopPropagation();
    this.expandedOrganizationInfoRetrieved = false;
    this.expandedCustomOrderDetailsRetrieved = false;
    this.expandedCustomOrderLimits = {
      STANDARD: '',
      ADMIN: '',
      OUTSIDER: '',
      STORAGE: '',
      END_DATE: '',
    };
    this.expandedOrganization = this.expandedOrganization === organization ? null : organization;
    if (this.expandedOrganization) {
      const organizationInfo = await this.organizationService.retrieveInfo(organization);
      this.organizationsInfo[organization.id] = organizationInfo;
      this.expandedOrganizationInfoRetrieved = true;
      if (organization.client__billing_system === BillingSystem.CustomOrder) {
        await this.retrieveCustomOrderDetails(organization);
      }
    }
    event.stopPropagation();
  }

  async retrieveCustomOrderDetails(organization: Organization): Promise<void> {
    this.customOrderDetails[organization.id] = {};
    this.organizationService.retrieveCustomOrderDetails(organization).then(
      ({ details, startDate, endDate }) => {
        if (!isEmptyOrNullObject(details)) {
          this.customOrderDetails[organization.id] = details;
          this.invoiceElementsDataSource = Object.values(details.rows);

          for (const customOrderLimitType of Object.keys(details.licenses)) {
            this.expandedCustomOrderLimits[customOrderLimitType] = this.checkCustomOrderLimit(customOrderLimitType, organization);
          }
          const nowTime = new Date().getTime();
          if (endDate) {
            const alertDate = new Date(endDate);
            alertDate.setDate(alertDate.getDate() - this.contractRemainingDurationAlertLimit);
            if (nowTime > endDate.getTime()) {
              this.expandedCustomOrderLimits['END_DATE'] = 'error';
            }
            if (nowTime > alertDate.getTime() && nowTime <= endDate.getTime()) {
              this.expandedCustomOrderLimits['END_DATE'] = 'warning';
            }
          }
        }
        this.expandedCustomOrderDetailsRetrieved = true;
      },
      (error) => {
        console.error(error);
      },
    );
  }

  checkCustomOrderLimit(type: string, organization: any): string {
    if (organization.client__billing_system === BillingSystem.CustomOrder) {
      const customOrderDetails = this.customOrderDetails[organization.id];
      const organizationInfo = this.organizationsInfo[organization.id];
      if (!organizationInfo || isEmptyOrNullObject(organizationInfo.stats)) {
        return;
      }

      if (type in customOrderDetails['licenses']) {
        let actualValue, customOrderValue;
        switch (type) {
          case 'STORAGE': {
            actualValue = organizationInfo.stats['dataSize'] + organizationInfo.stats['metadataSize'];
            const contractValue = customOrderDetails['licenses'][type];
            customOrderValue = Number(contractValue) * 100; // we multiply by 100 cause the value is slices of 100 GB
            customOrderValue = Filesize.toUnit(customOrderValue, FileSizeUnits.Gigabyte, FileSizeUnits.Byte);
            break;
          }
          case 'STANDARD':
          case 'ADMIN':
          case 'OUTSIDER':
            actualValue = organizationInfo.stats['users'][type]['active'];
            customOrderValue = Number(customOrderDetails['licenses'][type]);
            break;
        }
        if (actualValue > customOrderValue * (1 + this.contractUsageDeviationLimit)) {
          return 'error';
        } else if (actualValue > customOrderValue && actualValue <= customOrderValue * (1 + this.contractUsageDeviationLimit)) {
          return 'warning';
        } else if (actualValue <= customOrderValue) {
          return '';
        }
        return '';
      }
    }
    return '';
  }

  getOrganizationStatus(status: Status): string {
    if (status && 'is_frozen' in status) {
      return status.is_frozen
        ? this.translate.instant('app.panel.organization.component.status.frozen')
        : this.translate.instant('app.panel.organization.component.status.active');
    }
    return this.translate.instant('app.panel.organization.component.status.unknown');
  }

  getBillingSystemLabel(billingSystem: string): string {
    let label = '';
    label = billingSystem
      ? this.translate.instant(`app.panel.admin.billingSystemLabels.${billingSystem}`)
      : this.translate.instant(`app.panel.admin.billingSystemLabels.NONE`);
    return label;
  }

  getBillingSystemTooltip(organization: any): string {
    const billingSystem = organization.client__billing_system;
    const tooltipLines = [];
    const billingSystemLine = billingSystem
      ? this.translate.instant(`app.panel.admin.billingSystemTooltips.${billingSystem}`)
      : this.translate.instant(`app.panel.admin.billingSystemTooltips.NONE`);
    tooltipLines.push(billingSystemLine);
    if (organization.client__billing_system === BillingSystem.CustomOrder) {
      const customOrderStatus = this.customOrderStatus[organization.parsec_id];
      const customOrderStatusLine = customOrderStatus
        ? this.translate.instant(`app.panel.admin.customOrderStatusTooltips.${customOrderStatus}`)
        : this.translate.instant(`app.panel.admin.customOrderStatusTooltips.nothing_linked`);
      tooltipLines.push(customOrderStatusLine);
    }
    return tooltipLines.join('\n');
  }

  getCustomOrderStatusLabel(customOrderStatus: string): string {
    let label = '';
    label = customOrderStatus
      ? this.translate.instant(`app.panel.admin.customOrderStatusLabels.${customOrderStatus}`)
      : this.translate.instant(`app.panel.admin.customOrderStatusLabels.nothing_linked`);
    return label;
  }

  getCustomOrderStatusColor(customOrderStatus: string): string {
    const customOrderStatusObj = this.customOrderStatusList.find((obj) => obj.key === customOrderStatus);
    return customOrderStatusObj ? customOrderStatusObj.color : 'info';
  }

  getClientsData(organizations: Organization[]): any {
    const clientsData = [];
    for (const organization of organizations) {
      const clientData = { id: organization['client'], name: organization['client__name'] };
      if (clientData.id && !clientsData.find((obj) => obj.id === clientData.id)) {
        clientsData.push(clientData);
      }
    }
    return clientsData;
  }

  getExpirationDateString(billingSystem: string, expirationDate: string): string {
    let expirationDateString = '';

    if (billingSystem === BillingSystem.ExperimentalCandidate) {
      if (expirationDate) {
        const prefix = this.translate.instant(`app.panel.admin.clientList.component.expirationDatePrefix`);
        expirationDateString = `${prefix} ${new Date(expirationDate).toLocaleDateString(this.translate.currentLang)}`;
      } else {
        expirationDateString = this.translate.instant(`app.panel.admin.clientList.component.noExpirationDate`);
      }
    }
    return expirationDateString;
  }

  isAuthorizedAction(authorizedBillingSystem: string[], organizations: any[], authorizedCustomOrderStatus: string[] = []): boolean {
    let authorized = true;
    organizations.forEach((organization) => {
      const billingSystem = organization['client__billing_system'];
      if (billingSystem === BillingSystem.CustomOrder) {
        const customOrderStatus = this.customOrderStatus[organization.parsec_id];
        if (!authorizedCustomOrderStatus.includes(customOrderStatus)) {
          authorized = false;
        }
      }
      if (!authorizedBillingSystem.includes(billingSystem)) {
        authorized = false;
      }
    });
    return authorized;
  }

  isExpandedOrganizationLoaded(organization: any): boolean {
    let loaded = false;
    loaded = this.expandedOrganizationInfoRetrieved;
    if (organization.client__billing_system === BillingSystem.CustomOrder) {
      loaded = loaded && this.expandedCustomOrderDetailsRetrieved;
    }
    return loaded;
  }

  async alertAboutEndOfContract(organizations: Organization[]): Promise<void> {
    this.organizationService.alertAboutEndOfContract(organizations).then((response) => {
      if (response.count) {
        this.snackbar.success(this.translate.instant('app.panel.admin.component.someEmailsHaveBeenSent', { count: response.count }));
      }
    });
  }

  async freezeOrganization(organizations: Organization[]): Promise<void> {
    this.organizationService.freeze(organizations).then((response) => {
      if (response.updated !== 0) {
        this.loadOrganizations();
        this.snackbar.success(
          organizations.length === 1
            ? this.translate.instant('app.panel.admin.component.organizationHasBeenFrozen', { name: organizations[0].parsec_id })
            : this.translate.instant('app.panel.admin.component.organizationsHaveBeenFrozen', { count: response.updated }),
        );
      } else {
        this.snackbar.info(
          organizations.length === 1
            ? this.translate.instant('app.panel.admin.component.organizationAlreadyFrozen', { name: organizations[0].parsec_id })
            : this.translate.instant('app.panel.admin.component.organizationsAlreadyFrozen', { count: organizations.length }),
        );
      }
    });
  }

  async unfreezeOrganization(organizations: Organization[]): Promise<void> {
    this.organizationService.unfreeze(organizations).then((response) => {
      if (response.updated !== 0) {
        this.loadOrganizations();
        this.snackbar.success(
          organizations.length === 1
            ? this.translate.instant('app.panel.admin.component.organizationHasBeenUnfrozen', { name: organizations[0].parsec_id })
            : this.translate.instant('app.panel.admin.component.organizationsHaveBeenUnfrozen', { count: organizations.length }),
        );
      } else {
        this.snackbar.info(
          organizations.length === 1
            ? this.translate.instant('app.panel.admin.component.organizationAlreadyUnfrozen', { name: organizations[0].parsec_id })
            : this.translate.instant('app.panel.admin.component.organizationsAlreadyUnfrozen', { count: organizations.length }),
        );
      }
    });
  }

  openExpirationDateDialog(organizations: Organization[]): void {
    const dialogRef = this.dialog.open(OrganizationListExpirationDateDialogComponent, {
      data: {
        expirationDate: organizations.length === 1 ? organizations[0].expiration_date : null,
        organizations: organizations,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadOrganizations();
        this.snackbar.message(
          this.translate.instant('app.panel.admin.component.someRowsHaveBeenUpdated', { count: result.count }),
          {},
          {
            duration: 3000,
            panelClass: 'msg-success',
          },
        );
      }
    });
  }

  openFreezeDialog(organizations: Organization[]): void {
    const dialogRef = this.dialog.open(OrganizationListFreezeDialogComponent, {
      data: {
        organizations: organizations,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      if (result.updated !== 0) {
        this.loadOrganizations();
        if (result.isFrozen) {
          this.snackbar.success(
            organizations.length === 1
              ? this.translate.instant('app.panel.admin.component.organizationHasBeenFrozen', { name: organizations[0].parsec_id })
              : this.translate.instant('app.panel.admin.component.organizationsHaveBeenFrozen', { count: organizations.length }),
          );
        } else {
          this.snackbar.success(
            organizations.length === 1
              ? this.translate.instant('app.panel.admin.component.organizationHasBeenUnfrozen', { name: organizations[0].parsec_id })
              : this.translate.instant('app.panel.admin.component.organizationsHaveBeenUnfrozen', { count: organizations.length }),
          );
        }
      }
    });
  }
}
