import { EventEmitter, Injectable, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

export interface ReadNotificationEntry extends NotificationEntry {
  is_read: true;
}

export interface UnreadNotificationEntry extends NotificationEntry {
  is_read: false;
}

export interface NotificationEntry {
  pk: string;
  created_at: string;
  code: string;
  category: string;
  is_read: boolean;
  data: NotificationData | NotificationDataV0;
}

interface NotificationData {
  client: {
    id: string;
  };
  organization?: {
    id: string;
    parsec_id: string;
  };
  [key: string]: any;
}

interface NotificationDataV0 {
  client_pk: string;
  organization_pk?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  @Output() onUpdate = new EventEmitter<any>();
  displayed = false;
  unreadsViewSet;
  readsViewSet;
  unreads: UnreadNotificationEntry[] = [];
  reads: ReadNotificationEntry[] = [];
  unreadsCount = 0;
  readsCount = 0;

  constructor(
    private auth: AuthService,
    private api: ApiService,
  ) {}

  toggleVisibility(): void {
    this.displayed = !this.displayed;
  }

  hide(): void {
    this.displayed = false;
  }

  update(options?: {
    reads?: { enable: boolean; page?: number };
    unreads?: { enable: boolean; page?: number };
    callback?: () => void;
  }): void {
    let enableReads = true,
      enableUnreads = true;
    let pageReads = 1,
      pageUnreads = 1;
    if (options && options.reads) {
      enableReads = options.reads.enable;
      if (options.reads.page) {
        pageReads = options.reads.page + 1;
      }
    }
    if (options && options.unreads) {
      enableUnreads = options.unreads.enable;
      if (options.unreads.page) {
        pageUnreads = options.unreads.page + 1;
      }
    }
    const user = this.auth.whoIs();
    const url = `/users/${user.user_id}/notifications`;
    const unreadsReq = this.api.get(`${url}?is_read=false&page=${pageUnreads}`);
    const readsReq = this.api.get(`${url}?is_read=true&page=${pageReads}`);
    const reqs = [];
    if (enableUnreads) {
      reqs.push(unreadsReq);
    }
    if (enableReads) {
      reqs.push(readsReq);
    }
    forkJoin(reqs).subscribe(
      (responses) => {
        if (enableUnreads) {
          this.unreadsViewSet = responses[0];
          this.unreads = responses[0]['results'];
          this.unreadsCount = responses[0]['count'];
          if (enableReads) {
            this.readsViewSet = responses[1];
            this.reads = responses[1]['results'];
            this.readsCount = responses[1]['count'];
          }
        } else if (enableReads) {
          this.readsViewSet = responses[0];
          this.reads = responses[0]['results'];
          this.readsCount = responses[0]['count'];
        }
        if (options.callback) {
          options.callback();
        }
      },
      () => {
        this.unreads = [];
        this.reads = [];
      },
    );
  }

  getUnreads(): UnreadNotificationEntry[] {
    return this.unreads;
  }

  getReads(): ReadNotificationEntry[] {
    return this.reads;
  }

  countUnreads(): number {
    return this.unreadsCount;
  }

  countReads(): number {
    return this.readsCount;
  }

  updateReadStatus(pk: string = ''): void {
    const user = this.auth.whoIs();
    const list = [];
    if (pk) {
      const notif = this.unreads.filter((elt) => elt.pk === pk)[0];
      list.push(notif.pk);
    } else {
      this.unreads.forEach((notif) => {
        if (!notif.is_read) {
          list.push(notif.pk);
        }
      });
    }
    if (list.length > 0) {
      this.api.put(`/users/${user.user_id}/notifications/as_read`, { notifications: list }).subscribe(() => {
        this.unreadsCount = this.unreadsCount - list.length;
        this.onUpdate.emit('unreads');
      });
    }
  }

  delete(pk: string = ''): void {
    const user = this.auth.whoIs();
    const list = [];
    if (pk) {
      list.push(pk);
    } else {
      this.reads.forEach((notif) => {
        if (notif.is_read) {
          list.push(notif.pk);
        }
      });
    }
    if (list.length > 0) {
      this.api.put(`/users/${user.user_id}/notifications/delete_list`, { notifications: list }).subscribe(() => {
        this.readsCount = this.readsCount - list.length;
        this.onUpdate.emit('reads');
      });
    }
  }
}
