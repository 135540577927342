import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/api.service';
import { AdminAPI, BillingSystem, Filter } from '../admin.global';
import { Client, ClientListEntry, SwitchBillingSystemResponse } from './client-list.global';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  baseUrl = '/api/adm/clients';
  filters: Filter[] = [
    {
      key: 'billingSystemIsNone',
      enabled: false,
      fields: [
        {
          name: 'billing_system',
          value: BillingSystem.None,
        },
      ],
    },
    {
      key: 'billingSystemIsCustomOrderCandidate',
      enabled: false,
      fields: [
        {
          name: 'billing_system',
          value: BillingSystem.CustomOrderCandidate,
        },
      ],
    },
    {
      key: 'billingSystemIsCustomOrder',
      enabled: false,
      fields: [
        {
          name: 'billing_system',
          value: BillingSystem.CustomOrder,
        },
      ],
    },
    {
      key: 'billingSystemIsExperimentalCandidate',
      enabled: false,
      fields: [
        {
          name: 'billing_system',
          value: BillingSystem.ExperimentalCandidate,
        },
      ],
    },
    {
      key: 'billingSystemIsStripe',
      enabled: false,
      fields: [
        {
          name: 'billing_system',
          value: BillingSystem.Stripe,
        },
      ],
    },
  ];

  constructor(private api: ApiService) {}

  getFilters(enabledOnly = false): Filter[] {
    return enabledOnly ? this.filters.filter((filter) => filter.enabled === true) : this.filters;
  }

  countEnabledFilters(): number {
    return this.filters.filter((filter) => filter.enabled === true).length;
  }

  list(client_ids: string[] | null = null): Promise<Client[]> {
    const body = {
      client_ids,
      filters: this.filters.filter((filter) => filter.enabled === true).flatMap((filter) => filter.fields),
    };

    let clients: Client[] = [];
    return new Promise<Client[]>((resolve, reject) => {
      this.api.post(`${this.baseUrl}/list`, body).subscribe({
        next: (response: ClientListEntry[]) => {
          clients = response.map((entry: ClientListEntry) => {
            let client: Client = {
              ...entry,
              name: entry.company ? entry.company : entry.firstname + ' ' + entry.lastname,
            };
            return client;
          });
          resolve(clients);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  switchBillingSystem(clients: Client[], context: string): Promise<SwitchBillingSystemResponse> {
    return this.api
      .post(`${this.baseUrl}/switch-billing-system`, {
        context: context,
        client_ids: clients ? clients.map((value) => value.id) : null,
      })
      .toPromise();
  }

  registerExperimentalCandidate(clients: Client[], expirationDate: Date): Promise<SwitchBillingSystemResponse> {
    return this.api
      .post(`${this.baseUrl}/switch-billing-system`, {
        context: AdminAPI.BillingSystemSwitchContext.ExperimentalCandidateSwitch,
        client_ids: clients ? clients.map((value) => value.id) : null,
        experimental_candidate_expiration_date: expirationDate,
      })
      .toPromise();
  }
}
