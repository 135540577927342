import { Injectable } from '@angular/core';
import { Cookie, JWT } from './tools';

export interface UserInfo {
  email: string;
  is_staff: boolean;
  user_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  isAuthenticated(): boolean {
    const token = Cookie.get('access_token');
    if (token) {
      return !this.isTokenExpired(token);
    }
    return false;
  }

  isTokenExpired(token: string): boolean {
    return JWT.isExpired(token);
  }

  repudiate(): void {
    Cookie.delete('access_token');
    Cookie.delete('refresh_token');
  }

  whoIs(): UserInfo | undefined {
    const token = Cookie.get('access_token');
    if (token) {
      const data = JWT.decode(token);
      return {
        email: data.email,
        is_staff: data.is_staff,
        user_id: data.user_id,
      };
    }
    return undefined;
  }
}
