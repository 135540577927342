import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(private translate: TranslateService) {}

  getLocaleString(date: string): string {
    return new Date(date).toLocaleString(this.translate.currentLang);
  }
}
