import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';

export interface Client {
  id: string;
  created_at: string;
  firstname: string;
  lastname: string;
  phone: string;
  country: string;
  billing_system: string;
  experimental_candidate_expiration_date: string;
  stripe_customer_id: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  selectedTab = '';
  isRegistered = false;
  user;
  selectedClients: any[];
  tabs = ['clients', 'organizations', 'extract-invoices', 'custom-requests', 'help'];
  selectedQuestion = '';

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const user = this.auth.whoIs();
      this.api.get('/users/' + user.user_id).subscribe(
        (response) => {
          this.user = response;
          this.isRegistered = response.client?.stripe_customer_id ? true : false;
          if (params.has('tab')) {
            if (this.tabs.includes(params.get('tab'))) {
              this.selectedTab = params.get('tab');
              if (this.selectedTab === 'help') {
                if (params.has('question')) {
                  this.selectedQuestion = params.get('question');
                }
              }
              if (this.selectedTab !== 'help') {
                if (params.has('question')) {
                  this.router.navigate([`/panel/admin/${this.selectedTab}`]);
                }
              }
            } else {
              this.router.navigate(['/panel/admin']);
            }
          }
        },
        (_response: HttpErrorResponse) => {
          this.auth.repudiate();
          this.router.navigate(['']);
        },
      );
    });
  }

  goToOrganizations(clients: Client[]): void {
    this.selectTab('organizations');
    this.selectedClients = clients;
  }

  goToClient(clients: Client[]): void {
    this.selectTab('clients');
    this.selectedClients = clients;
  }

  removeSelectedClients(): void {
    this.selectedClients = [];
  }

  selectTab(tab: string, question: string = ''): void {
    if (this.tabs.includes(tab)) {
      let url = `/panel/admin/${tab}`;
      if (question) {
        if (tab === 'help') {
          url = `${url}/${question}`;
        }
      }
      this.router.navigate([url]);
    } else {
      this.router.navigate(['/panel/admin']);
    }
  }
}
