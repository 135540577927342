import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/api.service';
import { SnackbarService } from '../shared/snackbar.service';

@Component({
  selector: 'app-resend-creation-link',
  templateUrl: './resend-creation-link.component.html',
  styleUrls: ['./resend-creation-link.component.scss'],
})
export class ResendCreationLinkComponent {
  email = '';
  mailSent = false;
  constructor(
    private api: ApiService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
  ) {}

  sendMail(): void {
    if (this.email) {
      this.api
        .post('/promised_accounts/resend_creation_mail', {
          email: this.email,
          lang: this.translate.currentLang,
        })
        .subscribe((result) => {
          console.log(result);
          this.mailSent = true;
          this.snackbar.success(this.translate.instant('app.resendCreationLink.component.mailSent'));
        });
    }
  }
}
