<app-navbar></app-navbar>
<div class="container notification">
  <mat-card>
    <mat-nav-list>
      <div
        mat-subheader
        class="d-flex justify-content-between align-items-center"
      >
        {{ 'referentials.notifications.newList' | translate }}
        <button
          mat-icon-button
          (click)="notification.updateReadStatus()"
        >
          <mat-icon>playlist_add_check</mat-icon>
        </button>
      </div>
      <ng-container *ngFor="let notif of unreads">
        <a
          [routerLink]="notif.link"
          (click)="toggleVisibility(); notification.updateReadStatus(notif.pk)"
          *ngIf="notif.isUnread"
          mat-list-item
        >
          <mat-icon matListIcon>{{ icons[notif.type] }}</mat-icon>
          <span
            mat-line
            class="font-weight-bold"
            >{{ notif.message.code | translate: notif.message.interpolateParams }}</span
          >
          <span
            mat-line
            class="font-italic"
            >{{ date.getLocaleString(notif.date) }}</span
          >
          <button
            mat-icon-button
            (click)="notification.updateReadStatus(notif.pk); $event.preventDefault(); $event.stopPropagation()"
          >
            <mat-icon>done</mat-icon>
          </button>
        </a>
      </ng-container>
    </mat-nav-list>
    <mat-paginator
      *ngIf="notification.unreadsViewSet && (notification.unreadsViewSet.previous || notification.unreadsViewSet.next)"
      [length]="notification.unreadsViewSet.count"
      [pageSize]="5"
      (page)="onUnreadsPageChange($event)"
    >
    </mat-paginator>
    <ng-container *ngIf="notification.countUnreads() === 0">
      <div class="nothing-new d-flex justify-content-center">
        <span>{{ 'referentials.notifications.nothingNew' | translate }}</span>
      </div>
    </ng-container>
    <mat-nav-list>
      <div
        mat-subheader
        class="d-flex justify-content-between align-items-center"
      >
        {{ 'referentials.notifications.oldList' | translate }}
        <button
          mat-icon-button
          (click)="notification.delete()"
        >
          <mat-icon>delete_sweep</mat-icon>
        </button>
      </div>
      <ng-container *ngFor="let notif of reads">
        <a
          [routerLink]="notif.link"
          (click)="toggleVisibility()"
          *ngIf="!notif.isUnread"
          mat-list-item
        >
          <mat-icon matListIcon>{{ icons[notif.type] }}</mat-icon>
          <span
            mat-line
            class="font-weight-bold"
            >{{ notif.message.code | translate: notif.message.interpolateParams }}</span
          >
          <span
            mat-line
            class="font-italic"
            >{{ date.getLocaleString(notif.date) }}</span
          >
          <button
            mat-icon-button
            (click)="notification.delete(notif.pk); $event.preventDefault(); $event.stopPropagation()"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </a>
      </ng-container>
    </mat-nav-list>
    <mat-paginator
      *ngIf="notification.readsViewSet && (notification.readsViewSet.previous || notification.readsViewSet.next)"
      [length]="notification.readsViewSet.count"
      [pageSize]="5"
      (page)="onReadsPageChange($event)"
    >
    </mat-paginator>
    <ng-container *ngIf="notification.countReads() === 0">
      <div class="nothing-old d-flex justify-content-center">
        <span>{{ 'referentials.notifications.nothingOld' | translate }}</span>
      </div>
    </ng-container>
  </mat-card>
</div>
