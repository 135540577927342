import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Cookie } from './tools';

interface AccessTokens {
  access: string;
  refresh: string;
}

interface RefreshToken {
  access: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  httpOptions: any;
  errors = [];
  constructor(private http: HttpClient) {}

  get(url: string, options?: any): Observable<any> {
    return this.http.get(environment.apiUrl + url, options);
  }

  post(url: string, body: any, options?: any): Observable<any> {
    return this.http.post(environment.apiUrl + url, body, options);
  }

  patch(url: string, body: any, options?: any): Observable<any> {
    return this.http.patch(environment.apiUrl + url, body, options);
  }

  put(url: string, body: any, options?: any): Observable<any> {
    return this.http.put(environment.apiUrl + url, body, options);
  }

  authenticate(credentials: { email: string; password: string }): Observable<AccessTokens> {
    this.repudiate();
    return this.post('/api/token', {
      email: credentials.email,
      password: credentials.password,
    }).pipe(
      tap((response: AccessTokens) => {
        Cookie.set('access_token', response.access);
        Cookie.set('refresh_token', response.refresh);
      }),
    );
  }

  refresh(refresh_token: string): Observable<RefreshToken> {
    return this.post('/api/token/refresh', {
      refresh: refresh_token,
    }).pipe(
      tap((response: RefreshToken) => {
        Cookie.set('access_token', response.access);
      }),
    );
  }

  repudiate(): void {
    Cookie.delete('access_token');
    Cookie.delete('refresh_token');
  }
}
