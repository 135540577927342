<h1 mat-dialog-title>{{'app.panel.billing.component.addPaymentMethod' | translate}}</h1>
<div mat-dialog-content>
  <div class="row justify-content-center">
    <mat-form-field
      class="col-12"
      appearance="outline"
    >
      <mat-label>{{'app.panel.billing.component.desiredPaymentMethod' | translate}}</mat-label>
      <mat-select
        required
        [(ngModel)]="desiredPaymentMethod"
      >
        <mat-option
          *ngFor="let desiredPaymentMethod of data.desiredPaymentMethods"
          [value]="desiredPaymentMethod.code"
        >
          {{desiredPaymentMethod.label | translate}}
        </mat-option>
      </mat-select>
      <mat-error>{{'error.mandatoryField' | translate}}</mat-error>
    </mat-form-field>
  </div>
</div>
<div
  *ngIf="desiredPaymentMethod === 'card'"
  class="row justify-content-center pl-3 pr-3"
>
  <div
    class="col-12 stripe-card-container"
    style="border: 1px solid rgba(0, 0, 0, 0.07); margin-bottom: 1em"
  >
    <ngx-stripe-card
      [options]="cardOptions"
      [elementsOptions]="elementsOptions"
    ></ngx-stripe-card>
  </div>
</div>
<div
  *ngIf="desiredPaymentMethod === 'debit'"
  class="row justify-content-center"
>
  Pas encore dispo
</div>
<div
  mat-dialog-actions
  class="row justify-content-end"
>
  <button
    mat-raised-button
    mat-dialog-close
  >
    {{'app.panel.billing.defaultPaymentMethodDialog.cancel' | translate}}
  </button>
  <button
    mat-raised-button
    color="accent"
    cdkFocusInitial
    (click)="addPaymentMethod()"
  >
    {{'app.panel.billing.defaultPaymentMethodDialog.confirm' | translate}}
  </button>
</div>
