<mat-nav-list>
  <a
    routerLink="/feedback"
    [title]="data['somethingToReport']"
    mat-list-item
    (click)="openLink($event)"
  >
    <mat-icon matListIcon>feedback</mat-icon>
    <span mat-line>{{data['somethingToReport']}}</span>
  </a>
  <a
    routerLink="/help"
    [title]="data['needHelp']"
    mat-list-item
    (click)="openLink($event)"
  >
    <mat-icon matListIcon>help</mat-icon>
    <span mat-line>{{data['needHelp']}}</span>
  </a>
  <a
    [href]="getUrl('termsAndConditions')"
    target="_blank"
    [title]="data['termsAndConditions']"
    mat-list-item
    (click)="openLink($event)"
  >
    <mat-icon matListIcon>balance</mat-icon>
    <span mat-line>{{data['termsAndConditions']}}</span>
  </a>
  <a
    [href]="getUrl('terms')"
    target="_blank"
    [title]="data['legalTerms']"
    mat-list-item
    (click)="openLink($event)"
  >
    <mat-icon matListIcon>balance</mat-icon>
    <span mat-line>{{data['legalTerms']}}</span>
  </a>
  <a
    [href]="getUrl('privacy')"
    target="_blank"
    [title]="data['privacyPolicy']"
    mat-list-item
    (click)="openLink($event)"
  >
    <mat-icon matListIcon>policy</mat-icon>
    <span mat-line>{{data['privacyPolicy']}}</span>
  </a>
  <a
    href="https://parsec.cloud"
    target="_blank"
    title="Parsec.cloud"
    mat-list-item
    (click)="openLink($event)"
  >
    <mat-icon matListIcon>open_in_new</mat-icon>
    <span mat-line>Parsec.cloud</span>
  </a>
  <mat-list-item (click)="showLangs = !showLangs">
    <mat-icon matListIcon>translate</mat-icon>
    <span mat-line>{{getLangLabel(selectedLang)}}</span>
    <mat-icon
      class="menu-button"
      [ngClass]="{'rotated' : showLangs}"
      >expand_more</mat-icon
    >
  </mat-list-item>
  <div
    class="submenu"
    [ngClass]="{'expanded' : showLangs}"
    *ngIf="isShowing || isExpanded"
  >
    <mat-list-item
      (click)="selectLang(lang.key)"
      *ngFor="let lang of langs"
    >
      <span *ngIf="isExpanded || isShowing">{{lang.label}}</span>
    </mat-list-item>
  </div>
</mat-nav-list>
