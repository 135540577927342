import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';

export class CustomValidators {
  static Password(control: AbstractControl) {
    const password = control.value;
    let passwordStrength = 0;

    if (password.length === 0) {
      return { required: true };
    }

    if (password.length < 12) {
      return { minLength: true };
    } else {
      if (/[a-z]/.test(password)) {
        passwordStrength++;
      }
      if (/[A-Z]/.test(password)) {
        passwordStrength++;
      }
      if (/\d/.test(password)) {
        passwordStrength++;
      }
      if (/[-+_!@#$%^&*.,?{}:;]/.test(password)) {
        passwordStrength++;
      }

      return passwordStrength < 4 ? { invalid: true } : null;
    }
  }
  static Form(AC: AbstractControl): ValidationErrors | null {
    const password = AC.get('password');
    const confirmPassword = AC.get('confirmPassword');
    const phone = AC.get('phone');
    const _line1 = AC.get('line1');
    const _line2 = AC.get('line2');
    const postalCode = AC.get('postalCode');
    const _city = AC.get('city');
    const _state = AC.get('state');
    const _country = AC.get('country');

    if (confirmPassword) {
      if (confirmPassword.value.length === 0) {
        confirmPassword.setErrors({ required: true });
      } else {
        if (confirmPassword.value !== password?.value) {
          confirmPassword.setErrors({ invalid: true });
        } else {
          confirmPassword.setErrors(null);
        }
      }
    }

    if (phone) {
      if (phone.value.length === 0) {
        phone.setErrors({ required: true });
      } else {
        try {
          const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
          const countryIso = AC.get('country')?.value;
          if (!phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone.value, countryIso))) {
            phone.setErrors({ invalid: true });
          } else {
            phone.setErrors(null);
          }
        } catch (e) {
          phone.setErrors({ invalid: true });
        }
      }
    }

    if (postalCode) {
      if (/^\d{5}$/.test(postalCode.value)) {
        postalCode.setErrors(null);
      } else {
        postalCode.setErrors({ invalid: true });
      }
    }
    return null;
  }
}
