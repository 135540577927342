<h1 mat-dialog-title>{{ 'app.panel.admin.customRequestList.updateDialog.title' | translate }}</h1>
<div mat-dialog-content>
  <form
    [formGroup]="formGroup"
    id="formGroup"
    (submit)="submit()"
  >
    <div class="row justify-content-center">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'app.panel.admin.customRequestList.updateDialog.comment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comment"
          placeholder="N/A"
          rows="10"
          [errorStateMatcher]="errorStateMatcher"
        ></textarea>
        <mat-error>
          {{ 'referentials.errors.invalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row justify-content-center">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'app.panel.admin.customRequestList.updateDialog.organizationName' | translate }}</mat-label>
        <input
          matInput
          formControlName="organizationName"
          placeholder="N/A"
          [errorStateMatcher]="errorStateMatcher"
        />
        <mat-error>
          {{ 'referentials.errors.invalidOrganizationName' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div
  mat-dialog-actions
  class="d-flex justify-content-end"
>
  <button
    mat-raised-button
    (click)="cancel()"
  >
    {{ 'app.panel.admin.customRequestList.updateDialog.cancel' | translate }}
  </button>
  <button
    mat-raised-button
    color="accent"
    type="submit"
    form="formGroup"
    cdkFocusInitial
  >
    {{ 'app.panel.admin.customRequestList.updateDialog.submit' | translate }}
  </button>
</div>
