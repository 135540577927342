<div class="container">
  <div class="row align-items-center justify-content-center row-container">
    <mat-card *ngIf="validated">
      <div>
        <div class="row align-items-center">
          <div class="col-10 offset-1">
            <h3 class="text-center form-label">{{ 'app.validate.component.accountEnabled' | translate }}</h3>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 logo d-flex justify-content-center align-items-center">
            <mat-icon class="check-icon text-center">check_circle_outline</mat-icon>
          </div>
        </div>
        <div class="row">
          <div class="col-10 offset-1">
            <p>{{ 'app.validate.component.validated' | translate }}</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 d-flex justify-content-end">
            <button
              mat-raised-button
              color="accent"
              routerLink="/"
            >
              {{ 'app.global.backToHome' | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card *ngIf="expired">
      <div>
        <div class="row align-items-center">
          <div class="col-10 offset-1">
            <h3 class="text-center form-label">{{ 'error.occured' | translate }}</h3>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 logo d-flex justify-content-center align-items-center">
            <mat-icon class="error-icon text-center">error</mat-icon>
          </div>
        </div>
        <div class="row">
          <div class="col-10 offset-1">
            <p>{{ 'error.tokenExpired' | translate }}</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 d-flex justify-content-end">
            <button
              mat-raised-button
              color="accent"
              routerLink="/"
            >
              {{ 'app.global.backToHome' | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
