import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  selectedLang = 'fr';
  private popupOpenSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private bottomSheet: MatBottomSheet,
  ) {}

  ngOnInit(): void {
    this.translate.setDefaultLang('fr');
    this.translate.use(localStorage.getItem('lang') || this.translate.getBrowserLang() || 'fr');
    this.selectedLang = this.translate.currentLang;
  }

  selectLang(event: MatSelectChange): void {
    this.translate.use(event.value);
    localStorage.setItem('lang', event.value);
  }

  getUrl(documentName: string): string {
    if (documentName === 'termsAndConditions') {
      return `/assets/pdf/Parsec-CGVU-${this.translate.currentLang}.pdf`;
    } else {
      const url = 'https://parsec.cloud';
      const doc = documentName.replace('privacy', 'politique-de-confidentialite').replace('terms', 'mentions-legales');
      switch (this.translate.currentLang) {
        case 'fr':
          return `${url}/${doc}`;
        case 'en':
          return `${url}/${this.translate.currentLang}/${doc}/`;
        default:
          return `${url}/${doc}`;
      }
    }
  }

  ngOnDestroy(): void {
    this.popupOpenSubscription.unsubscribe();
  }

  openMobileFooter(): void {
    this.translate.get('app.component').subscribe((texts) => {
      this.bottomSheet.open(MobileFooterBottomSheet, { data: texts });
    });
  }
}

@Component({
  selector: 'mobile-footer-bottom-sheet',
  templateUrl: 'mobile-footer-bottom-sheet.html',
})
export class MobileFooterBottomSheet {
  selectedLang = 'fr';
  langs = [
    {
      key: 'fr',
      label: 'Français',
    },
    {
      key: 'en',
      label: 'English',
    },
  ];
  showLangs = false;
  isExpanded = true;
  isShowing = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { texts: any },
    private bottomSheetRef: MatBottomSheetRef<MobileFooterBottomSheet>,
    private translate: TranslateService,
  ) {
    this.selectedLang = this.translate.currentLang;
  }

  openLink(_event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
  }

  getUrl(documentName: string): string {
    if (documentName === 'termsAndConditions') {
      return `/assets/pdf/Parsec-CGVU-${this.translate.currentLang}.pdf`;
    } else {
      const url = 'https://parsec.cloud';
      const doc = documentName.replace('privacy', 'politique-de-confidentialite').replace('terms', 'mentions-legales');
      switch (this.translate.currentLang) {
        case 'fr':
          return `${url}/${doc}`;
        case 'en':
          return `${url}/${this.translate.currentLang}/${doc}/`;
        default:
          return `${url}/${doc}`;
      }
    }
  }

  selectLang(lang: string): void {
    this.bottomSheetRef.dismiss();
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }

  getLangLabel(key: string): string {
    return this.langs.filter((lang) => lang.key === key)[0]['label'];
  }
}
