<div class="row no-gutters align-items-center justify-content-end filters">
  <mat-form-field>
    <mat-label>{{ 'app.panel.admin.component.search' | translate }}</mat-label>
    <input
      matInput
      (keyup)="applySearch($event)"
      #input
    />
  </mat-form-field>
  <button
    mat-button
    [ngClass]="{ 'font-weight-bold': clientService.countEnabledFilters() > 0 }"
    [matMenuTriggerFor]="filtersMenu"
    (click)="$event.stopPropagation()"
  >
    <mat-icon>filter_list</mat-icon>
    <span>
      {{ 'app.panel.admin.component.filter' | translate }}
      <ng-container *ngIf="clientService.countEnabledFilters() > 0">({{ clientService.countEnabledFilters() }})</ng-container>
    </span>
  </button>
  <mat-menu
    class="mat-menu-nowrap"
    #filtersMenu="matMenu"
  >
    <button
      [ngClass]="{ 'mat-button-end-icon': filter.enabled }"
      mat-menu-item
      (click)="filter.enabled = !filter.enabled; loadClients()"
      *ngFor="let filter of clientService.filters"
    >
      <span>{{ 'app.panel.admin.clientList.component.filters.' + filter.key | translate }}</span>
      <mat-icon *ngIf="filter.enabled">check</mat-icon>
    </button>
  </mat-menu>
</div>
<mat-card
  class="row no-gutters align-items-center justify-content-begin selection-actions"
  *ngIf="!selection.isEmpty()"
>
  <button
    mat-button
    (click)="goToOrganizations.emit(selection.selected)"
  >
    <span>{{ 'app.panel.admin.component.goToOrganizations' | translate }}</span>
  </button>
  <button
    mat-button
    (click)="openExperimentalCandidateDialog(selection.selected)"
    [disabled]="
      !isAuthorizedAction([BillingSystem.CustomOrder, BillingSystem.None, BillingSystem.CustomOrderCandidate], selection.selected)
    "
  >
    <span>{{ 'app.panel.admin.component.registerToExperimentalCandidate' | translate }}</span>
  </button>
  <button
    mat-button
    (click)="switchBillingSystem(selection.selected, AdminAPI.BillingSystemSwitchContext.CustomOrderSwitch)"
    [disabled]="!isAuthorizedAction([BillingSystem.None, BillingSystem.ExperimentalCandidate], selection.selected)"
  >
    <span>{{ 'app.panel.admin.component.registerToCustomOrder' | translate }}</span>
  </button>
  <button
    mat-button
    (click)="switchBillingSystem(selection.selected, AdminAPI.BillingSystemSwitchContext.CustomOrderCandidateApproval)"
    [disabled]="!isAuthorizedAction([BillingSystem.CustomOrderCandidate], selection.selected)"
  >
    <span>{{ 'app.panel.admin.component.acceptCustomOrderRequest' | translate }}</span>
  </button>
  <button
    mat-button
    (click)="switchBillingSystem(selection.selected, AdminAPI.BillingSystemSwitchContext.NoneSwitch)"
    [disabled]="!isAuthorizedAction([BillingSystem.CustomOrderCandidate, BillingSystem.ExperimentalCandidate], selection.selected)"
  >
    <span>{{ 'app.panel.admin.component.switchBackToFree' | translate }}</span>
  </button>
</mat-card>
<div class="mat-elevation-z8">
  <table
    mat-table
    [dataSource]="clientsDataSource"
    matSort
    multiTemplateDataRows
  >
    <ng-container matColumnDef="select">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          *ngIf="clientsDataSource.data.length > 0 && clientsDataSource.filteredData.length > 0"
        >
        </mat-checkbox>
      </th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.clientList.component.name' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let client"
        class="font-weight-bold"
      >
        {{ client.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="created_at">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.clientList.component.createdAt' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let client"
      >
        {{ date.getLocaleString(client.created_at) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.clientList.component.email' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let client"
      >
        <a href="mailto:{{ client.email }}">{{ client.email }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.clientList.component.phone' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let client"
      >
        {{ client.phone }}
      </td>
    </ng-container>
    <ng-container matColumnDef="job">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.clientList.component.job' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let client"
      >
        {{ client.job }}
      </td>
    </ng-container>
    <ng-container matColumnDef="billing_system">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.clientList.component.billingSystem' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let client"
      >
        {{ getBillingSystemLabel(client.billing_system) }}
        <span
          class="font-italic"
          *ngIf="client.billing_system === BillingSystem.ExperimentalCandidate"
          >({{ getExpirationDateString(client) }})</span
        >
        <a
          mat-icon-button
          class="icon-tooltip"
          aria-label="billing system about"
          matTooltipPosition="right"
          routerLink="/panel/admin/help/billing-system"
          [matTooltip]="getBillingSystemTooltip(client.billing_system)"
        >
          <mat-icon>help</mat-icon>
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="options">
      <th
        mat-header-cell
        *matHeaderCellDef
        aria-label="client actions"
      >
        &nbsp;
      </th>
      <td
        mat-cell
        *matCellDef="let client"
      >
        <button
          mat-icon-button
          aria-label="options client"
          [matMenuTriggerFor]="clientOptionsMenu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #clientOptionsMenu="matMenu">
          <button
            mat-menu-item
            (click)="goToOrganizations.emit([client])"
          >
            <span>{{ 'app.panel.admin.component.goToOrganizations' | translate }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="isAuthorizedAction([BillingSystem.CustomOrder, BillingSystem.None, BillingSystem.CustomOrderCandidate], [client])"
            (click)="openExperimentalCandidateDialog([client])"
          >
            <span>{{ 'app.panel.admin.component.registerToExperimentalCandidate' | translate }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="isAuthorizedAction([BillingSystem.None, BillingSystem.ExperimentalCandidate], [client])"
            (click)="switchBillingSystem([client], AdminAPI.BillingSystemSwitchContext.CustomOrderSwitch)"
          >
            <span>{{ 'app.panel.admin.component.registerToCustomOrder' | translate }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="isAuthorizedAction([BillingSystem.CustomOrderCandidate], [client])"
            (click)="switchBillingSystem([client], AdminAPI.BillingSystemSwitchContext.CustomOrderCandidateApproval)"
          >
            <span>{{ 'app.panel.admin.component.acceptCustomOrderRequest' | translate }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="isAuthorizedAction([BillingSystem.CustomOrderCandidate, BillingSystem.ExperimentalCandidate], [client])"
            (click)="switchBillingSystem([client], AdminAPI.BillingSystemSwitchContext.NoneSwitch)"
          >
            <span>{{ 'app.panel.admin.component.switchBackToFree' | translate }}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="clientsColumnsToDisplayWithExpand"
    ></tr>
    <tr
      mat-row
      *matRowDef="let client; columns: clientsColumnsToDisplayWithExpand"
      class="example-element-row"
    ></tr>
    <tr
      class="mat-row"
      *matNoDataRow
    >
      <td
        class="mat-cell"
        [attr.colspan]="clientsColumnsToDisplayWithExpand.length"
      >
        <ng-container *ngIf="input.value">
          {{ 'app.panel.admin.component.noDataMatchingFilter' | translate }} "{{ input.value }}"
        </ng-container>
        <ng-container *ngIf="!input.value && clientsLoaded">
          {{ 'app.panel.admin.component.noResults' | translate }}
        </ng-container>
        <mat-spinner
          diameter="40"
          *ngIf="!clientsLoaded"
        ></mat-spinner>
      </td>
    </tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page of clients"
  ></mat-paginator>
</div>
