import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { CustomValidators } from 'src/app/shared/validators';
import { CustomErrorStateMatcher } from '../../customer-profile/customer-profile.component';

@Component({
  selector: 'app-extract-invoices',
  templateUrl: './extract-invoices.component.html',
  styleUrls: ['./extract-invoices.component.scss'],
})
export class ExtractInvoicesComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  errorStateMatcher = new CustomErrorStateMatcher();
  maxDate: Date;
  admUrl = '/api/adm/export-stripe-invoices';
  emails: string[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private auth: AuthService,
    private snackbar: SnackbarService,
    private api: ApiService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.maxDate = new Date();
    this.formGroup = this.formBuilder.group(
      {
        from: ['', Validators.required],
        to: ['', Validators.required],
        email: ['', Validators.required],
      },
      {
        updateOn: 'change',
        validators: CustomValidators.Form,
      },
    );
    if (this.auth.isAuthenticated()) {
      const user = this.auth.whoIs();
      this.formGroup.get('email').setValue(user.email);
      this.updateEmails();
    }
  }

  submit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    const from = this.formGroup.get('from').value;
    const to = this.formGroup.get('to').value;
    this.api.post(this.admUrl, { start_date: from, end_date: to, emails: this.emails }).subscribe(() => {
      this.snackbar.success(this.translate.instant('app.panel.admin.extractInvoices.component.exportStarted'));
    });
  }

  updateFromDate(date: Date): void {
    const toDate: Date = new Date(date);
    toDate.setDate(toDate.getDate() - 1);
    if (date && date < this.formGroup.get('from').value) {
      this.formGroup.get('from').setValue(toDate);
    }
  }

  getMaxDate(): Date {
    if (this.formGroup.get('to').value) {
      const maxDate = new Date(this.formGroup.get('to').value);
      maxDate.setDate(maxDate.getDate() - 1);
      return maxDate;
    }
    return this.maxDate;
  }

  updateEmails(): void {
    const emails = this.formGroup
      .get('email')
      .value.split(',')
      .map((email) => email.trim());
    this.emails = emails.filter((email) => email !== '');
  }
}
