import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { MatImportModule } from '../mat-import.module';
import { NavbarComponent } from './navbar.component';
import { NotificationCenterComponent } from './notification-center/notification-center.component';

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, AppRoutingModule, MatImportModule, TranslateModule],
  declarations: [NavbarComponent, NotificationCenterComponent],
  exports: [NavbarComponent],
})
export class NavbarModule {}
