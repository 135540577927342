import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface Question {
  id: string;
  i18nKey: string;
  texts: {
    [key: string]: string;
  };
}

@Component({
  selector: 'app-admin-help',
  templateUrl: './admin-help.component.html',
  styleUrls: ['./admin-help.component.scss'],
})
export class AdminHelpComponent implements OnInit {
  panelOpenState = false;
  target = '';
  expandedQuestionId = '';
  questions = [
    {
      id: 'billing-system',
      i18nKey: 'app.panel.admin.adminHelp.component.questions.billingSystem',
      texts: {
        'app.panel.admin.billingSystemLabels': 'app.panel.admin.billingSystemTooltips',
      },
    },
    {
      id: 'custom-order-status',
      i18nKey: 'app.panel.admin.adminHelp.component.questions.customOrderStatus',
      texts: {
        'app.panel.admin.customOrderStatusLabels': 'app.panel.admin.customOrderStatusTooltips',
      },
    },
  ];
  @Input() question: string;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.expandedQuestionId = '';
    if (this.question) {
      if (this.questions.some((q) => q.id === this.question)) {
        this.expandedQuestionId = this.question;
      } else {
        this.router.navigate(['/panel/admin/help']);
      }
    }
  }

  getQuestionTexts(question: Question): string[] {
    const questionTexts = [];
    if (question.texts) {
      const labels = this.translate.instant(`${Object.keys(question.texts)[0]}`);
      const tooltips = this.translate.instant(`${Object.values(question.texts)[0]}`);
      const colon = this.translate.instant('app.panel.admin.adminHelp.component.colon');
      for (const key in labels) {
        questionTexts.push(`${labels[key]}${colon} ${tooltips[key]}`);
      }
    }
    return questionTexts;
  }

  showQuestion(question: Question): boolean {
    if (this.translate.instant(`${question.i18nKey}.title`).includes(this.target)) {
      return true;
    }
    return false;
  }
}
