<div class="container">
  <div class="row align-items-center justify-content-center row-container">
    <mat-card class="col-10 col-lg-8">
      <div *ngIf="!hasToken">
        <div class="row align-items-center">
          <div class="col-8 offset-2">
            <h3 class="text-center form-label">{{ 'app.changePassword.component.forgotPassword' | translate }}</h3>
          </div>
        </div>
        <ng-container *ngIf="!mailSent">
          <form (keydown.enter)="sendMail(); $event.preventDefault()">
            <div class="row justify-content-center">
              <mat-form-field
                class="col-8"
                appearance="outline"
              >
                <mat-label>{{ 'app.changePassword.component.email' | translate }}</mat-label>
                <mat-icon matPrefix>person</mat-icon>
                <input
                  matInput
                  [(ngModel)]="email"
                  name="emailInput"
                  type="text"
                  required
                />
                <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row justify-content-center">
              <div class="col-8 d-flex justify-content-end">
                <button
                  mat-raised-button
                  color="accent"
                  (click)="sendMail()"
                >
                  {{ 'app.changePassword.component.sendMail' | translate }}
                </button>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="mailSent">
          <div class="row justify-content-center">
            <div class="col-8 logo d-flex justify-content-center align-items-center">
              <mat-icon class="check-icon text-center">check_circle_outline</mat-icon>
            </div>
          </div>
          <div class="row justify-content-center">
            <p>{{ 'app.changePassword.component.mailSent' | translate }}</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-8 d-flex justify-content-end">
              <button
                mat-raised-button
                color="accent"
                routerLink="/"
              >
                {{ 'app.global.backToHome' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="hasToken">
        <div class="row align-items-center">
          <div class="col-10 offset-1">
            <h3 class="text-center form-label">{{ 'app.changePassword.component.passwordChange' | translate }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-10 offset-1">
            <p class="font-weight-bold password-info">{{ 'app.changePassword.component.passwordHint' | translate }}</p>
          </div>
        </div>
        <ng-container *ngIf="!passwordChanged">
          <form
            [formGroup]="formGroup"
            (keydown.enter)="changePassword(); $event.preventDefault()"
          >
            <div class="row justify-content-center">
              <mat-form-field
                class="col-5"
                appearance="outline"
              >
                <mat-label>{{ 'app.changePassword.component.password' | translate }}</mat-label>
                <input
                  type="password"
                  matInput
                  formControlName="password"
                  required
                  (ngModelChange)="checkPassword()"
                  [errorStateMatcher]="errorStateMatcher"
                />
                <mat-hint [style.color]="['', 'red', 'orange', 'green'][passwordStrength]">
                  {{
                    [
                      '',
                      'app.global.weakPassword' | translate,
                      'app.global.mediumPassword' | translate,
                      'app.global.strongPassword' | translate
                    ][passwordStrength]
                  }}
                </mat-hint>
                <mat-error>{{ getError('password') }}</mat-error>
              </mat-form-field>
              <mat-form-field
                class="col-5"
                appearance="outline"
              >
                <mat-label>{{ 'app.changePassword.component.confirmPassword' | translate }}</mat-label>
                <input
                  type="password"
                  matInput
                  formControlName="confirmPassword"
                  required
                  (ngModelChange)="checkConfirmPassword()"
                  [errorStateMatcher]="errorStateMatcher"
                />
                <mat-hint [style.color]="['', 'red', 'green'][confirmPasswordValidity]">
                  {{
                    [
                      'app.global.confirmPasswordValidity.mustMatch' | translate,
                      'app.global.confirmPasswordValidity.doNotMatch' | translate,
                      'app.global.confirmPasswordValidity.match' | translate
                    ][confirmPasswordValidity]
                  }}
                </mat-hint>
                <mat-error>{{ getError('confirmPassword') }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row justify-content-center">
              <div class="col-10 d-flex justify-content-end">
                <button
                  mat-raised-button
                  color="accent"
                  (click)="changePassword()"
                >
                  {{ 'app.changePassword.component.changePassword' | translate }}
                </button>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="passwordChanged">
          <div class="row justify-content-center">
            <div class="col-8 logo d-flex justify-content-center align-items-center">
              <mat-icon class="check-icon text-center">check_circle_outline</mat-icon>
            </div>
          </div>
          <div class="row justify-content-center">
            <p>{{ 'app.changePassword.component.passwordChanged' | translate }}</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-8 d-flex justify-content-end">
              <button
                mat-raised-button
                color="accent"
                routerLink="/"
              >
                {{ 'app.global.backToHome' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-card>
  </div>
</div>
