import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, MobileFooterBottomSheet } from './app.component';
import { ChangeCardComponent } from './change-card/change-card.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HelpComponent } from './help/help.component';
import { MainComponent } from './main/main.component';
import { MatImportModule } from './mat-import.module';
import { NavbarModule } from './navbar/navbar.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { PanelModule } from './panel/panel.module';
import { ResendCreationLinkComponent } from './resend-creation-link/resend-creation-link.component';
import { ApiInterceptor } from './shared/api.interceptor';
import { AuthInterceptor } from './shared/auth.interceptor';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { ValidateComponent } from './validate/validate.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SubscribeComponent,
    NotFoundComponent,
    ValidateComponent,
    ChangePasswordComponent,
    ChangeCardComponent,
    FeedbackComponent,
    HelpComponent,
    ResendCreationLinkComponent,
    MobileFooterBottomSheet,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaV3Module,
    NgxStripeModule.forRoot(environment.stripePublicKey),
    AppRoutingModule,
    MatImportModule,
    NavbarModule,
    PanelModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Lc6P-gUAAAAAAqvYQ0qlDiuUSDMGqYzopAnrzwG',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
