import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/api.service';
import { Filter } from '../admin.global';
import { CustomRequest, CustomRequestListResponse, Status } from './custom-request-list.global';

@Injectable({
  providedIn: 'root',
})
export class CustomRequestService {
  baseUrl = '/api/adm/custom-orders';
  filters: Filter[] = [
    {
      key: 'received',
      enabled: false,
      fields: [
        {
          name: 'status',
          value: Status.Received,
        },
      ],
    },
    {
      key: 'processing',
      enabled: false,
      fields: [
        {
          name: 'status',
          value: Status.Processing,
        },
      ],
    },
    {
      key: 'standby',
      enabled: false,
      fields: [
        {
          name: 'status',
          value: Status.Standby,
        },
      ],
    },
    {
      key: 'finished',
      enabled: false,
      fields: [
        {
          name: 'status',
          value: Status.Finished,
        },
      ],
    },
    {
      key: 'cancelled',
      enabled: false,
      fields: [
        {
          name: 'status',
          value: Status.Cancelled,
        },
      ],
    },
  ];

  constructor(private api: ApiService) {}

  countEnabledFilters(): number {
    return this.filters.filter((filter) => filter.enabled === true).length;
  }

  list(
    search: string | null = null,
    sortField: string,
    order: SortDirection,
    page: number,
    page_size: number,
  ): Observable<CustomRequestListResponse> {
    let ordering = '';
    if (sortField) {
      sortField = sortField === 'name' ? 'firstname,lastname' : sortField;
      ordering = order === 'asc' ? sortField : `-${sortField.split(',').join(',-')}`;
    }

    const filter = this.filters.find((filter) => filter.enabled === true);

    let url = `${this.baseUrl}?page=${page + 1}&ordering=${ordering}&page_size=${page_size}`;
    if (filter || search) {
      url += '&';
      if (filter) {
        url += `${filter.fields[0].name}=${filter.fields[0].value}`;
        url += '&';
      }
      if (search) {
        url += `search=${search}`;
      }
    }

    return this.api.get(url);
  }

  update(id: string, payload: any): Observable<CustomRequest> {
    return this.api.patch(`${this.baseUrl}/${id}`, payload);
  }

  accept(id: string): Observable<CustomRequest> {
    return this.api.post(`${this.baseUrl}/${id}/create-client`, {});
  }
}
