<app-navbar></app-navbar>
<div class="container billing">
  <mat-tab-group
    backgroundColor="accent"
    mat-stretch-tabs
    dynamicHeight
    #tabs
    class="rounded-top"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">payment</mat-icon>
        {{ 'app.panel.billing.component.payment' | translate }}
      </ng-template>
      <mat-card class="tab-content">
        <ng-container *ngIf="isLoaded">
          <ng-container *ngIf="billing_system === BILLING_SYSTEM_NONE">
            <div class="row no-gutters">
              <div class="form-label col-10 offset-1">{{ 'app.panel.billing.component.desiredBillingSystem' | translate }}</div>
            </div>
            <div class="row no-gutters">
              <mat-radio-group
                class="col-10 offset-1"
                [(ngModel)]="desiredBillingSystem"
              >
                <mat-radio-button value="monthlySubscription">{{
                  'app.panel.billing.component.monthlySubscription' | translate
                }}</mat-radio-button>
                <mat-radio-button
                  class="offset-1"
                  value="customOrder"
                  >{{ 'app.panel.billing.component.customOrder' | translate }}</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div
              *ngIf="desiredBillingSystem === 'monthlySubscription'"
              class="row"
            >
              <div class="col-10 offset-1 payment-system-text-info">
                {{ 'app.panel.billing.component.monthlySubscriptionMessageInfo' | translate }}
              </div>
            </div>
            <div
              *ngIf="desiredBillingSystem === 'customOrder'"
              class="row"
            >
              <div class="col-10 offset-1 payment-system-text-info">
                {{ 'app.panel.billing.component.customOrderMessageInfo' | translate }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="shouldDisplayMonthlySubscriptionContent()">
            <form [formGroup]="formGroupMonthlySubscription">
              <div class="row no-gutters">
                <div
                  *ngIf="billing_system === BILLING_SYSTEM_STRIPE && isRegistered"
                  class="form-label col-10 offset-1"
                >
                  {{ 'app.panel.billing.component.billingDetails' | translate }}
                </div>
              </div>
              <div class="row">
                <mat-form-field
                  class="col-8 offset-2 col-lg-6 offset-lg-3 pl-0 pr-0"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.panel.billing.component.address.line1' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="line1"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ getError(formGroupMonthlySubscription, 'line1') }}</mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field
                  class="col-8 offset-2 col-lg-6 offset-lg-3 pl-0 pr-0"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.panel.billing.component.address.line2' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="line2"
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ getError(formGroupMonthlySubscription, 'line2') }}</mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field
                  class="col-8 offset-2 col-sm-4 col-lg-3 offset-lg-3 pl-0 pr-10 plr-0"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.panel.billing.component.address.postalCode' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="postalCode"
                    maxlength="5"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ getError(formGroupMonthlySubscription, 'postalCode') }}</mat-error>
                </mat-form-field>
                <mat-form-field
                  class="col-8 offset-2 col-sm-4 offset-sm-0 col-lg-3 pl-10 pr-0 plr-0"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.panel.billing.component.address.city' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="city"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ getError(formGroupMonthlySubscription, 'city') }}</mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field
                  class="col-8 offset-2 col-sm-4 col-lg-3 offset-lg-3 pl-0 pr-10 plr-0"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.panel.billing.component.address.state' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="state"
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ getError(formGroupMonthlySubscription, 'state') }}</mat-error>
                </mat-form-field>
                <mat-form-field
                  class="col-8 offset-2 col-sm-4 offset-sm-0 col-lg-3 pl-10 pr-0 plr-0"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.panel.billing.component.address.country' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="country"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ getError(formGroupMonthlySubscription, 'country') }}</mat-error>
                </mat-form-field>
              </div>
              <div
                class="row justify-content-center"
                *ngIf="billing_system === BILLING_SYSTEM_STRIPE && isRegistered"
              >
                <div class="col-8 col-lg-6 d-flex justify-content-center">
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="updateBillingDetails()"
                    [disabled]="!formGroupMonthlySubscription.valid"
                  >
                    {{ 'app.panel.billing.component.updateBillingDetails' | translate }}
                  </button>
                </div>
              </div>
            </form>
            <ng-container *ngIf="billing_system === BILLING_SYSTEM_STRIPE && isRegistered">
              <div class="row no-gutters">
                <div class="form-label col-10 offset-1">{{ 'app.panel.billing.component.paymentMethods' | translate }}</div>
              </div>
              <ng-container *ngIf="selectedPaymentMethod">
                <div class="row">
                  <div class="col-10 offset-1">
                    {{ 'app.panel.billing.component.choosePaymentMethod' | translate }}
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-10">
                    <button
                      *ngIf="defaultPaymentMethod !== selectedPaymentMethod"
                      mat-raised-button
                      color="accent"
                      (click)="openDefaultPaymentMethodDialog()"
                    >
                      {{ 'app.panel.billing.component.setAsDefaultPaymentMethod' | translate }}
                    </button>
                    <span
                      class="font-italic"
                      *ngIf="defaultPaymentMethod === selectedPaymentMethod"
                      >{{ 'app.panel.billing.component.selectedPaymentMethodIsAlreadyDefault' | translate }}</span
                    >
                    <button
                      *ngIf="defaultPaymentMethod !== selectedPaymentMethod"
                      mat-raised-button
                      color="accent"
                      (click)="openRemovePaymentMethodDialog()"
                    >
                      {{ 'app.panel.billing.component.removePaymentMethod' | translate }}
                    </button>
                  </div>
                </div>
              </ng-container>
              <div class="row">
                <div class="col-10 offset-1">
                  <div class="row no-gutters justify-content-start custom-gap">
                    <button
                      *ngFor="let paymentMethod of billingDetails.payment_methods"
                      mat-button
                      class="card-design mat-badge-icon mat-badge-on-border-radius"
                      [ngClass]="{ active: paymentMethod.default, selected: selectedPaymentMethod === paymentMethod.id }"
                      [matBadge]="paymentMethod.default ? 'check' : ''"
                      matBadgeColor="secondary"
                      (click)="selectPaymentMethod(paymentMethod.id)"
                    >
                      <div class="d-flex justify-content-between align-items-center brand">
                        <ng-container *ngIf="paymentMethod.type === 'card'">
                          <p>{{ paymentMethod.brand }} {{ paymentMethod.exp_date }}</p>
                          <i class="fa fa-cc-{{ paymentMethod.brand }}"></i>
                        </ng-container>
                        <ng-container *ngIf="paymentMethod.type === 'debit'">
                          <p>
                            {{ 'app.panel.billing.component.bankAccount' | translate }}
                            <span *ngIf="paymentMethod.active">
                              {{ 'app.panel.billing.component.activePaymentMethod' | translate }}
                            </span>
                          </p>
                          <i class="fa fa-bank fa-2x"></i>
                        </ng-container>
                      </div>
                      <p class="digits">**** **** **** {{ paymentMethod.last_digits }}</p>
                    </button>
                    <button
                      mat-button
                      class="card-design"
                      (click)="openAddPaymentMethodDialog()"
                    >
                      <div class="addPaymentMethod">
                        <i class="fa fa-plus fa-2x"></i>
                        <p class="text-center">{{ 'app.panel.billing.component.addPaymentMethod' | translate }}</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="billing_system === BILLING_SYSTEM_NONE || !isRegistered">
              <div class="row no-gutters">
                <div class="col-10 offset-1">
                  {{ 'app.panel.billing.component.validPaymentMethods' | translate }}
                </div>
              </div>
              <div class="row justify-content-center secure-payment-info">
                <i class="fa fa-cc-visa"></i>
                <i class="fa fa-cc-mastercard"></i>
              </div>
              <div class="row">
                <mat-form-field
                  class="col-8 offset-2 col-lg-6 offset-lg-3 pl-0 pr-0"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.panel.billing.component.desiredPaymentMethod' | translate }}</mat-label>
                  <mat-select
                    required
                    [(ngModel)]="desiredPaymentMethod"
                  >
                    <mat-option
                      *ngFor="let desiredPaymentMethod of desiredPaymentMethods"
                      [value]="desiredPaymentMethod.code"
                    >
                      {{ desiredPaymentMethod.label | translate }}
                    </mat-option>
                  </mat-select>
                  <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div
                *ngIf="desiredPaymentMethod === 'card'"
                class="row justify-content-center"
              >
                <div class="col-8 col-lg-6 stripe-card-container">
                  <ngx-stripe-card
                    [options]="cardOptions"
                    [elementsOptions]="elementsOptions"
                  ></ngx-stripe-card>
                </div>
              </div>
              <div
                *ngIf="desiredPaymentMethod === 'debit'"
                class="row justify-content-center"
              >
                test
              </div>
              <div class="row justify-content-center">
                <div class="col-8 col-lg-6 d-flex justify-content-center">
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="billingRegistration()"
                    [disabled]="!formGroupMonthlySubscription.valid"
                  >
                    {{ 'app.panel.billing.component.monthlySubscriptionRegistration' | translate }}
                  </button>
                </div>
              </div>
              <div class="row justify-content-center align-items-center secure-payment-info">
                <mat-icon>lock</mat-icon>{{ 'app.panel.billing.component.securedPayment' | translate }}
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!shouldDisplayMonthlySubscriptionContent()">
            <ng-container *ngIf="billing_system === BILLING_SYSTEM_NONE">
              <div class="row">
                <div class="col-10 offset-1 info2">
                  {{ 'app.panel.billing.component.customOrderMessageInfo2' | translate }}
                </div>
              </div>
              <div class="row justify-content-center">
                <button
                  mat-raised-button
                  color="accent"
                  (click)="billingRegistration()"
                  [disabled]="!formGroupCustomOrder.valid"
                >
                  {{ 'app.panel.billing.component.customOrderRegistration' | translate }}
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="billing_system === BILLING_SYSTEM_CUSTOM_ORDER_CANDIDATE">
              <div class="row no-gutters">
                <div class="form-label col-10 offset-1">{{ 'app.panel.billing.component.customOrder' | translate }}</div>
                <div class="col-10 offset-1 logo d-flex justify-content-center align-items-center">
                  <mat-icon class="check-icon text-center">check_circle_outline</mat-icon>
                </div>
                <div class="col-10 offset-1">{{ 'app.panel.billing.component.thanksForContactingUs' | translate }}</div>
              </div>
            </ng-container>
            <ng-container *ngIf="billing_system === BILLING_SYSTEM_CUSTOM_ORDER">
              <div class="row no-gutters">
                <div class="form-label col-10 offset-1">{{ 'app.panel.billing.component.customOrder' | translate }}</div>
                <div class="col-10 offset-1">
                  {{ 'app.panel.billing.component.customOrderSummary' | translate
                  }}<a [routerLink]="'/panel/organization/list'">{{ 'app.global.navbar.organizations' | translate }}</a
                  >.
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="billing_system === BILLING_SYSTEM_EXPERIMENTAL_CANDIDATE">
              <div class="row no-gutters">
                <div class="form-label col-10 offset-1">{{ 'app.panel.billing.component.experimentalCandidate' | translate }}</div>
                <div class="col-10 offset-1">{{ 'app.panel.billing.component.experimentalCandidateDescription' | translate }}</div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-card>
    </mat-tab>
    <mat-tab *ngIf="invoices.length > 0">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">list_alt</mat-icon>
        {{ 'app.panel.billing.component.invoices' | translate }}
      </ng-template>
      <mat-card class="tab-content invoices-card">
        <div class="row no-gutters">
          <div class="form-label col-12 col-lg-10 offset-lg-1 d-flex justify-content-between align-items-center">
            <span>{{ 'app.panel.billing.component.myInvoices' | translate }}</span
            ><span class="indication-label">* {{ 'app.panel.billing.component.limitTo100' | translate }}</span>
          </div>
        </div>
        <mat-list class="col-12 col-lg-10 offset-lg-1">
          <mat-list-item *ngFor="let invoice of invoices">
            <mat-icon
              mat-list-icon
              color="accent"
              class="receipt"
              >receipt</mat-icon
            >
            <p
              mat-line
              class="d-flex"
            >
              <span class="font-weight-bold text-capitalize"
                >{{ invoice.organization }}, {{ 'app.panel.billing.component.from' | translate }}
                {{ date.getLocaleString(invoice.period_start) }} {{ 'app.panel.billing.component.to' | translate }}
                {{ date.getLocaleString(invoice.period_end) }}</span
              >
            </p>
            <p
              mat-line
              class="d-flex"
            >
              <span class="amount"
                ><span class="font-weight-bold">{{ invoice.total }}</span> €
                {{ 'app.panel.billing.component.paidByCard' | translate }}</span
              >
            </p>
            <a
              mat-mini-fab
              [title]="'app.panel.billing.component.downloadReceipt' | translate"
              [href]="invoice.pdf"
              target="_blank"
            >
              <mat-icon>get_app</mat-icon>
            </a>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
        <mat-paginator
          *ngIf="invoices && invoicesViewSet.count > pageSize"
          [length]="invoicesViewSet.count"
          [pageSize]="pageSize"
          (page)="onPageChange($event)"
        >
        </mat-paginator>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</div>
