<div class="row no-gutters align-items-center justify-content-end filters">
  <mat-form-field>
    <mat-label>{{ 'app.panel.admin.component.search' | translate }}</mat-label>
    <input
      matInput
      [(ngModel)]="searchInput"
      (keyup)="applySearch($event)"
      #input
    />
  </mat-form-field>
  <button
    mat-button
    [ngClass]="{ 'font-weight-bold': customRequestService.countEnabledFilters() > 0 }"
    [matMenuTriggerFor]="filtersMenu"
    (click)="$event.stopPropagation()"
  >
    <mat-icon>filter_list</mat-icon>
    <span>
      {{ 'app.panel.admin.component.filter' | translate }}
      <ng-container *ngIf="customRequestService.countEnabledFilters() > 0">({{ customRequestService.countEnabledFilters() }})</ng-container>
    </span>
  </button>
  <mat-menu
    class="mat-menu-nowrap"
    #filtersMenu="matMenu"
  >
    <button
      [ngClass]="{ 'mat-button-end-icon': filter.enabled }"
      mat-menu-item
      *ngFor="let filter of customRequestService.filters"
      (click)="changeFilter(filter)"
    >
      <span>{{ 'app.panel.admin.customRequestList.component.filters.' + filter.key | translate }}</span>
      <mat-icon *ngIf="filter.enabled">check</mat-icon>
    </button>
  </mat-menu>
</div>

<div class="mat-elevation-z8">
  <table
    mat-table
    [dataSource]="customRequestsDataSource"
    matSort
    multiTemplateDataRows
  >
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.customRequestList.component.name' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customRequest"
        class="font-weight-bold"
      >
        {{ customRequest.firstname }} {{ customRequest.lastname }}
      </td>
    </ng-container>
    <ng-container matColumnDef="created_at">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.customRequestList.component.createdAt' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customRequest"
      >
        {{ date.getLocaleString(customRequest.created_at) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.customRequestList.component.email' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customRequest"
      >
        <a href="mailto:{{ customRequest.email }}">{{ customRequest.email }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.customRequestList.component.phone' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customRequest"
      >
        {{ customRequest.phone }}
      </td>
    </ng-container>
    <ng-container matColumnDef="job">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.customRequestList.component.job' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customRequest"
      >
        {{ customRequest.job }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.customRequestList.component.status' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let customRequest"
      >
        {{ customRequest.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th
        mat-header-cell
        *matHeaderCellDef
        aria-label="customRequest actions"
      >
        &nbsp;
      </th>
      <td
        mat-cell
        *matCellDef="let customRequest"
        class="icon-column"
      >
        <button
          mat-icon-button
          aria-label="expand customRequest"
          (click)="onExpand($event, customRequest)"
        >
          <mat-icon *ngIf="expandedCustomRequest !== customRequest">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedCustomRequest === customRequest">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th
        mat-header-cell
        *matHeaderCellDef
        aria-label="customRequest actions"
      >
        &nbsp;
      </th>
      <td
        mat-cell
        *matCellDef="let customRequest"
      >
        <button
          mat-icon-button
          aria-label="options customRequest"
          [matMenuTriggerFor]="customRequestOptionsMenu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #customRequestOptionsMenu="matMenu">
          <button
            mat-menu-item
            (click)="openUpdateDialog(customRequest)"
          >
            <span>{{ 'app.panel.admin.customRequestList.component.actions.save' | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="acceptRequest(customRequest)"
            *ngIf="customRequest.status === status.Received"
          >
            <span>{{ 'app.panel.admin.customRequestList.component.actions.accept' | translate }}</span>
          </button>
          <!-- <button
            mat-menu-item
            (click)="standbyRequests([customRequest])"
            *ngIf="customRequest.status === status.Received"
          >
            <span>{{ 'app.panel.admin.customRequestList.component.actions.standby' | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="rejectRequests([customRequest])"
            *ngIf="customRequest.status === status.Received"
          >
            <span>{{ 'app.panel.admin.customRequestList.component.actions.reject' | translate }}</span>
          </button> -->
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let customRequest"
        [attr.colspan]="customRequestsColumnsToDisplayWithExpand.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="customRequest === expandedCustomRequest ? 'expanded' : 'collapsed'"
        >
          <div class="row no-gutters">
            <div class="form-label italic col-12">{{ 'app.panel.admin.customRequestList.component.orderDetails' | translate }}</div>
          </div>
          <div
            class="row justify-content-left stats"
            *ngIf="customRequest.company"
          >
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>apartment</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.admin.customRequestList.component.company' | translate }}</span>
                {{ customRequest.company }}
              </div>
            </div>
          </div>
          <div class="row justify-content-left stats">
            <div class="col-12">
              <div class="d-flex">
                <mat-icon>description</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.admin.customRequestList.component.describedNeed' | translate }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex pre-container">
                <pre>{{ customRequest.described_need }}</pre>
              </div>
            </div>
          </div>
          <div class="row justify-content-left stats">
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>person</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.admin.customRequestList.component.standardUsers' | translate }}</span>
                {{ customRequest.standard_users }}
              </div>
            </div>
          </div>
          <div class="row justify-content-left stats">
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>storage</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.admin.customRequestList.component.storage' | translate }}</span>
                {{ customRequest.storage }}
              </div>
            </div>
          </div>
          <div class="row justify-content-left stats">
            <div class="col-12">
              <div class="d-flex">
                <mat-icon>comment</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.admin.customRequestList.component.comment' | translate }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex pre-container">
                <pre>{{ customRequest.comment || 'N/A' }}</pre>
              </div>
            </div>
          </div>
          <div class="row justify-content-left stats">
            <div class="col-12">
              <div class="d-flex">
                <mat-icon>business_center</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.admin.customRequestList.component.organization' | translate }}</span>
                {{ customRequest.organization_name || 'N/A' }}
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="customRequestsColumnsToDisplayWithExpand"
    ></tr>
    <tr
      mat-row
      *matRowDef="let customRequest; columns: customRequestsColumnsToDisplayWithExpand"
      class="example-element-row"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
    <tr
      class="mat-row"
      *matNoDataRow
    >
      <td
        class="mat-cell"
        [attr.colspan]="customRequestsColumnsToDisplayWithExpand.length"
      >
        <ng-container *ngIf="input.value">
          {{ 'app.panel.admin.component.noDataMatchingFilter' | translate }} "{{ input.value }}"
        </ng-container>
        <ng-container *ngIf="!input.value && !isLoadingResults">
          {{ 'app.panel.admin.component.noResults' | translate }}
        </ng-container>
        <mat-spinner
          diameter="40"
          *ngIf="isLoadingResults"
        ></mat-spinner>
      </td>
    </tr>
  </table>
  <mat-paginator
    [length]="resultsLength"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page of customRequests"
  ></mat-paginator>
</div>
