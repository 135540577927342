<h1 mat-dialog-title>{{'app.panel.billing.removePaymentMethodDialog.title' | translate}}</h1>
<div mat-dialog-content>
  <span
    *ngIf="data.paymentMethod.type === 'card'"
    class="text-capitalize"
    >{{data.paymentMethod.brand}} {{data.paymentMethod.exp_date}}</span
  >
  <span *ngIf="data.paymentMethod.type === 'debit'">Compte bancaire</span>
  {{'app.panel.billing.removePaymentMethodDialog.consequences' | translate:{lastDigits: data.paymentMethod.last_digits} }}
</div>
<div
  mat-dialog-actions
  class="row justify-content-end"
>
  <button
    mat-raised-button
    mat-dialog-close
  >
    {{'app.panel.billing.removePaymentMethodDialog.cancel' | translate}}
  </button>
  <button
    mat-raised-button
    color="accent"
    cdkFocusInitial
    (click)="submit()"
  >
    {{'app.panel.billing.removePaymentMethodDialog.confirm' | translate}}
  </button>
</div>
