<ng-container *ngIf="loaded">
  <h1 mat-dialog-title>{{ getTitle() }}</h1>
  <div mat-dialog-content>
    <div class="row justify-content-center">
      <p
        class="col-12 font-italic"
        appearance="outline"
      >
        {{ 'app.panel.admin.organizationList.freezeDialog.organizationName' | translate: { name: organizationName } }}
      </p>
    </div>
  </div>
  <div
    mat-dialog-actions
    class="d-flex justify-content-end"
  >
    <button
      mat-raised-button
      (click)="cancel()"
    >
      {{ 'app.panel.admin.organizationList.freezeDialog.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="accent"
      (click)="submit()"
    >
      {{ 'app.panel.admin.organizationList.freezeDialog.submit' | translate }}
    </button>
  </div>
</ng-container>
<mat-spinner
  diameter="40"
  *ngIf="!loaded"
></mat-spinner>
