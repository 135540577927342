<div class="container">
  <div class="row align-items-center justify-content-center not-found">
    <mat-card class="col-12 col-sm-10 col-md-8 col-lg-6 text-center">
      <div class="row justify-content-center no-gutters title">
        <div class="col-6">
          <img
            class="img-fluid"
            src="assets/images/parsec-vert.png"
            [alt]="'app.global.parsecLogo' | translate"
          />
          <hr />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 logo d-flex justify-content-center align-items-center">4<mat-icon>sentiment_very_dissatisfied</mat-icon>4</div>
      </div>
      <div class="row justify-content-center">
        <div class="col-9">
          {{ 'error.doesNotExist' | translate }}
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-9">
          <button
            mat-raised-button
            color="accent"
            routerLink="/"
          >
            {{ 'app.global.backToHome' | translate }}
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
