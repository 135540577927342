import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateService } from 'src/app/shared/date.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { notifications } from 'src/assets/referentials/notifications';

type Notification = {
  date: string;
  isUnread: boolean;
  link: string;
  message: {
    code: string;
    interpolateParams: any;
  };
  type: 'info' | 'warning';
};

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  icons = {
    warning: 'warning',
    info: 'info',
  };
  notifs: Notification[] = [];
  onUpdateSubscription: Subscription;

  constructor(
    public notification: NotificationService,
    public translate: TranslateService,
    public date: DateService,
  ) {}

  ngOnInit(): void {
    this.getNotifications();
    this.onUpdateSubscription = this.notification.onUpdate.subscribe(() => {
      this.getNotifications();
    });
  }

  getNotifications(): void {
    this.notifs = [];
    this.notification.update({
      reads: { enable: false },
      callback: () => {
        this.notification.getUnreads().forEach((element) => {
          const notif = { ...notifications[element.code] };
          const interpolateParams = element.data;
          notif.pk = element.pk;
          notif.isUnread = !element.is_read;
          notif.date = element.created_at;
          if (element.data.organization) {
            const organization = element.data.organization;
            notif.link = notif.link.replace(':organization_id', organization.pk);
            interpolateParams.organization.suffix = organization.suffix.toUpperCase();
          }
          notif.message = {
            code: `referentials.notifications.${element.code}`,
            interpolateParams: interpolateParams,
          };
          this.notifs.push(notif);
        });
      },
    });
  }

  toggleVisibility(): void {
    this.notification.toggleVisibility();
  }

  ngOnDestroy(): void {
    this.onUpdateSubscription.unsubscribe();
  }
}
