import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/api.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';

@Component({
  selector: 'billing-default-payment-method-dialog',
  templateUrl: 'billing-default-payment-method-dialog.html',
})
export class BillingDefaultPaymentDialog {
  constructor(
    public dialogRef: MatDialogRef<BillingDefaultPaymentDialog>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: SnackbarService,
    private api: ApiService,
  ) {}

  submit(): void {
    if (this.data.paymentMethod) {
      const url = `/users/${this.data.user.pk}/clients/${this.data.user.client.pk}/default_payment_method`;
      this.api
        .patch(url, {
          payment_method: this.data.paymentMethod.id,
        })
        .subscribe(() => {
          this.dialogRef.close('newDefaultPaymentMethodSet');
        });
    }
  }
}
