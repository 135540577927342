import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { SnackbarService } from '../shared/snackbar.service';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form.submitted);
  }
}

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  feedbackSent = false;
  formGroup = new UntypedFormGroup({});
  errorStateMatcher = new CustomErrorStateMatcher();
  fieldErrors = {};
  constructor(
    private api: ApiService,
    private auth: AuthService,
    private snackbar: SnackbarService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      {
        platform: ['Logiciel Parsec', Validators.required],
        type: ['Problème', Validators.required],
        name: [''],
        email: [''],
        title: ['', Validators.required],
        description: ['', Validators.required],
      },
      {
        updateOn: 'change',
      },
    );
    if (this.auth.isAuthenticated()) {
      const user = this.auth.whoIs();
      this.api.get('/users/' + user.user_id).subscribe(
        (response) => {
          this.formGroup.get('name').setValue(`${response.client.firstname} ${response.client.lastname}`);
          this.formGroup.get('email').setValue(response.email);
        },
        (_response: HttpErrorResponse) => {
          this.auth.repudiate();
        },
      );
    }
  }

  sendFeedback(): void {
    if (this.formGroup.valid) {
      this.api
        .post('/api/feedback', {
          type: this.formGroup.get('type').value,
          platform: this.formGroup.get('platform').value,
          name: this.formGroup.get('name').value,
          email: this.formGroup.get('email').value,
          title: this.formGroup.get('title').value,
          description: this.formGroup.get('description').value,
        })
        .subscribe(() => {
          this.feedbackSent = true;
          this.snackbar.success(this.translate.instant('app.feedback.component.feedbackSent'));
        });
    } else {
      this.snackbar.error(this.translate.instant('error.fillAllMandatoryFields'));
    }
  }
}
