import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { OrganizationService } from '../organization.service';

@Component({
  selector: 'app-organization-list-freeze-dialog',
  templateUrl: 'organization-list-freeze-dialog.component.html',
})
export class OrganizationListFreezeDialogComponent implements OnInit {
  isFrozen: boolean;
  loaded = false;
  organizationName: string;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private organizationService: OrganizationService,
    public dialogRef: MatDialogRef<OrganizationListFreezeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    for (const organization of this.data.organizations) {
      this.organizationName = organization.parsec_id;
      this.organizationService.retrieveInfo(organization).then((response) => {
        if (response.status) {
          this.isFrozen = response.status['is_frozen'];
          this.loaded = true;
        }
      });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getTitle(): string {
    const translateKey =
      this.isFrozen === true
        ? 'app.panel.admin.organizationList.freezeDialog.unfreezeOrganization'
        : 'app.panel.admin.organizationList.freezeDialog.freezeOrganization';
    return this.translate.instant(translateKey);
  }

  async submit(): Promise<void> {
    if (this.isFrozen === true) {
      this.organizationService.unfreeze(this.data.organizations).then(
        (response) => {
          if (response.updated !== 0) {
            this.dialogRef.close({ count: response.updated, isFrozen: false });
          }
        },
        (error) => {
          if (error.status === 400) {
            this.snackbar.error(this.translate.instant('error.wrongData'));
          }
        },
      );
    } else {
      this.organizationService.freeze(this.data.organizations).then(
        (response) => {
          if (response.updated !== 0) {
            this.dialogRef.close({ count: response.updated, isFrozen: true });
          }
        },
        (error) => {
          if (error.status === 400) {
            this.snackbar.error(this.translate.instant('error.wrongData'));
          }
        },
      );
    }
  }
}
