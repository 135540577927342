import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/api.service';
import { isEmptyOrNullObject } from 'src/app/shared/tools';
import {
  BillingSystem,
  CustomFieldCodes,
  CustomOrderStatus,
  Filter,
  SellsyBillingUnitReference,
  sellsyReferenceToOrganizationInfoType,
} from '../admin.global';
import {
  AlertAboutEndOfContractResponse,
  CustomOrderDetailsResponse,
  getOrganizationStatsFromResponse,
  InvoiceElement,
  Organization,
  OrganizationInfo,
  OrganizationInfoResponse,
  OrganizationListEntry,
  OrganizationListResponse,
  SetExpirationDateResponse,
} from './organization-list.global';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  baseUrl = '/api/adm/organizations';
  filters: Filter[] = [
    {
      key: 'billingSystemIsNone',
      enabled: false,
      fields: [
        {
          name: 'client__billing_system',
          value: BillingSystem.None,
        },
      ],
    },
    {
      key: 'billingSystemIsCustomOrderCandidate',
      enabled: false,
      fields: [
        {
          name: 'client__billing_system',
          value: BillingSystem.CustomOrderCandidate,
        },
      ],
    },
    {
      key: 'billingSystemIsCustomOrder',
      enabled: false,
      fields: [
        {
          name: 'client__billing_system',
          value: BillingSystem.CustomOrder,
        },
      ],
    },
    {
      key: 'billingSystemIsExperimentalCandidate',
      enabled: false,
      fields: [
        {
          name: 'client__billing_system',
          value: BillingSystem.ExperimentalCandidate,
        },
      ],
    },
    {
      key: 'billingSystemIsStripe',
      enabled: false,
      fields: [
        {
          name: 'client__billing_system',
          value: BillingSystem.Stripe,
        },
      ],
    },
  ];

  constructor(
    private api: ApiService,
    private translate: TranslateService,
  ) {}

  getFilters(enabledOnly = false): Filter[] {
    return enabledOnly ? this.filters.filter((filter) => filter.enabled === true) : this.filters;
  }

  countEnabledFilters(): number {
    return this.filters.filter((filter) => filter.enabled === true).length;
  }

  list(client_ids: string[] | null = null): Promise<{
    organizations: Organization[];
    custom_order_status: { [parsecId: string]: CustomOrderStatus };
  }> {
    const body = {
      client_ids,
      filters: this.filters.filter((filter) => filter.enabled === true).flatMap((filter) => filter.fields),
    };
    let organizations: Organization[] = [];

    return new Promise((resolve, reject) => {
      this.api.post(`${this.baseUrl}/list`, body).subscribe({
        next: (response: OrganizationListResponse) => {
          organizations = response.organizations.map((entry: OrganizationListEntry) => {
            let expiration_time = null;
            if (entry.expiration_date) {
              const timeDiff = Math.abs(new Date(entry.created_at).getTime() - new Date(entry.expiration_date).getTime());
              expiration_time = Math.ceil(timeDiff / (1000 * 3600 * 24));
            }
            let organization: Organization = {
              ...entry,
              url: `/users/${entry.client__user}/clients/${entry.client}/organizations/${entry.id}`,
              expiration_time,
              client__name: entry.client__company ? entry.client__company : entry.client__firstname + ' ' + entry.client__lastname,
            };

            return organization;
          });
          resolve({ organizations, custom_order_status: response.custom_order_status });
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  async setExpirationDate(organizations: Organization[], expiration_date: Date | null): Promise<SetExpirationDateResponse> {
    return this.api
      .put(`${this.baseUrl}/expiration-date`, {
        organization_ids: organizations ? organizations.map((value) => value.id) : null,
        expiration_date: expiration_date ? expiration_date.toISOString() : null,
      })
      .toPromise();
  }

  async freeze(organizations: Organization[]): Promise<SetExpirationDateResponse> {
    return this.setExpirationDate(organizations, new Date());
  }

  async unfreeze(organizations: Organization[]): Promise<SetExpirationDateResponse> {
    return this.setExpirationDate(organizations, null);
  }

  areFrozen(organizations: Organization[]): boolean {
    return organizations.every((organization) => {
      return organization.expiration_date !== null && new Date(organization.expiration_date) < new Date();
    });
  }

  areNotFrozen(organizations: Organization[]): boolean {
    return organizations.every((organization) => {
      return organization.expiration_date === null || new Date(organization.expiration_date) >= new Date();
    });
  }

  async alertAboutEndOfContract(organizations: Organization[]): Promise<AlertAboutEndOfContractResponse> {
    return this.api
      .post(`${this.baseUrl}/send-alert-end-of-contract`, {
        organization_ids: organizations ? organizations.map((value) => value.id) : null,
        lang: this.translate.currentLang,
      })
      .toPromise();
  }

  async retrieveInfo(organization: Organization): Promise<OrganizationInfo> {
    return new Promise((resolve, reject) => {
      this.api.get(`${this.baseUrl}/${organization.id}/info`).subscribe(
        (organizationInfo: OrganizationInfoResponse) => {
          resolve({
            status: organizationInfo.status,
            stats: !isEmptyOrNullObject(organizationInfo.stats) ? getOrganizationStatsFromResponse(organizationInfo.stats) : null,
          });
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  async retrieveCustomOrderDetails(organization: Organization): Promise<CustomOrderDetailsResponse> {
    return new Promise<CustomOrderDetailsResponse>((resolve, reject) => {
      this.api.get(`${this.baseUrl}/${organization.id}/custom-order-details`).subscribe(
        (customOrderDetails) => {
          if (!isEmptyOrNullObject(customOrderDetails)) {
            const startDate = this.getCustomFieldValue(CustomFieldCodes.StartDate, customOrderDetails) as Date;
            const endDate = this.getCustomFieldValue(CustomFieldCodes.EndDate, customOrderDetails) as Date;
            resolve({
              details: {
                invoiceNumber: customOrderDetails['number'] ?? '',
                startDate: startDate ? startDate.toLocaleDateString() : '',
                endDate: endDate ? endDate.toLocaleDateString() : '',
                link: customOrderDetails['public_link']['url'] ?? '',
                amount: `${customOrderDetails['amounts']['total_incl_tax']} ${customOrderDetails['currency']}`,
                licenses: {
                  STANDARD: this.getCustomFieldValue(CustomFieldCodes.StandardLicenseCount, customOrderDetails) as number,
                  ADMIN: this.getCustomFieldValue(CustomFieldCodes.AdminLicenseCount, customOrderDetails) as number,
                  OUTSIDER: this.getCustomFieldValue(CustomFieldCodes.OutsiderLicenseCount, customOrderDetails) as number,
                  STORAGE: this.getCustomFieldValue(CustomFieldCodes.StorageLicenseCount, customOrderDetails) as number,
                },
                rows: this.getCustomOrderRows(customOrderDetails),
              },
              startDate,
              endDate,
            });
          } else {
            resolve({ details: null, startDate: null, endDate: null });
          }
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  getCustomFieldValue(customFieldCode: CustomFieldCodes, customOrderDetails: object): string | number | Date | null {
    if (!customOrderDetails || !Object.keys(customOrderDetails).includes('_embed')) {
      return null;
    }
    const customFields = customOrderDetails['_embed']['custom_fields'];
    const customField = customFields.find((cField) => cField.code === customFieldCode);
    switch (customFieldCode) {
      case CustomFieldCodes.StartDate:
      case CustomFieldCodes.EndDate:
        return customField['value'] ? new Date(customField['value']) : null;
      case CustomFieldCodes.OrganizationId:
        return customField['value'];
      case CustomFieldCodes.AdminLicenseCount:
      case CustomFieldCodes.OutsiderLicenseCount:
      case CustomFieldCodes.StandardLicenseCount:
      case CustomFieldCodes.StorageLicenseCount:
        return Number(customField['value']);
      default:
        return null;
    }
  }

  getCustomOrderRows(customOrderDetails: object): { string: InvoiceElement } | Record<string, never> {
    const customOrderRows = customOrderDetails['rows'] ?? [];
    const rows = {};
    for (const customOrderRow of customOrderRows) {
      if (Object.values(SellsyBillingUnitReference).includes(customOrderRow['reference'])) {
        const invoiceElementName = sellsyReferenceToOrganizationInfoType(customOrderRow['reference']);
        const row: InvoiceElement = {
          name: this.translate.instant(`app.panel.organization.component.invoiceElements.${invoiceElementName}`),
          quantity: customOrderRow['quantity'] ?? '0',
          unitAmount: customOrderRow['unit_amount'] ?? '0',
          taxAmount: customOrderRow['tax_amount'] ?? '0',
          totalAmount: customOrderRow['amount_tax_inc'] ?? '0',
        };
        rows[invoiceElementName] = row;
      }
    }
    return rows;
  }
}
