<ng-container *ngIf="isAuthenticated">
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <a
      class="navbar-brand"
      routerLink="/panel"
    >
      <img
        src="/assets/images/parsec.png"
        height="25"
        alt="My.Parsec.Cloud - {{ 'app.panel.navbar.component.memberArea' | translate }}"
      />
    </a>
    <button
      class="navbar-toggler collapsed"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      (click)="isShown = !isShown"
      aria-controls="navbarNav"
      [attr.aria-expanded]="isShown"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      [ngClass]="{ show: isShown }"
      id="navbarNav"
    >
      <ul class="navbar-nav ml-auto text-right">
        <ng-container *ngFor="let link of links">
          <li
            class="nav-item"
            *ngIf="isLinkVisibleOnCurrentUrl(link)"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              class="nav-link"
              [routerLink]="link.url"
              >{{ link.name | translate }}</a
            >
          </li>
        </ng-container>
        <li class="nav-item">
          <button
            mat-button
            class="notification-center-toggler"
            (click)="toggleNotificationCenter($event)"
            #notificationCenterToggler
          >
            <mat-icon
              *ngIf="notification.countUnreads() > 0"
              [matBadge]="notification.countUnreads()"
              matBadgeColor="warn"
              >notifications</mat-icon
            >
            <mat-icon *ngIf="notification.countUnreads() === 0">notifications</mat-icon>
          </button>
        </li>
        <li class="nav-item">
          <button
            mat-raised-button
            color="accent"
            (click)="logOut()"
          >
            {{ 'app.panel.navbar.component.logOut' | translate }}
          </button>
        </li>
      </ul>
    </div>
  </nav>
  <app-notification-center
    class="col-6 col-sm-4 col-lg-3 justify-content-right notification-center"
    *ngIf="notification.displayed"
    #notificationCenter
  >
  </app-notification-center>
</ng-container>
<nav
  class="navbar navbar-expand-lg navbar-light bg-white"
  *ngIf="!isAuthenticated"
>
  <a
    class="navbar-brand"
    routerLink="/"
  >
    <img
      src="/assets/images/parsec.png"
      height="25"
      [alt]="'My.Parsec.Cloud - ' + ('app.global.navbar.home' | translate)"
    />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse"
    id="navbarNav"
  >
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <button
          mat-raised-button
          color="accent"
          routerLink="/"
        >
          {{ 'app.global.navbar.alreadyClient' | translate }}
        </button>
      </li>
    </ul>
  </div>
</nav>
