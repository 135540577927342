import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';

interface BackendError {
  code: string;
  detail: string;
  attr: string | null;
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  translate: TranslateService;
  constructor(
    private router: Router,
    private snackbar: SnackbarService,
    private injector: Injector,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        this.translate = this.injector.get(TranslateService);
        console.log(response);
        switch (response.status) {
          case 0:
            this.snackbar.error(this.translate.instant('error.serviceDown'));
            break;
          case 404:
            // NOT FOUND
            this.router.navigate(['not-found']);
            break;
          case 503:
            if (!response.error.errors) {
              // We don't have specific error messages from the backend. handle it as a plain 503
              this.snackbar.error(this.translate.instant('error.parsecDown'));
              break;
            } // FALLTHROUGH
          default:
            if (response.error.errors) {
              this.mapErrors(response.error.errors || []);
            }
            break;
        }
        return throwError(response);
      }),
    );
  }

  mapErrors(errors: BackendError[]): void {
    for (const error of errors) {
      const referentialErrors = this.translate.instant(`referentials.errors`);
      if (referentialErrors[error.code]) {
        this.snackbar.error(referentialErrors[error.code]);
      } else {
        this.snackbar.error(this.translate.instant(`error.occured`));
      }
      console.error(error);
    }
  }
}
