export interface CustomRequest {
  id: string;
  created_at: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  job: string;
  company: string;
  described_need: string;
  organization_name: string;
  admin_users: number;
  standard_users: number;
  outsider_users: number;
  storage: number;
  formula: null;
  status: string;
  comment: string | null;
  address: {
    id: string;
    created_at: string;
    line1: string;
    line2: string;
    postal_code: string;
    city: string;
    state: string;
    country: string;
  };
}

export interface CustomRequestListResponse {
  count: number;
  next: any | null;
  previous: any | null;
  results: CustomRequest[];
}

export enum Status {
  Received = 'RECEIVED',
  Processing = 'PROCESSING',
  Standby = 'STANDBY',
  Finished = 'FINISHED',
  Cancelled = 'CANCELLED',
}
