import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { ApiService } from 'src/app/shared/api.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';

@Component({
  selector: 'billing-add-payment-method-dialog',
  templateUrl: 'billing-add-payment-method-dialog.html',
})
export class BillingAddPaymentDialog {
  desiredPaymentMethod: string;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#000000',
        color: '#000000',
        lineHeight: '50px',
        fontWeight: '300',
        fontFamily: 'Montserrat, "Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: 'rgba(0,0,0,0.38)',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'fr',
  };
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  constructor(
    public dialogRef: MatDialogRef<BillingAddPaymentDialog>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: SnackbarService,
    private api: ApiService,
    private stripeService: StripeService,
  ) {}

  addPaymentMethod(): void {
    if (this.desiredPaymentMethod == 'card') {
      this.stripeService.createPaymentMethod({ type: 'card', card: this.card.element }).subscribe((result) => {
        if (result.paymentMethod) {
          const url = `/users/${this.data.user.pk}/clients/${this.data.user.client.pk}/add_payment_method`;
          this.api
            .put(url, {
              payment_method: result.paymentMethod.id,
            })
            .subscribe(() => {
              this.dialogRef.close('newPaymentMethodAdded');
            });
        } else if (result.error) {
          this.snackbar.error(result.error.message);
          this.dialogRef.close();
        }
      });
    } else if (this.desiredPaymentMethod == 'debit') {
      console.log('debit added');
      this.dialogRef.close();
    } else {
      this.snackbar.error(this.translate.instant('app.panel.billing.addPaymentMethodDialog.pleaseSelectPaymentMethod'));
    }
  }
}
