import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss'],
})
export class ValidateComponent implements OnInit {
  validated = false;
  expired = false;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.validate();
  }

  validate(): void {
    this.route.paramMap.subscribe((params) => {
      const url = `/users/${params.get('userId')}/enabling/?tk=${params.get('token')}`;
      this.api.get(url).subscribe(
        () => {
          this.validated = true;
        },
        (response: HttpErrorResponse) => {
          if (response.status === 400 && response.error.code === 'TOKEN_EXPIRED') {
            this.expired = true;
          }
        },
      );
    });
  }
}
