<div class="router-outlet-container">
  <router-outlet></router-outlet>
</div>
<!-- <div class="feedback">
  <a
    mat-fab
    routerLink="/feedback"
    [title]="'app.component.somethingToReport' | translate"
  >
    <mat-icon>feedback</mat-icon>
  </a>
</div>
<div class="help">
  <a
    mat-fab
    routerLink="/help"
    [title]="'app.component.needHelp' | translate"
  >
    <mat-icon>help</mat-icon>
  </a>
</div> -->
<div class="mobile-footer">
  <a
    mat-fab
    (click)="openMobileFooter()"
    [title]="'app.component.moreOptions' | translate"
  >
    <mat-icon>more_horiz</mat-icon>
  </a>
</div>
<div class="footer mt-auto">
  <a
    [href]="getUrl('termsAndConditions')"
    target="_blank"
    [title]="'app.component.termsAndConditions' | translate"
  >
    {{ 'app.component.termsAndConditions' | translate }}
  </a>
  <mat-icon class="lens">lens</mat-icon>
  <a
    [href]="getUrl('terms')"
    target="_blank"
    [title]="'app.component.legalTerms' | translate"
  >
    {{ 'app.component.legalTerms' | translate }}
  </a>
  <mat-icon class="lens">lens</mat-icon>
  <a
    [href]="getUrl('privacy')"
    target="_blank"
    [title]="'app.component.privacyPolicy' | translate"
  >
    {{ 'app.component.privacyPolicy' | translate }}
  </a>
  <mat-icon class="lens">lens</mat-icon>
  <a
    href="https://parsec.cloud"
    target="_blank"
    title="Parsec.cloud"
  >
    Parsec.cloud
  </a>
  <mat-icon class="lens">lens</mat-icon>
  <mat-icon class="translate-icon">translate</mat-icon>
  <mat-form-field
    appearance="standard"
    class="fit-to-content no-underline align-items-center"
  >
    <mat-select
      [(value)]="selectedLang"
      (selectionChange)="selectLang($event)"
    >
      <mat-option value="fr">Français</mat-option>
      <mat-option value="en">English</mat-option>
    </mat-select>
  </mat-form-field>
</div>
