import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { SnackbarService } from './snackbar.service';
import { Cookie, JWT } from './tools';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private auth: AuthService,
    private api: ApiService,
    private router: Router,
    private snackbar: SnackbarService,
    private translate: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this._canActivate(route, state);
  }

  async _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let accessToken = Cookie.get('access_token');

    if (accessToken) {
      const staffOnly = route.data.staffOnly;
      const isAccessTokenExpired = this.auth.isTokenExpired(accessToken);

      if (isAccessTokenExpired) {
        const _newToken = await this._tryRefreshToken();
        if (_newToken === null) {
          this.gotoAuthScreen('error.sessionExpired', { queryParams: { target: state.url } });
          return false;
        }
        accessToken = _newToken!;
      }

      const tokenPayload = JWT.decode(accessToken);
      const userIsStaff = tokenPayload.is_staff;

      if (staffOnly && !userIsStaff) {
        this.gotoAuthScreen('error.accessDenied', { queryParams: { target: state.url } });
        return false;
      }
      return true;
    }
    console.warn('"access_token" not found');
    this.gotoAuthScreen();
    return false;
  }

  async _tryRefreshToken(): Promise<string | null> {
    const refreshToken = Cookie.get('refresh_token');

    if (refreshToken && !this.auth.isTokenExpired(refreshToken)) {
      const response = await this.api.refresh(refreshToken).toPromise();
      if (response) {
        return response.access;
      }
    }
    return null;
  }

  gotoAuthScreen(error?: string, extra?: NavigationExtras): void {
    this.router.navigate([''], extra);
    if (error) this.snackbar.error(this.translate.instant(error));
  }
}
