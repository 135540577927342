import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification.service';
import { AuthService, UserInfo } from '../shared/auth.service';
import { FeatureService } from '../shared/feature.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  user: UserInfo | undefined;
  constructor(
    public notification: NotificationService,
    public feature: FeatureService,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.user = this.auth.whoIs();
  }
}
