import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  message(message: string, action: { name?: string; execute?: () => void } = {}, config: MatSnackBarConfig = {}): void {
    config.duration = config.duration || 10000;
    this.snackBar
      .open(message, action.name || 'OK', config)
      .onAction()
      .subscribe(() => {
        (action.execute || Function)();
      });
  }

  info(message: string, action?: { name?: string; execute?: () => void }): void {
    this.message(message, action, { panelClass: 'msg-info' });
  }

  success(message: string, action?: { name?: string; execute?: () => void }): void {
    this.message(message, action, { panelClass: 'msg-success' });
  }

  warning(message: string, action?: { name?: string; execute?: () => void }): void {
    this.message(message, action, { panelClass: 'msg-warning' });
  }

  error(message: string, action?: { name?: string; execute?: () => void }): void {
    this.message(message, action, { panelClass: 'msg-error' });
  }
}
