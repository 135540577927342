<app-navbar></app-navbar>
<div class="container">
  <mat-card class="row align-items-center justify-content-center">
    <div>
      <div class="row no-gutters">
        <div class="form-label col-8 offset-2">{{ 'app.panel.customerProfile.component.account' | translate }}</div>
      </div>
      <div class="row justify-content-center">
        <div class="col-8">
          <button
            mat-raised-button
            color="accent"
            (click)="changePassword()"
          >
            {{ 'app.panel.customerProfile.component.changePassword' | translate }}
          </button>
          <button
            mat-raised-button
            color="accent"
            (click)="openDialog()"
          >
            {{ 'app.panel.customerProfile.component.openDialog' | translate }}
          </button>
        </div>
      </div>
    </div>
    <form [formGroup]="formGroup">
      <div class="row no-gutters">
        <div class="form-label col-8 offset-2">{{ 'app.panel.customerProfile.component.customerData' | translate }}</div>
      </div>
      <div class="row">
        <mat-form-field
          class="col-8 offset-2"
          appearance="outline"
        >
          <mat-label>{{ 'app.panel.customerProfile.component.email' | translate }}</mat-label>
          <input
            matInput
            formControlName="email"
          />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field
          class="col-8 col-md-4 offset-2"
          appearance="outline"
        >
          <mat-label>{{ 'app.panel.customerProfile.component.firstname' | translate }}</mat-label>
          <input
            matInput
            formControlName="firstname"
            required
            [errorStateMatcher]="errorStateMatcher"
          />
          <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-8 offset-2 col-md-4 offset-md-0"
          appearance="outline"
        >
          <mat-label>{{ 'app.panel.customerProfile.component.lastname' | translate }}</mat-label>
          <input
            matInput
            formControlName="lastname"
            required
            [errorStateMatcher]="errorStateMatcher"
          />
          <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field
          class="col-8 col-md-4 offset-2"
          appearance="outline"
        >
          <mat-label>{{ 'app.panel.customerProfile.component.country' | translate }}</mat-label>
          <mat-select
            formControlName="country"
            required
            [errorStateMatcher]="errorStateMatcher"
            (ngModelChange)="checkCountry()"
          >
            <mat-option
              *ngFor="let country of countries"
              [value]="country.code"
            >
              {{ country.label }}
            </mat-option>
          </mat-select>
          <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-8 offset-2 col-md-4 offset-md-0"
          appearance="outline"
        >
          <mat-label>{{ 'app.panel.customerProfile.component.phone' | translate }}</mat-label>
          <input
            matInput
            formControlName="phone"
            required
            [errorStateMatcher]="errorStateMatcher"
          />
          <mat-error>{{ getError('phone') }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row individual-or-enterprise justify-content-center">
        <mat-slide-toggle
          color="accent"
          class="col-8"
          formControlName="representCompany"
          (change)="clearCompanyFields()"
          required
        >
          {{ 'app.panel.customerProfile.component.representCompany' | translate }}
        </mat-slide-toggle>
      </div>
      <div
        class="row"
        *ngIf="formGroup.get('representCompany').value"
      >
        <mat-form-field
          class="col-8 col-md-4 offset-2"
          appearance="outline"
        >
          <mat-label>{{ 'app.panel.customerProfile.component.company' | translate }}</mat-label>
          <input
            matInput
            formControlName="company"
            required
            [errorStateMatcher]="errorStateMatcher"
          />
        </mat-form-field>
        <mat-form-field
          class="col-8 offset-2 col-md-4 offset-md-0"
          appearance="outline"
        >
          <mat-label>{{ 'app.panel.customerProfile.component.job' | translate }}</mat-label>
          <input
            matInput
            formControlName="job"
            required
            [errorStateMatcher]="errorStateMatcher"
          />
          <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row justify-content-center">
        <div class="col-8 d-flex justify-content-end">
          <button
            mat-raised-button
            color="accent"
            (click)="update()"
          >
            {{ 'app.panel.customerProfile.component.update' | translate }}
          </button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
