import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from 'src/app/shared/date.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { AdminAPI, BillingSystem } from '../admin.global';
import { ClientListExperimentalCandidateDialogComponent } from './client-list-experimental-candidate-dialog/client-list-experimental-candidate-dialog.component';
import { Client, ClientData } from './client-list.global';
import { ClientService } from './client.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent {
  public readonly BillingSystem: typeof BillingSystem = BillingSystem;
  public readonly AdminAPI: typeof AdminAPI = AdminAPI;
  clients = [];
  clientsDisplayedColumns: string[] = ['select', 'name', 'created_at', 'email', 'phone', 'job', 'billing_system'];
  clientsDataSource: MatTableDataSource<ClientData> = new MatTableDataSource();
  clientsColumnsToDisplayWithExpand: string[] = [...this.clientsDisplayedColumns, 'options'];
  selection = new SelectionModel<ClientData>(true, []);
  _clients: Client[] = [];
  clientsLoaded = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.clientsDataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.clientsDataSource.sort = sort;
  }

  @Input() set client(value: any) {
    this._clients = value;
    this.loadClients();
  }
  get client() {
    return this._clients;
  }
  @Output() goToOrganizations: EventEmitter<any> = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    public clientService: ClientService,
    public date: DateService,
  ) {}

  applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.clientsDataSource.filter = filterValue.trim().toLowerCase();

    if (this.clientsDataSource.paginator) {
      this.clientsDataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.clientsDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.clientsDataSource.data);
  }

  loadClients(): void {
    this.clientsLoaded = false;
    const client_ids = this._clients ? this._clients.map((value) => value.id) : null;
    this.clientService.list(client_ids).then((clients) => {
      this.clients = clients;
      this.clientsDataSource.data = this.clients;
      this.clientsLoaded = true;
      this.selection.clear();
    });
  }

  getBillingSystemLabel(billingSystem: string): string {
    let label = '';
    label = billingSystem
      ? this.translate.instant(`app.panel.admin.billingSystemLabels.${billingSystem}`)
      : this.translate.instant(`app.panel.admin.billingSystemLabels.NONE`);
    return label;
  }

  getBillingSystemTooltip(billingSystem: string): string {
    let tooltip = '';
    tooltip = billingSystem
      ? this.translate.instant(`app.panel.admin.billingSystemTooltips.${billingSystem}`)
      : this.translate.instant(`app.panel.admin.billingSystemTooltips.NONE`);
    return tooltip;
  }

  getExpirationDateString(client: Client): string {
    let expirationDateString = '';

    if (client.billing_system === BillingSystem.ExperimentalCandidate) {
      if (client.experimental_candidate_expiration_date) {
        const prefix = this.translate.instant(`app.panel.admin.clientList.component.expirationDatePrefix`);
        const experimental_expiration_date = new Date(client.experimental_candidate_expiration_date);
        expirationDateString = `${prefix} ${experimental_expiration_date.toLocaleDateString(this.translate.currentLang)}`;
      } else {
        expirationDateString = this.translate.instant(`app.panel.admin.clientList.component.noExpirationDate`);
      }
    }
    return expirationDateString;
  }

  isAuthorizedAction(authorizedBillingSystem: string[], clients: any[]): boolean {
    let authorized = true;
    clients.forEach((client) => {
      if (!authorizedBillingSystem.includes(client['billing_system'])) {
        authorized = false;
      }
    });
    return authorized;
  }

  switchBillingSystem(clients: Client[], context: string): void {
    this.clientService.switchBillingSystem(clients, context).then(({ updated, count }) => {
      if (updated) {
        this.snackbar.success(this.translate.instant('app.panel.admin.component.someRowsHaveBeenUpdated', { count }));
        this.loadClients();
      }
    });
  }

  openExperimentalCandidateDialog(clients: Client[]): void {
    const dialogRef = this.dialog.open(ClientListExperimentalCandidateDialogComponent, {
      data: {
        clients: clients,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackbar.message(
          this.translate.instant('app.panel.admin.component.someRowsHaveBeenUpdated', { count: result.count }),
          {},
          {
            duration: 3000,
            panelClass: 'msg-success',
          },
        );
        this.loadClients();
      }
    });
  }
}
