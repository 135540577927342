import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/api.service';
import { CustomValidators } from 'src/app/shared/validators';
import { SnackbarService } from '../shared/snackbar.service';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form.submitted);
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  email = '';
  userId = '';
  token = '';
  mailSent = false;
  passwordChanged = false;
  hasToken = false;
  formGroup = new UntypedFormGroup({});
  passwordStrength = 0;
  confirmPasswordValidity = 0;
  errorStateMatcher = new CustomErrorStateMatcher();
  fieldErrors = {};
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private snackbar: SnackbarService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.hasToken = params.has('userId') && params.has('token');
      if (this.hasToken) {
        this.userId = params.get('userId');
        this.token = params.get('token');
        this.formGroup = this.formBuilder.group(
          {
            password: ['', CustomValidators.Password],
            confirmPassword: ['', Validators.required],
          },
          {
            updateOn: 'change',
            validators: CustomValidators.Form,
          },
        );
      }
    });
  }

  checkPassword(): void {
    const password = this.formGroup.get('password').value;
    let passwordStrength = 0;

    this.checkConfirmPassword();

    if (password.length === 0) {
      return;
    }

    if (password.length < 12) {
      this.passwordStrength = 1;
      return;
    } else {
      if (/[a-z]/.test(password)) {
        passwordStrength++;
      }
      if (/[A-Z]/.test(password)) {
        passwordStrength++;
      }
      if (/\d/.test(password)) {
        passwordStrength++;
      }
      if (/[-+_!@#$%^&*.,?{}:;]/.test(password)) {
        passwordStrength++;
      }

      switch (passwordStrength) {
        case 0:
        case 1:
        case 2:
          this.passwordStrength = 1;
          return;
        case 3:
          this.passwordStrength = 2;
          return;
        case 4:
          this.passwordStrength = 3;
          break;
      }
    }
  }

  checkConfirmPassword(): void {
    const password = this.formGroup.get('password').value;
    const confirmPassword = this.formGroup.get('confirmPassword').value;
    this.confirmPasswordValidity = 0;

    if (confirmPassword.length === 0) {
      return;
    }

    if (password !== confirmPassword) {
      this.confirmPasswordValidity = 1;
    } else {
      this.confirmPasswordValidity = 2;
    }
  }

  getError(fieldName: string): string {
    const field = this.formGroup.get(fieldName);

    if (field.value.length === 0) {
      return this.translate.instant('error.mandatoryField');
    }

    switch (fieldName) {
      case 'password':
        if (field.hasError('invalid')) {
          return this.translate.instant('error.weakPassword');
        }
        if (field.hasError('minLength')) {
          return this.translate.instant('error.shortPassword');
        }
        break;
      case 'confirmPassword':
        if (field.hasError('invalid')) {
          return this.translate.instant('error.notMatchingPassword');
        }
        break;
    }

    if (this.fieldErrors[fieldName]) {
      return this.fieldErrors[fieldName];
    }
  }

  sendMail(): void {
    if (this.email) {
      this.api
        .post('/users/change_password', {
          email: this.email,
          lang: this.translate.currentLang,
        })
        .subscribe(() => {
          this.mailSent = true;
          this.snackbar.success(this.translate.instant('app.changePassword.component.mailSent'));
        });
    }
  }

  changePassword(): void {
    if (this.hasToken) {
      this.api
        .post(`/users/${this.userId}/update_password?tk=${this.token}`, {
          password: this.formGroup.get('password').value,
          confirmation_password: this.formGroup.get('confirmPassword').value,
        })
        .subscribe(
          () => {
            this.passwordChanged = true;
            this.snackbar.success(this.translate.instant('app.changePassword.component.passwordChanged'));
          },
          (response: HttpErrorResponse) => {
            if (response.error && response.error.errors) {
              for (const error of response.error.errors) {
                if (error.field) {
                  const fieldName = error.field.replace('confirmation_password', 'confirmPassword');
                  this.fieldErrors[fieldName] = error.message;
                  this.formGroup.get(fieldName).setErrors({ invalid: true });
                }
              }
            }
          },
        );
    }
  }
}
