import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as libphonenumber from 'google-libphonenumber';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/api.service';
import { CustomValidators } from 'src/app/shared/validators';
import { countries, Country } from 'src/assets/referentials/countries';
import { Formula, formulas } from '../../assets/referentials/formulas';
import { SnackbarService } from '../shared/snackbar.service';
import { StepperService } from './stepper.service';

interface UpdateUserInfo {
  password: string;
  confirmation_password: string;
  client: {
    firstname: string;
    lastname: string;
    country: string;
    phone: string;
    company?: string;
    job?: string;
  };
  lang: string;
}

type FinalizationFormGroupFieldKey = 'password' | 'confirmPassword';
type ProfileFormGroupFieldKey = 'firstname' | 'lastname' | 'company' | 'job' | 'country' | 'phone' | 'representCompany' | 'email';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form?.submitted);
  }
}

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit, OnDestroy {
  formulas: Formula[] = [];
  countries: Country[] = [];
  email = '';
  profileFormGroup = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    job: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    phone: new FormControl({ value: '', disabled: true }, Validators.required),
    representCompany: new FormControl(false, Validators.required),
    email: new FormControl({ value: '', disabled: true }),
  });
  formulaFormGroup = new FormGroup({
    registerCardNow: new FormControl(true),
  });
  summaryFormGroup = new FormGroup({
    dataAccuracy: new FormControl(false, Validators.required),
    generalTerms: new FormControl(false, Validators.required),
  });
  finalizationFormGroup = new FormGroup(
    {
      password: new FormControl('', CustomValidators.Password),
      confirmPassword: new FormControl('', Validators.required),
    },
    {
      updateOn: 'change',
      validators: CustomValidators.Form,
    },
  );
  @ViewChild('profileForm') profileForm!: NgForm;
  @ViewChild('finalizationForm') finalizationForm!: NgForm;
  errorStateMatcher = new CustomErrorStateMatcher();
  fieldErrors: { [key: string]: string } = {};
  phoneUtil: libphonenumber.PhoneNumberUtil | undefined;
  subscription: Subscription | undefined;
  finalized = false;
  passwordStrength = 0;
  confirmPasswordValidity = 0;
  loginData = {
    email: '',
    password: '',
  };
  visibility = {
    password: false,
    confirmPassword: false,
  };

  constructor(
    private stepper: StepperService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: SnackbarService,
    private formBuilder: UntypedFormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private renderer: Renderer2,
    private translate: TranslateService,
  ) {
    this.formulas = formulas;
    this.countries = [{ code: undefined, label: '' } as Country].concat(countries.sort((a, b) => a.label.localeCompare(b.label)));
  }

  ngOnInit(): void {
    this.api.repudiate();
    this.stepper.reset();
    this.stepper.clear();
    this.route.paramMap.subscribe((params) => {
      const url = `/promised_accounts/${params.get('promiseAccountId')}/email?tk=${params.get('token')}`;
      this.api.get(url).subscribe(
        (data) => {
          if (data.email) {
            this.email = data.email;
            // profile init
            try {
              this.phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            } catch (error) {
              console.error(error);
            }
            this.profileFormGroup.patchValue({
              firstname: data.firstname || '',
              lastname: data.lastname || '',
              company: data.company || '',
              job: data.job || '',
              country: data.country || '',
              phone: data.phone || '',
              representCompany: data.representCompany,
              email: this.email,
            });
            this.checkCountry();
            this.profileFormGroup.valueChanges.subscribe((values) => {
              values.email = this.email;
              this.stepper.set('profile', values);
            });
          } else {
            this.router.navigate(['']);
          }
        },
        (_response: HttpErrorResponse) => {
          this.router.navigate(['/help/resend-creation-link']);
        },
      );
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  start(): void {
    this.stepper.set('profile', { email: this.email });
    this.stepper.start();
  }

  isStepperStarted(): boolean {
    return this.stepper.started;
  }

  previous(): void {
    this.stepper.set('profile', {});
    this.stepper.reset();
  }

  checkCountry(): void {
    const country = this.profileFormGroup.value.country;

    if (country === '' || country === undefined) {
      this.profileFormGroup.controls.phone.disable();
    } else {
      this.profileFormGroup.controls.phone.enable();
    }
  }

  getError(fieldName: ProfileFormGroupFieldKey): string | undefined {
    const field = this.profileFormGroup.controls[fieldName];

    if (typeof field.value === 'string' && field.value.length === 0) {
      return this.translate.instant('error.mandatoryField');
    }

    if (this.fieldErrors[fieldName]) {
      return this.fieldErrors[fieldName];
    }
  }

  submit(event: KeyboardEvent): void {
    if (this.phoneUtil) {
      try {
        const phoneNum = this.phoneUtil.parseAndKeepRawInput(this.profileFormGroup.value.phone!, this.profileFormGroup.value.country!);
        if (this.phoneUtil.isValidNumber(phoneNum)) {
          this.profileFormGroup.patchValue({ phone: this.phoneUtil.format(phoneNum, libphonenumber.PhoneNumberFormat.E164) });
        }
      } catch (error) {
        this.profileFormGroup.patchValue({ phone: '' });
        console.error(error);
      }
    }

    this.profileForm.onSubmit(event);
    this.finalizationForm.onSubmit(event);

    const { dataAccuracy, generalTerms } = this.summaryFormGroup.value;
    if (dataAccuracy && generalTerms) {
      const profile = this.stepper.get('profile');
      const { password, confirmPassword } = this.finalizationFormGroup.value;
      this.finalized = false;
      this.loginData.email = '';
      this.loginData.password = '';

      if (profile && password && confirmPassword) {
        this.route.paramMap.subscribe((params) => {
          const url = `/promised_accounts/${params.get('promiseAccountId')}/user?tk=${params.get('token')}`;
          const user: UpdateUserInfo = {
            password,
            confirmation_password: confirmPassword,
            client: {
              firstname: profile.firstname,
              lastname: profile.lastname,
              country: profile.country,
              phone: profile.phone,
            },
            lang: this.translate.currentLang,
          };
          if (profile.representCompany) {
            user.client['company'] = profile.company;
            user.client['job'] = profile.job;
          }
          this.subscription = this.recaptchaV3Service.execute('profile').subscribe((token) => {
            const httpOptions = {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
                'X-Skip-Auth-Interceptor': '',
              }),
            };
            this.api.post(url, user, httpOptions).subscribe(
              () => {
                this.finalized = true;
                localStorage.setItem(
                  'loginData',
                  JSON.stringify({
                    remember: true,
                    email: profile.email,
                  }),
                );
                this.loginData.email = profile.email;
                this.loginData.password = password;
              },
              (response: HttpErrorResponse) => {
                if (response.error && response.error.errors) {
                  for (const error of response.error.errors) {
                    const field = error.field;
                    if (typeof field === 'string') {
                      const fieldName = field.replace('confirmation_password', 'confirmPassword');
                      this.fieldErrors[fieldName] = error.message;
                      const profileFieldControl = this.profileFormGroup.get(fieldName);
                      const finalizationFieldControl = this.finalizationFormGroup.get(fieldName);
                      if (profileFieldControl) {
                        profileFieldControl.setErrors({ invalid: true });
                      }
                      if (finalizationFieldControl) {
                        finalizationFieldControl.setErrors({ invalid: true });
                      }
                    }
                  }
                }
              },
            );
          });
        });
      } else {
        this.router.navigate(['']);
      }
    } else {
      this.snackbar.error(this.translate.instant('error.certifyDataAccuracy'));
    }
  }

  clearCompanyFields(): void {
    this.profileFormGroup.patchValue({ company: '', job: '' });
  }

  getProfile(): any {
    return this.stepper.get('profile') ? this.stepper.get('profile') : {};
  }

  getCountryLabel(): string {
    let label = '';
    if (this.getProfile().country) {
      label = this.countries.filter((country) => country.code === this.getProfile().country)[0].label;
    }
    return label;
  }

  getDocumentUrl(documentName: string): string {
    if (documentName === 'termsAndConditions') {
      return `/assets/pdf/Parsec-CGVU-${this.translate.currentLang}.pdf`;
    } else {
      const url = 'https://parsec.cloud';
      const doc = documentName.replace('privacy', 'politique-de-confidentialite').replace('terms', 'mentions-legales');
      switch (this.translate.currentLang) {
        case 'fr':
          return `${url}/${doc}`;
        case 'en':
          return `${url}/${this.translate.currentLang}/${doc}/`;
        default:
          return `${url}/${doc}`;
      }
    }
  }

  getFinalizationError(fieldName: FinalizationFormGroupFieldKey): string | undefined {
    const field = this.finalizationFormGroup.controls[fieldName];

    if (field.value?.length === 0) {
      return this.translate.instant('error.mandatoryField');
    }

    switch (fieldName) {
      case 'password':
        if (field.hasError('invalid')) {
          return this.translate.instant('error.weakPassword');
        }
        if (field.hasError('minLength')) {
          return this.translate.instant('error.shortPassword');
        }
        break;
      case 'confirmPassword':
        if (field.hasError('invalid')) {
          return this.translate.instant('error.notMatchingPassword');
        }
        break;
    }

    const error = this.fieldErrors[fieldName];
    if (typeof error === 'string') {
      return error;
    }
  }

  checkPassword(): void {
    const password = this.finalizationFormGroup.value.password || '';
    let passwordStrength = 0;

    this.checkConfirmPassword();

    if (password.length === 0) {
      return;
    }

    if (password.length < 12) {
      this.passwordStrength = 1;
      return;
    } else {
      if (/[a-z]/.test(password)) {
        passwordStrength++;
      }
      if (/[A-Z]/.test(password)) {
        passwordStrength++;
      }
      if (/\d/.test(password)) {
        passwordStrength++;
      }
      if (/[-+_!@#$%^&*.,?{}:;]/.test(password)) {
        passwordStrength++;
      }

      switch (passwordStrength) {
        case 0:
        case 1:
        case 2:
          this.passwordStrength = 1;
          return;
        case 3:
          this.passwordStrength = 2;
          return;
        case 4:
          this.passwordStrength = 3;
          break;
      }
    }
  }

  checkConfirmPassword(): void {
    const password = this.finalizationFormGroup.value.password || '';
    const confirmPassword = this.finalizationFormGroup.value.confirmPassword || '';
    this.confirmPasswordValidity = 0;

    if (confirmPassword.length === 0) {
      return;
    }

    if (password !== confirmPassword) {
      this.confirmPasswordValidity = 1;
    } else {
      this.confirmPasswordValidity = 2;
    }
  }

  logIn(): void {
    this.api
      .authenticate({
        email: this.loginData.email,
        password: this.loginData.password,
      })
      .subscribe(() => {
        localStorage.setItem(
          'loginData',
          JSON.stringify({
            remember: true,
            email: this.loginData.email,
          }),
        );
        if (this.formulaFormGroup.value.registerCardNow) {
          this.router.navigate(['panel/billing']);
        } else {
          this.router.navigate(['panel']);
        }
      });
  }

  toggleVisibility(fieldName: 'password' | 'confirmPassword'): void {
    this.visibility[fieldName] = !this.visibility[fieldName];
  }
}
