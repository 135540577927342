import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap, take } from 'rxjs/operators';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { AdminAPI, BillingSystem } from '../admin.global';
// eslint-disable-next-line max-len
import { DateService } from 'src/app/shared/date.service';
import { CustomRequestListUpdateDialogComponent } from './custom-request-list-update-dialog/custom-request-list-update-dialog.component';
import { CustomRequest, CustomRequestListResponse, Status } from './custom-request-list.global';
import { CustomRequestService } from './custom-request.service';

@Component({
  selector: 'app-custom-request-list',
  templateUrl: './custom-request-list.component.html',
  styleUrls: ['./custom-request-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomRequestListComponent {
  public readonly BillingSystem: typeof BillingSystem = BillingSystem;
  public readonly AdminAPI: typeof AdminAPI = AdminAPI;
  status = Status;

  customRequests = [];
  customRequestsDisplayedColumns: string[] = ['name', 'created_at', 'email', 'phone', 'job', 'status'];
  customRequestsDataSource: CustomRequest[] = [];
  customRequestsColumnsToDisplayWithExpand: string[] = [...this.customRequestsDisplayedColumns, 'expand', 'options'];
  expandedCustomRequest: CustomRequest | null;
  isLoadingResults = false;
  searchInput = '';
  resultsLength = 0;
  errors = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    private _ngZone: NgZone,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    public customRequestService: CustomRequestService,
    public date: DateService,
  ) {}

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.loadCustomRequests();
        }),
      )
      .subscribe();
  }

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  applySearch(event: Event): void {
    this.paginator.pageIndex = 0;
    this.loadCustomRequests().subscribe();
  }

  loadCustomRequests(): Observable<void> {
    this.isLoadingResults = true;
    return this.customRequestService
      .list(this.searchInput, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize)
      .pipe(
        catchError(() => of(null)),
        map((data: CustomRequestListResponse) => {
          this.isLoadingResults = false;
          this.resultsLength = data.count;
          return data.results;
        }),
        map((data: CustomRequest[]) => {
          this.customRequestsDataSource = data;
        }),
      );
  }

  async onExpand(event: Event, customRequest: CustomRequest): Promise<void> {
    this.expandedCustomRequest = this.expandedCustomRequest === customRequest ? null : customRequest;
    event.stopPropagation();
  }

  changeFilter(filter: any): void {
    this.paginator.pageIndex = 0;
    this.customRequestService.filters.forEach((filterElem) => {
      if (filter.key !== filterElem.key) {
        filterElem.enabled = false;
      }
    });
    filter.enabled = !filter.enabled;
    this.loadCustomRequests().subscribe();
  }

  openUpdateDialog(customRequest: CustomRequest): void {
    const dialogRef = this.dialog.open(CustomRequestListUpdateDialogComponent, {
      data: customRequest,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.updated) {
        this.snackbar.success(this.translate.instant('app.panel.admin.customRequestList.component.customOrderUpdated'));
        this.loadCustomRequests().subscribe();
      }
    });
  }

  acceptRequest(customRequest: CustomRequest): void {
    this.customRequestService.accept(customRequest.id).subscribe(
      (customRequestUpdated: CustomRequest) => {
        this.snackbar.success(this.translate.instant('app.panel.admin.customRequestList.component.clientCreated'));
      },
      (httpError: HttpErrorResponse) => {
        this.errors = {};
        for (const error of httpError.error.errors || []) {
          this.errors[error.attr] = error;
        }
      },
    );
  }

  standbyRequests(customRequest: CustomRequest): void {}

  rejectRequests(customRequest: CustomRequest): void {}
}
