<div class="row no-gutters align-items-center justify-content-end filters">
  <mat-form-field>
    <mat-label>{{ 'app.panel.admin.component.search' | translate }}</mat-label>
    <input
      matInput
      (keyup)="applySearch($event)"
      #input
    />
  </mat-form-field>
  <button
    mat-button
    [ngClass]="{ 'font-weight-bold': organizationService.countEnabledFilters() > 0 }"
    [matMenuTriggerFor]="filtersMenu"
    (click)="$event.stopPropagation()"
  >
    <mat-icon>filter_list</mat-icon>
    <span>
      {{ 'app.panel.admin.component.filter' | translate }}
      <ng-container *ngIf="organizationService.countEnabledFilters() > 0">({{ organizationService.countEnabledFilters() }})</ng-container>
    </span>
  </button>
  <mat-menu
    class="mat-menu-nowrap"
    #filtersMenu="matMenu"
  >
    <button
      [ngClass]="{ 'mat-button-end-icon': filter.enabled }"
      mat-menu-item
      (click)="filter.enabled = !filter.enabled; loadOrganizations()"
      *ngFor="let filter of organizationService.filters"
    >
      <span>{{ 'app.panel.admin.organizationList.component.filters.' + filter.key | translate }}</span>
      <mat-icon *ngIf="filter.enabled">check</mat-icon>
    </button>
  </mat-menu>
</div>
<mat-card
  class="row no-gutters align-items-center justify-content-begin selection-actions"
  *ngIf="!selection.isEmpty()"
>
  <button
    mat-button
    (click)="goToClient.emit(getClientsData(selection.selected))"
  >
    <span>{{ 'app.panel.admin.component.goToClients' | translate }}</span>
  </button>
  <button
    mat-button
    (click)="alertAboutEndOfContract(selection.selected)"
    [disabled]="
      !isAuthorizedAction([BillingSystem.CustomOrder], selection.selected, [CustomOrderStatus.InvoicePaid, CustomOrderStatus.ContractEnded])
    "
  >
    <span>{{ 'app.panel.admin.component.alertAboutEndOfContract' | translate }}</span>
  </button>
  <button
    mat-button
    (click)="openExpirationDateDialog(selection.selected)"
  >
    <span>{{ 'app.panel.admin.organizationList.component.openExpirationDateDialog' | translate }}</span>
  </button>
  <!-- deactivated till the BMS give the is_frozen state directly in list_organizations -->
  <!-- <button
    mat-button
    (click)="freezeOrganization(selection.selected)"
    [disabled]="!organizationService.areNotFrozen(selection.selected)"
  >
    <span>{{ 'app.panel.admin.component.freezeOrganization' | translate }}</span>
  </button>
  <button
    mat-button
    (click)="unfreezeOrganization(selection.selected)"
    [disabled]="!organizationService.areFrozen(selection.selected)"
  >
    <span>{{ 'app.panel.admin.component.unFreezeOrganization' | translate }}</span>
  </button> -->
</mat-card>
<div class="mat-elevation-z8">
  <table
    mat-table
    [dataSource]="organizationsDataSource"
    matSort
    multiTemplateDataRows
  >
    <ng-container matColumnDef="select">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          *ngIf="organizationsDataSource.data.length > 0 && organizationsDataSource.filteredData.length > 0"
        >
        </mat-checkbox>
      </th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="suffix">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.organizationList.component.name' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let organization"
        class="font-weight-bold"
      >
        {{ organization.suffix }}
      </td>
    </ng-container>
    <ng-container matColumnDef="created_at">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.organizationList.component.createdAt' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let organization"
      >
        {{ date.getLocaleString(organization.created_at) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="expiration_date">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.organizationList.component.expirationDate' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let organization"
      >
        {{
          organization.expiration_date
            ? date.getLocaleString(organization.expiration_date)
            : ('app.panel.admin.component.noDate' | translate)
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="client__billing_system">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'app.panel.admin.organizationList.component.billingSystem' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let organization"
      >
        {{ getBillingSystemLabel(organization.client__billing_system) }}
        <span
          class="font-italic text-{{ getCustomOrderStatusColor(customOrderStatus[organization.parsec_id]) }}"
          *ngIf="organization.client__billing_system === BillingSystem.CustomOrder"
          >({{ getCustomOrderStatusLabel(customOrderStatus[organization.parsec_id]) }})</span
        >
        <span
          class="font-italic"
          *ngIf="organization.client__billing_system === BillingSystem.ExperimentalCandidate"
        >
          ({{ getExpirationDateString(organization.client__billing_system, organization.client__experimental_candidate_expiration_date) }})
        </span>
        <a
          mat-icon-button
          class="icon-tooltip"
          aria-label="billing system about"
          matTooltipPosition="right"
          (click)="$event.stopPropagation()"
          [matTooltip]="getBillingSystemTooltip(organization)"
          routerLink="/panel/admin/help/billing-system"
          matTooltipClass="multiline-tooltip"
        >
          <mat-icon>help</mat-icon>
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th
        mat-header-cell
        *matHeaderCellDef
        aria-label="organization actions"
      >
        &nbsp;
      </th>
      <td
        mat-cell
        *matCellDef="let organization"
        class="icon-column"
      >
        <button
          mat-icon-button
          aria-label="expand organization"
          (click)="onExpand($event, organization)"
        >
          <mat-icon *ngIf="expandedOrganization !== organization">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedOrganization === organization">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="options">
      <th
        mat-header-cell
        *matHeaderCellDef
        aria-label="organization actions"
      >
        &nbsp;
      </th>
      <td
        mat-cell
        *matCellDef="let organization"
        class="icon-column"
      >
        <button
          mat-icon-button
          aria-label="options organization"
          [matMenuTriggerFor]="organizationOptionsMenu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #organizationOptionsMenu="matMenu">
          <button
            mat-menu-item
            (click)="goToClient.emit(getClientsData([organization]))"
          >
            <span>{{ 'app.panel.admin.component.goToClient' | translate }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="
              isAuthorizedAction(
                [BillingSystem.CustomOrder],
                [organization],
                [CustomOrderStatus.InvoicePaid, CustomOrderStatus.ContractEnded]
              )
            "
            (click)="alertAboutEndOfContract([organization])"
          >
            <span>{{ 'app.panel.admin.component.alertAboutEndOfContract' | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="openExpirationDateDialog([organization])"
          >
            <span>{{ 'app.panel.admin.organizationList.component.openExpirationDateDialog' | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="openFreezeDialog([organization])"
          >
            <span>{{ 'app.panel.admin.organizationList.component.openFreezeDialog' | translate }}</span>
          </button>
          <!-- <ng-container *ngIf="organizationService.areNotFrozen([organization]); else unfreezeButton">
            <button
              mat-menu-item
              (click)="freezeOrganization([organization])"
            >
              <span>{{ 'app.panel.admin.component.freezeOrganization' | translate }}</span>
            </button>
          </ng-container>
          <ng-template #unfreezeButton>
            <button
              mat-menu-item
              (click)="unfreezeOrganization([organization])"
            >
              <span>{{ 'app.panel.admin.component.unFreezeOrganization' | translate }}</span>
            </button>
          </ng-template> -->
        </mat-menu>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let organization"
        [attr.colspan]="organizationsColumnsToDisplayWithExpand.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="organization === expandedOrganization ? 'expanded' : 'collapsed'"
        >
          <ng-container *ngIf="expandedOrganizationInfoRetrieved && organizationsInfo[organization.id]">
            <div class="row no-gutters">
              <div class="form-label italic col-12">{{ 'app.panel.organization.component.parsecStats' | translate }}</div>
            </div>
            <div
              class="row justify-content-left stats"
              *ngIf="!isEmptyOrNullObject(organizationsInfo[organization.id].status)"
            >
              <div class="col-6">
                <div class="d-flex">
                  <mat-icon>info</mat-icon>
                  <span class="font-weight-bold">{{ 'app.panel.organization.component.status.title' | translate }}</span>
                  {{ getOrganizationStatus(organizationsInfo[organization.id].status) }}
                </div>
              </div>
            </div>
            <div
              class="row justify-content-left stats"
              *ngIf="isEmptyOrNullObject(organizationsInfo[organization.id].stats)"
            >
              <div class="col-6">
                <div class="d-flex">
                  <mat-icon>equalizer</mat-icon>
                  <span class="font-weight-bold">{{ 'app.panel.organization.component.noAvailableStats' | translate }}</span>
                </div>
              </div>
            </div>
            <div
              class="row justify-content-center stats"
              *ngIf="!isEmptyOrNullObject(organizationsInfo[organization.id].stats)"
            >
              <div class="col-6">
                <div
                  class="d-flex"
                  [ngClass]="{
                    'text-warning': expandedCustomOrderLimits['STORAGE'] === 'warning',
                    'text-danger': expandedCustomOrderLimits['STORAGE'] === 'error'
                  }"
                >
                  <mat-icon>storage</mat-icon
                  ><span class="font-weight-bold">{{ 'app.panel.organization.component.dataSize' | translate }}</span>
                  {{ organizationsInfo[organization.id].stats.readableDataSize }}
                  <mat-icon *ngIf="expandedCustomOrderLimits['STORAGE']">error_outline</mat-icon>
                </div>
                <div class="d-flex">
                  <mat-icon>equalizer</mat-icon
                  ><span class="font-weight-bold">{{ 'app.panel.organization.component.metadataSize' | translate }}</span>
                  {{ organizationsInfo[organization.id].stats.readableMetadataSize }}
                </div>
                <div class="d-flex">
                  <mat-icon>people</mat-icon
                  ><span class="font-weight-bold">{{ 'app.panel.organization.component.users' | translate }}</span>
                  {{ 'app.panel.organization.component.userCount' | translate: organizationsInfo[organization.id].stats.users.total }}
                </div>
              </div>
              <div class="col-6">
                <div
                  class="d-flex"
                  [ngClass]="{
                    'text-warning': expandedCustomOrderLimits['ADMIN'] === 'warning',
                    'text-danger': expandedCustomOrderLimits['ADMIN'] === 'error'
                  }"
                >
                  <mat-icon>manage_accounts</mat-icon
                  ><span class="font-weight-bold">{{ 'app.panel.organization.component.admins' | translate }}</span>
                  {{ 'app.panel.organization.component.userCount' | translate: organizationsInfo[organization.id].stats.users.ADMIN }}
                  <mat-icon *ngIf="expandedCustomOrderLimits['ADMIN']">error_outline</mat-icon>
                </div>
                <div
                  class="d-flex"
                  [ngClass]="{
                    'text-warning': expandedCustomOrderLimits['STANDARD'] === 'warning',
                    'text-danger': expandedCustomOrderLimits['STANDARD'] === 'error'
                  }"
                >
                  <mat-icon>person</mat-icon
                  ><span class="font-weight-bold">{{ 'app.panel.organization.component.standards' | translate }}</span>
                  {{ 'app.panel.organization.component.userCount' | translate: organizationsInfo[organization.id].stats.users.STANDARD }}
                  <mat-icon *ngIf="expandedCustomOrderLimits['STANDARD']">error_outline</mat-icon>
                </div>
                <div
                  class="d-flex"
                  [ngClass]="{
                    'text-warning': expandedCustomOrderLimits['OUTSIDER'] === 'warning',
                    'text-danger': expandedCustomOrderLimits['OUTSIDER'] === 'error'
                  }"
                >
                  <mat-icon>person_outline</mat-icon
                  ><span class="font-weight-bold">{{ 'app.panel.organization.component.outsiders' | translate }}</span>
                  {{ 'app.panel.organization.component.userCount' | translate: organizationsInfo[organization.id].stats.users.OUTSIDER }}
                  <mat-icon *ngIf="expandedCustomOrderLimits['OUTSIDER']">error_outline</mat-icon>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="expandedCustomOrderDetailsRetrieved && customOrderDetails[organization.id]">
            <div class="row no-gutters">
              <div class="form-label italic col-12">{{ 'app.panel.organization.component.customOrderDetails' | translate }}</div>
            </div>
            <div
              class="row justify-content-left stats"
              *ngIf="isEmptyOrNullObject(customOrderDetails[organization.id])"
            >
              <div class="col-6">
                <div class="d-flex">
                  <mat-icon>receipt</mat-icon>
                  <span class="font-weight-bold">{{ 'app.panel.organization.component.noAvailableContractDetails' | translate }}</span>
                </div>
              </div>
            </div>
            <div
              class="row justify-content-left stats"
              *ngIf="!isEmptyOrNullObject(customOrderDetails[organization.id])"
            >
              <div class="col-6">
                <div class="d-flex">
                  <mat-icon>receipt</mat-icon>
                  <span class="font-weight-bold">
                    {{ 'app.panel.organization.component.invoiceNumber' | translate }}
                  </span>
                  <a
                    target="_blank"
                    [href]="customOrderDetails[organization.id]['link']"
                    [title]="'app.panel.organization.component.seeInSellsy' | translate"
                  >
                    {{ customOrderDetails[organization.id]['invoiceNumber'] }}
                  </a>
                </div>
                <div class="d-flex">
                  <mat-icon>payments</mat-icon>
                  <span class="font-weight-bold">
                    {{ 'app.panel.organization.component.totalPrice' | translate }}
                  </span>
                  {{ customOrderDetails[organization.id]['amount'] }}
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <mat-icon>manage_accounts</mat-icon>
                  <span class="font-weight-bold">
                    {{ 'app.panel.organization.component.adminLicenses' | translate }}
                  </span>
                  {{ customOrderDetails[organization.id]['licenses']['ADMIN'] }}
                </div>
                <div class="d-flex flex-wrap">
                  <mat-icon>person</mat-icon>
                  <span class="font-weight-bold">
                    {{ 'app.panel.organization.component.standardLicenses' | translate }}
                  </span>
                  {{ customOrderDetails[organization.id]['licenses']['STANDARD'] }}
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <mat-icon>event_available</mat-icon>
                  <span class="font-weight-bold">
                    {{ 'app.panel.organization.component.startDate' | translate }}
                  </span>
                  {{ customOrderDetails[organization.id]['startDate'] }}
                </div>
                <div
                  class="d-flex flex-wrap"
                  [ngClass]="{
                    'text-warning': expandedCustomOrderLimits['END_DATE'] === 'warning',
                    'text-danger': expandedCustomOrderLimits['END_DATE'] === 'error'
                  }"
                >
                  <mat-icon>event_busy</mat-icon>
                  <span class="font-weight-bold">
                    {{ 'app.panel.organization.component.endDate' | translate }}
                  </span>
                  {{ customOrderDetails[organization.id]['endDate'] }}
                  <mat-icon *ngIf="expandedCustomOrderLimits['END_DATE']">error_outline</mat-icon>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <mat-icon>hiking</mat-icon>
                  <span class="font-weight-bold">
                    {{ 'app.panel.organization.component.outsiderLicenses' | translate }}
                  </span>
                  {{ customOrderDetails[organization.id]['licenses']['OUTSIDER'] }}
                </div>
                <div class="d-flex flex-wrap">
                  <mat-icon>storage</mat-icon>
                  <span class="font-weight-bold">
                    {{ 'app.panel.organization.component.storageLicenses' | translate }}
                  </span>
                  {{ customOrderDetails[organization.id]['licenses']['STORAGE'] }}
                </div>
              </div>
            </div>
            <div
              class="row justify-content-left stats"
              *ngIf="!isEmptyOrNullObject(customOrderDetails[organization.id])"
            >
              <table
                mat-table
                [dataSource]="invoiceElementsDataSource"
                *ngIf="invoiceElementsDataSource.length > 0"
                class="col-12"
              >
                <ng-container matColumnDef="name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    {{ 'app.panel.organization.component.contract.type' | translate }}
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    {{ 'app.panel.organization.component.contract.quantity' | translate }}
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.quantity }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="unitAmount">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    {{ 'app.panel.organization.component.contract.unitAmount' | translate }}
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.unitAmount }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="taxAmount">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    {{ 'app.panel.organization.component.contract.taxAmount' | translate }}
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.taxAmount }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="totalAmount">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    {{ 'app.panel.organization.component.contract.totalAmount' | translate }}
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.totalAmount }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="invoiceElementsDisplayedColumns"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: invoiceElementsDisplayedColumns"
                ></tr>
              </table>
            </div>
          </ng-container>
          <mat-spinner
            *ngIf="!isExpandedOrganizationLoaded(organization)"
            diameter="40"
          ></mat-spinner>
        </div>
      </td>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="organizationsColumnsToDisplayWithExpand"
    ></tr>
    <tr
      mat-row
      *matRowDef="let organization; columns: organizationsColumnsToDisplayWithExpand"
      class="example-element-row"
      [class.example-expanded-row]="expandedOrganization === organization"
      (click)="selection.toggle(organization)"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
    <ng-container>
      <tr
        class="mat-row"
        *matNoDataRow
      >
        <td
          class="mat-cell"
          [attr.colspan]="organizationsColumnsToDisplayWithExpand.length"
        >
          <ng-container *ngIf="input.value">
            {{ 'app.panel.admin.component.noDataMatchingFilter' | translate }} "{{ input.value }}"
          </ng-container>
          <ng-container *ngIf="!input.value && organizationsLoaded">
            {{ 'app.panel.admin.component.noResults' | translate }}
          </ng-container>
          <mat-spinner
            diameter="40"
            *ngIf="!organizationsLoaded"
          ></mat-spinner>
        </td>
      </tr>
    </ng-container>
  </table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page of organizations"
  ></mat-paginator>
</div>
