import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  isEnabled(feature: string): boolean {
    return environment.features[feature] === true;
  }
}
