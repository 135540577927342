<div class="container">
  <div class="row align-items-center justify-content-center row-container">
    <mat-card class="col-12">
      <div>
        <div class="row align-items-center">
          <div class="col-10 offset-1">
            <h3 class="text-center form-label">{{ 'app.panel.admin.adminHelp.component.anyQuestion' | translate }}</h3>
          </div>
        </div>
        <div class="row justify-content-center">
          <mat-form-field
            class="col-10"
            appearance="outline"
          >
            <mat-label>{{ 'app.panel.admin.adminHelp.component.search' | translate }}</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input
              type="text"
              matInput
              [(ngModel)]="target"
            />
          </mat-form-field>
        </div>
        <div class="row justify-content-center">
          <mat-accordion class="col-10">
            <ng-container *ngFor="let question of questions">
              <mat-expansion-panel
                *ngIf="showQuestion(question)"
                [expanded]="question.id === expandedQuestionId"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title class="font-weight-bold">{{ question.i18nKey + '.title' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <p *ngFor="let text of question.i18nKey + '.texts' | translate">{{ text }}</p>
                <ul>
                  <li *ngFor="let text of getQuestionTexts(question)">{{ text }}</li>
                </ul>
                <a
                  *ngIf="question.link"
                  [routerLink]="question.link.path"
                >
                  {{ question.i18nKey + '.link' | translate }}
                </a>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>
      </div>
    </mat-card>
  </div>
</div>
