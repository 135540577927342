export interface Filter {
  key: string;
  enabled: boolean;
  fields: [{ name: string; value: any }];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AdminAPI {
  export enum BillingSystemSwitchContext {
    CustomOrderCandidateApproval = 'custom_order_candidate_approval',
    ExperimentalCandidateSwitch = 'experimental_candidate_switch',
    NoneSwitch = 'none_switch',
    CustomOrderSwitch = 'custom_order_switch',
  }
}

export enum BillingSystem {
  None = 'NONE',
  CustomOrderCandidate = 'CUSTOM_ORDER_CANDIDATE',
  CustomOrder = 'CUSTOM_ORDER',
  ExperimentalCandidate = 'EXPERIMENTAL_CANDIDATE',
  Stripe = 'STRIPE',
}

export enum CustomOrderStatus {
  NothingLinked = 'nothing_linked',
  EstimateLinked = 'estimate_linked',
  InvoiceToBePaid = 'invoice_to_be_paid',
  InvoicePaid = 'invoice_paid',
  ContractEnded = 'contract_ended',
  Unknown = 'unknown',
}

export enum SellsyBillingUnitReference {
  AdminUserMonth = 'Psc_D0_Adm_M',
  StandardUserMonth = 'Psc_D0_Std_User_M',
  OutsiderUserMonth = 'Psc_D0_Ext_User_M',
  HundredGoMonth = 'Psc_Stck_100_Go_M',
}

export enum OrganizationInfoType {
  AdminUser = 'ADMIN',
  StandardUser = 'STANDARD',
  OutsiderUser = 'OUTSIDER',
  Storage = 'STORAGE',
}

export enum CustomFieldCodes {
  OrganizationId = 'parsec-saas-organization-id',
  StartDate = 'parsec-saas-custom-order-start-date',
  EndDate = 'parsec-saas-custom-order-end-date',
  StandardLicenseCount = 'parsec-saas-custom-order-standard-license-count',
  AdminLicenseCount = 'parsec-saas-custom-order-admin-license-count',
  OutsiderLicenseCount = 'parsec-saas-custom-order-outsider-license-count',
  StorageLicenseCount = 'parsec-saas-custom-order-storage-license-count',
}

export function sellsyReferenceToOrganizationInfoType(sellsyBillingUnitReference: SellsyBillingUnitReference): string {
  switch (sellsyBillingUnitReference) {
    case SellsyBillingUnitReference.AdminUserMonth:
      return OrganizationInfoType.AdminUser;
    case SellsyBillingUnitReference.StandardUserMonth:
      return OrganizationInfoType.StandardUser;
    case SellsyBillingUnitReference.OutsiderUserMonth:
      return OrganizationInfoType.OutsiderUser;
    case SellsyBillingUnitReference.HundredGoMonth:
      return OrganizationInfoType.Storage;
  }
}

export function organizationInfoTypeToCustomFieldCode(organizationInfoType: OrganizationInfoType): CustomFieldCodes {
  switch (organizationInfoType) {
    case OrganizationInfoType.AdminUser:
      return CustomFieldCodes.AdminLicenseCount;
    case OrganizationInfoType.StandardUser:
      return CustomFieldCodes.StandardLicenseCount;
    case OrganizationInfoType.OutsiderUser:
      return CustomFieldCodes.OutsiderLicenseCount;
    case OrganizationInfoType.Storage:
      return CustomFieldCodes.StorageLicenseCount;
  }
}
