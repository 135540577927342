import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCommonModule, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {
  MatLegacyPaginatorIntl as MatPaginatorIntl,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

export function createCustomMatPaginatorIntl(translate: TranslateService) {
  return new CustomMatPaginatorIntl(translate);
}

export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.getTranslations();
    this.translate.onLangChange.subscribe(() => this.getTranslations());
  }

  getTranslations(): void {
    this.translate.get('extLibraries.matPaginator').subscribe((texts) => {
      this.nextPageLabel = texts['next'];
      this.previousPageLabel = texts['previous'];
      this.itemsPerPageLabel = texts['itemsPerPage'];
      this.changes.next();
    });
  }
}

@NgModule({
  imports: [
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatIconModule,
    MatSelectModule,
    MatSnackBarModule,
    MatStepperModule,
    MatRadioModule,
    MatBottomSheetModule,
    MatTabsModule,
    MatListModule,
    MatBadgeModule,
    MatDialogModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatPaginatorModule,
    MatChipsModule,
    MatCommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  exports: [
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatIconModule,
    MatSelectModule,
    MatSnackBarModule,
    MatStepperModule,
    MatRadioModule,
    MatBottomSheetModule,
    MatTabsModule,
    MatListModule,
    MatBadgeModule,
    MatDialogModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatSortModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatCommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: createCustomMatPaginatorIntl,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR',
    },
  ],
})
export class MatImportModule {}
