import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { DateService } from 'src/app/shared/date.service';
import { FeatureService } from 'src/app/shared/feature.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { CustomValidators } from 'src/app/shared/validators';
import { BillingSystem } from '../admin/admin.global';
import { BillingAddPaymentDialog } from './billing-add.component';
import { BillingDefaultPaymentDialog } from './billing-default.component';
import { BillingRemovePaymentDialog } from './billing-remove.component';

export class PaymentMethod {
  id: string;
  type: string;
  last_digits: string;
  default = false;

  constructor(id: string, type: string, lastDigits: string, isDefault: boolean = false) {
    this.id = id;
    this.type = type;
    this.last_digits = lastDigits;
    this.default = isDefault;
  }
}

export class CardPaymentMethod extends PaymentMethod {
  brand: string;
  exp_date: string;

  constructor(id: string, lastDigits: string, brand: string, expDate: string, isDefault: boolean = false) {
    super(id, 'card', lastDigits, isDefault);
    this.brand = brand;
    this.exp_date = expDate;
  }
}

export class DebitPaymentMethod extends PaymentMethod {
  bank_name: string;

  constructor(id: string, lastDigits: string, bankName: string, isDefault: boolean = false) {
    super(id, 'debit', lastDigits, isDefault);
    this.bank_name = bankName;
  }
}

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form.submitted);
  }
}

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  BILLING_SYSTEM_NONE = 'NONE';
  BILLING_SYSTEM_STRIPE = 'STRIPE';
  BILLING_SYSTEM_CUSTOM_ORDER = 'CUSTOM_ORDER';
  BILLING_SYSTEM_CUSTOM_ORDER_CANDIDATE = 'CUSTOM_ORDER_CANDIDATE';
  BILLING_SYSTEM_EXPERIMENTAL_CANDIDATE = 'EXPERIMENTAL_CANDIDATE';
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @ViewChild(MatTabGroup) tabs: MatTabGroup;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#000000',
        color: '#000000',
        lineHeight: '50px',
        fontWeight: '300',
        fontFamily: 'Montserrat, "Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: 'rgba(0,0,0,0.38)',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'fr',
  };

  user = {
    pk: '',
    client: {
      pk: '',
    },
  };
  isRegistered = false;
  representCompany = false;
  formGroupMonthlySubscription = new UntypedFormGroup({});
  formGroupCustomOrder = new UntypedFormGroup({});
  errorStateMatcher = new CustomErrorStateMatcher();
  fieldErrors = {};
  invoices = [];
  isLoaded = false;
  billingDetails: {
    address: {
      line1: string;
      line2: string;
      postal_code: string;
      city: string;
      state: string;
      country: string;
    };
    email: string;
    name: string;
    payment_methods: Array<CardPaymentMethod | DebitPaymentMethod>;
  };
  pageSize = 8;
  pageIndex = 0;
  invoicesViewSet: any;
  desiredBillingSystem = 'monthlySubscription';
  billing_system = 'NONE';
  desiredPaymentMethod;
  desiredPaymentMethods = [
    {
      code: 'card',
      label: 'app.panel.billing.component.desiredPaymentMethods.card',
    },
    // TODO: Implement a feature that allows users to add a Debit Card (ref: Issue #227)
    // {
    //   code: 'debit',
    //   label: 'app.panel.billing.component.desiredPaymentMethods.debit'
    // }
  ];
  selectedPaymentMethod = '';
  defaultPaymentMethod = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private snackbar: SnackbarService,
    private stripeService: StripeService,
    private translate: TranslateService,
    public feature: FeatureService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public date: DateService,
  ) {
    this.billingDetails = {
      address: {
        line1: '',
        line2: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
      },
      email: '',
      name: '',
      payment_methods: [],
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      if (params.has('subtab') && params.get('subtab') !== 'invoices') {
        this.router.navigate(['/panel/billing']);
      }
    });
    this.formGroupMonthlySubscription = this.formBuilder.group(
      {
        line1: ['', Validators.required],
        line2: '',
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        state: '',
        country: ['', Validators.required],
      },
      {
        updateOn: 'change',
        validators: CustomValidators.Form,
      },
    );
    this.loadUser();
  }

  shouldDisplayMonthlySubscriptionContent(): boolean {
    return (
      this.billing_system == this.BILLING_SYSTEM_STRIPE ||
      (this.billing_system == this.BILLING_SYSTEM_NONE && this.desiredBillingSystem == 'monthlySubscription')
    );
  }

  loadUser(): void {
    const user = this.auth.whoIs();
    this.api.get('/users/' + user.user_id).subscribe(
      (response) => {
        this.user = response;
        this.isRegistered = response.client.stripe_customer_id ? true : false;
        this.representCompany = response.client.company !== 'N/A';
        this.translate.onLangChange.subscribe((event) => {
          this.updateElementsLocale(event.lang);
        });
        this.billing_system = response.client.billing_system;
        this.loadBillingDetails();
        this.loadInvoices();
      },
      () => {
        this.auth.repudiate();
        this.router.navigate(['']);
      },
    );
  }

  loadBillingDetails(): void {
    if (this.billing_system === this.BILLING_SYSTEM_STRIPE && this.isRegistered) {
      const url = `/users/${this.user.pk}/clients/${this.user.client.pk}/billing_details`;
      this.api.get(url).subscribe((response) => {
        this.billingDetails = response;
        // WARNING: do not merge the following lines uncommented, only for sepa_debit testing purpose
        // this.billingDetails.payment_methods.push(new DebitPaymentMethod('pm_dsglksdfjksldffjlkds', '7654', 'Crédit Agricole'));
        // this.billingDetails.payment_methods.push(new DebitPaymentMethod('pm_dsgljhhjgjhjhhgjjlkds', '7654', 'Crédit Agricole'));
        // this.billingDetails.payment_methods.push(new DebitPaymentMethod('pm_feioerufriofouikjl', '7654', 'Crédit Agricole'));
        this.defaultPaymentMethod = this.billingDetails.payment_methods.find((paymentMethod) => paymentMethod.default === true)['id'];
        if (this.billingDetails.address) {
          this.formGroupMonthlySubscription.get('line1').setValue(this.billingDetails.address.line1);
          this.formGroupMonthlySubscription.get('line2').setValue(this.billingDetails.address.line2);
          this.formGroupMonthlySubscription.get('postalCode').setValue(this.billingDetails.address.postal_code);
          this.formGroupMonthlySubscription.get('city').setValue(this.billingDetails.address.city);
          this.formGroupMonthlySubscription.get('state').setValue(this.billingDetails.address.state);
          this.formGroupMonthlySubscription.get('country').setValue(this.billingDetails.address.country);
        }
        this.isLoaded = true;
      });
    } else {
      this.isLoaded = true;
    }
  }

  loadInvoices(): void {
    if (this.billing_system === this.BILLING_SYSTEM_STRIPE && this.isRegistered) {
      const url = `/users/${this.user.pk}/clients/${this.user.client.pk}/invoices`;
      this.api.get(url).subscribe((response) => {
        this.invoicesViewSet = response;
        this.invoices = this._formatInvoicesElements(response.results, 0);
        this.route.paramMap.subscribe((params) => {
          this.tabs.selectedIndex = params.has('subtab') ? 1 : 0;
        });
      });
    }
  }

  _formatInvoicesElements(invoices: any[], pageIndex: number) {
    let elements = [];
    elements = invoices.slice(this.pageSize * pageIndex, this.pageSize * (pageIndex + 1)).map((invoice) => ({
      id: invoice.id,
      pdf: invoice.pdf,
      organization: invoice.organization,
      status: invoice.status,
      period_start: invoice.period_start,
      period_end: invoice.period_end,
      total: (invoice.total / 100).toFixed(2).replace('.', ','),
    }));
    return elements;
  }

  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.invoices = this._formatInvoicesElements(this.invoicesViewSet.results, this.pageIndex);
  }

  getError(formGroup: UntypedFormGroup, fieldName: string): string {
    const field = formGroup.get(fieldName);

    if (field.value && field.value.length === 0) {
      return this.translate.instant('error.mandatoryField');
    }

    if (this.fieldErrors[fieldName]) {
      return this.fieldErrors[fieldName];
    }
  }

  updateBillingDetails(): void {
    const _user = this.auth.whoIs();
    if (!this.formGroupMonthlySubscription.invalid) {
      const url = `/users/${this.user.pk}/clients/${this.user.client.pk}/billing_details`;
      const billingDetails = {
        address: {
          line1: this.formGroupMonthlySubscription.get('line1').value,
          line2: this.formGroupMonthlySubscription.get('line2').value,
          postal_code: this.formGroupMonthlySubscription.get('postalCode').value,
          city: this.formGroupMonthlySubscription.get('city').value,
          state: this.formGroupMonthlySubscription.get('state').value,
          country: this.formGroupMonthlySubscription.get('country').value,
        },
      };
      this.api.patch(url, billingDetails).subscribe(() => {
        this.snackbar.success(this.translate.instant('app.panel.billing.component.dataUpdated'));
      });
    }
  }

  billingRegistration(): void {
    const url = `/users/${this.user.pk}/clients/${this.user.client.pk}/billing_registration`;
    if (this.desiredBillingSystem == 'monthlySubscription' && !this.formGroupMonthlySubscription.invalid) {
      this.stripeService.createPaymentMethod({ type: 'card', card: this.card.element }).subscribe((result) => {
        console.log(result);
        if (result.paymentMethod) {
          const payload = {
            address: {
              line1: this.formGroupMonthlySubscription.get('line1').value,
              line2: this.formGroupMonthlySubscription.get('line2').value,
              postal_code: this.formGroupMonthlySubscription.get('postalCode').value,
              city: this.formGroupMonthlySubscription.get('city').value,
              state: this.formGroupMonthlySubscription.get('state').value,
              country: this.formGroupMonthlySubscription.get('country').value,
            },
            payment_method: result.paymentMethod.id,
            billing_system: BillingSystem.Stripe,
          };
          this.api.post(url, payload).subscribe(() => {
            this.isRegistered = true;
            this.billing_system = this.BILLING_SYSTEM_STRIPE;
            this.isLoaded = false;
            this.loadBillingDetails();
            this.snackbar.success(this.translate.instant('app.panel.billing.component.cardRegistered'));
          });
        } else if (result.error) {
          this.snackbar.error(result.error.message);
        }
      });
    } else if (this.desiredBillingSystem == 'customOrder' && !this.formGroupCustomOrder.invalid) {
      this.api
        .post(url, {
          billing_system: BillingSystem.CustomOrderCandidate,
        })
        .subscribe(() => {
          this.billing_system = BillingSystem.CustomOrderCandidate;
          this.isLoaded = false;
          this.loadBillingDetails();
        });
    }
  }

  changeCard(): void {
    const user = this.auth.whoIs();
    this.api.post(`/users/${user.user_id}/change_card`, { lang: this.translate.currentLang }).subscribe(() => {
      this.snackbar.success(this.translate.instant('app.panel.billing.component.mailSent'));
    });
  }

  updateElementsLocale(locale: string): void {
    type elementsLocale = 'auto' | 'da' | 'de' | 'en' | 'es' | 'fi' | 'fr' | 'it' | 'ja' | 'no' | 'nl' | 'sv' | 'zh';
    this.elementsOptions.locale = locale as elementsLocale;
  }

  openDefaultPaymentMethodDialog(): void {
    const selectedPaymentMethodData = this.billingDetails.payment_methods.find(
      (paymentMethod) => paymentMethod.id === this.selectedPaymentMethod,
    );
    if (selectedPaymentMethodData) {
      const dialogRef = this.dialog.open(BillingDefaultPaymentDialog, {
        data: {
          paymentMethod: selectedPaymentMethodData,
          user: this.user,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.snackbar.message(
            this.translate.instant('app.changeCard.component.cardChanged'),
            {},
            {
              duration: 3000,
              panelClass: 'msg-success',
            },
          );
          this.isLoaded = false;
          this.loadBillingDetails();
        }
      });
    }
  }

  selectPaymentMethod(id: string): void {
    switch (this.selectedPaymentMethod) {
      case id:
        this.selectedPaymentMethod = '';
        break;
      default:
        this.selectedPaymentMethod = id;
        break;
    }
  }

  openAddPaymentMethodDialog(): void {
    const dialogRef = this.dialog.open(BillingAddPaymentDialog, {
      width: '440px',
      data: {
        desiredPaymentMethods: this.desiredPaymentMethods,
        user: this.user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackbar.message(
          this.translate.instant('app.panel.billing.addPaymentMethodDialog.newPaymentMethodAdded'),
          {},
          {
            duration: 3000,
            panelClass: 'msg-success',
          },
        );
        this.isLoaded = false;
        this.loadBillingDetails();
      }
    });
  }

  openRemovePaymentMethodDialog(): void {
    const selectedPaymentMethodData = this.billingDetails.payment_methods.find(
      (paymentMethod) => paymentMethod.id === this.selectedPaymentMethod,
    );
    if (selectedPaymentMethodData) {
      const dialogRef = this.dialog.open(BillingRemovePaymentDialog, {
        data: {
          paymentMethod: selectedPaymentMethodData,
          user: this.user,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.snackbar.message(
            this.translate.instant('app.panel.billing.component.removedCard'),
            {},
            {
              duration: 3000,
              panelClass: 'msg-success',
            },
          );
          this.isLoaded = false;
          this.selectedPaymentMethod = '';
          this.loadBillingDetails();
        }
      });
    }
  }
}
