<div class="container">
  <div class="row align-items-center justify-content-center row-container">
    <mat-card class="col-10 col-lg-8">
      <div *ngIf="hasToken">
        <div class="row align-items-center">
          <div class="col-10 offset-1">
            <h3 class="text-center form-label">{{ 'app.changeCard.component.paymentMethodUpdate' | translate }}</h3>
          </div>
        </div>
        <ng-container *ngIf="!cardChanged">
          <div class="row justify-content-center">
            <div class="col-6 stripe-card-container">
              <ngx-stripe-card
                [options]="cardOptions"
                [elementsOptions]="elementsOptions"
              ></ngx-stripe-card>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-10 d-flex justify-content-center">
              <button
                mat-raised-button
                color="accent"
                (click)="changeCard()"
              >
                {{ 'app.changeCard.component.changeCard' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cardChanged">
          <div class="row justify-content-center">
            <div class="col-8 logo d-flex justify-content-center align-items-center">
              <mat-icon class="check-icon text-center">check_circle_outline</mat-icon>
            </div>
          </div>
          <div class="row justify-content-center">
            <p>{{ 'app.changeCard.component.cardChanged' | translate }}</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-8 d-flex justify-content-end">
              <button
                mat-raised-button
                color="accent"
                routerLink="/"
              >
                {{ 'app.global.backToHome' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-card>
  </div>
</div>
