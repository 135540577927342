import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { ApiService } from '../shared/api.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  email = '';
  password = '';
  passwordVisibility = false;
  remember = false;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: SnackbarService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if (loginData) {
      this.email = loginData.email;
      this.remember = loginData.remember;
    }
  }

  logIn(): void {
    if (this.email !== '' && this.password !== '') {
      this.api
        .authenticate({
          email: this.email,
          password: this.password,
        })
        .subscribe(() => {
          localStorage.setItem(
            'loginData',
            JSON.stringify({
              remember: this.remember,
              email: this.remember ? this.email : '',
            }),
          );
          this.route.queryParams.subscribe((queryParams) => {
            this.router.navigate([queryParams['target'] ? queryParams['target'] : 'panel/admin']);
          });
        });
    } else {
      this.snackbar.error(this.translate.instant('error.fillAllFields'));
    }
  }

  togglePasswordVisibility(): void {
    this.passwordVisibility = !this.passwordVisibility;
  }
}
