import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { CustomRequest } from '../custom-request-list.global';
import { CustomRequestService } from '../custom-request.service';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form.submitted);
  }
}

@Component({
  selector: 'custom-request-list-update-dialog',
  styleUrls: ['custom-request-list-update-dialog.component.scss'],
  templateUrl: 'custom-request-list-update-dialog.component.html',
})
export class CustomRequestListUpdateDialogComponent {
  formGroup = new FormGroup(
    {
      comment: new FormControl(this.data.comment),
      organizationName: new FormControl(this.data.organization_name),
    },
    {
      updateOn: 'change',
    },
  );
  errorStateMatcher = new CustomErrorStateMatcher();

  constructor(
    private customRequestService: CustomRequestService,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    public dialogRef: MatDialogRef<CustomRequestListUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomRequest,
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const comment = this.formGroup.get('comment');
    const organizationName = this.formGroup.get('organizationName');

    if (this.formGroup.invalid) {
      return;
    }

    organizationName.setErrors(null);
    comment.setErrors(null);

    this.customRequestService
      .update(this.data.id, {
        comment: comment.value || null,
        organization_name: organizationName.value || '',
      })
      .subscribe(
        (customRequestUpdated: CustomRequest) => {
          this.dialogRef.close({ updated: true, customRequest: customRequestUpdated });
        },
        (httpError: HttpErrorResponse) => {
          for (const error of httpError.error.errors || []) {
            if (error.attr) {
              if (error.attr === 'organization_name' && error.code === 'invalid') {
                organizationName.setErrors({ invalid: true });
              }
              if (error.attr === 'comment' && error.code === 'invalid') {
                comment.setErrors({ invalid: true });
              }
            }
          }
          if (httpError.status === 500) {
            this.snackbar.error(this.translate.instant('error.wrongData'));
          }
        },
      );
  }
}
