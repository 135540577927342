import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as libphonenumber from 'google-libphonenumber';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { CustomValidators } from 'src/app/shared/validators';
import { countries } from 'src/assets/referentials/countries';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form.submitted);
  }
}

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
})
export class CustomerProfileComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  isRegistered = false;
  countries = [];
  passwordStrength = 0;
  confirmPasswordValidity = 0;
  errorStateMatcher = new CustomErrorStateMatcher();
  fieldErrors = {};
  phoneUtil: any;
  PNF: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.countries = [{ code: undefined, label: '' }].concat(countries.sort((a, b) => a.label.localeCompare(b.label)));
  }

  ngOnInit(): void {
    try {
      this.phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      this.PNF = libphonenumber.PhoneNumberFormat;
    } catch (error) {
      console.error(error);
      this.auth.repudiate();
      this.router.navigate(['']);
    }

    this.formGroup = this.formBuilder.group(
      {
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: [{ value: '', disabled: true }, Validators.required],
        job: ['', Validators.required],
        company: ['', Validators.required],
        country: ['', Validators.required],
        phone: [{ value: '', disabled: true }, Validators.required],
        representCompany: [false, Validators.required],
      },
      {
        updateOn: 'change',
        validators: CustomValidators.Form,
      },
    );
    this.checkCountry();
    this.loadCustomerData();
  }

  loadCustomerData(): void {
    const user = this.auth.whoIs();
    this.api.get('/users/' + user.user_id).subscribe(
      (response) => {
        this.isRegistered = response.client.stripe_customer_id ? true : false;
        this.formGroup.get('firstname').setValue(response.client.firstname);
        this.formGroup.get('lastname').setValue(response.client.lastname);
        this.formGroup.get('email').setValue(response.email);
        this.formGroup.get('job').setValue(response.client.job);
        this.formGroup.get('company').setValue(response.client.company);
        this.formGroup.get('representCompany').setValue(!!response.client.company);
        this.formGroup.get('country').setValue(response.client.country);
        try {
          const phoneNum = this.phoneUtil.parseAndKeepRawInput(response.client.phone, response.client.country);
          if (this.phoneUtil.isValidNumber(phoneNum)) {
            this.formGroup.get('phone').setValue(this.phoneUtil.format(phoneNum, this.PNF.E164));
          } else {
            this.formGroup.get('phone').setValue('');
          }
        } catch (error) {
          this.formGroup.get('phone').setValue('');
          console.error(error);
        }
      },
      (_response: HttpErrorResponse) => {
        this.auth.repudiate();
        this.router.navigate(['']);
      },
    );
  }

  checkCountry(): void {
    const country = this.formGroup.get('country').value;

    if (country === '' || country === undefined) {
      this.formGroup.get('phone').disable();
    } else {
      this.formGroup.get('phone').enable();
    }
  }

  getError(fieldName: string): string {
    const field = this.formGroup.get(fieldName);

    if (field.value.length === 0) {
      return this.translate.instant('error.mandatoryField');
    }

    switch (fieldName) {
      case 'phone':
        if (field.hasError('invalid')) {
          return this.translate.instant('error.wrongPhoneForCountry');
        }
        break;
    }

    if (this.fieldErrors[fieldName]) {
      return this.fieldErrors[fieldName];
    }
  }

  clearCompanyFields(): void {
    this.formGroup.get('company').setValue('');
    this.formGroup.get('job').setValue('');
  }

  update(): void {
    const user = this.auth.whoIs();
    try {
      const phoneNum = this.phoneUtil.parseAndKeepRawInput(this.formGroup.get('phone').value, this.formGroup.get('country').value);
      if (this.phoneUtil.isValidNumber(phoneNum)) {
        this.formGroup.get('phone').setValue(this.phoneUtil.format(phoneNum, this.PNF.E164));
      }
    } catch (error) {
      this.formGroup.get('phone').setValue('');
      console.error(error);
    }
    const client = {
      firstname: this.formGroup.get('firstname').value,
      lastname: this.formGroup.get('lastname').value,
      country: this.formGroup.get('country').value,
      phone: this.formGroup.get('phone').value,
    };

    if (this.formGroup.get('representCompany').value) {
      client['company'] = this.formGroup.get('company').value;
      client['job'] = this.formGroup.get('job').value;
    }
    this.api
      .patch('/users/' + user.user_id, {
        client: client,
        represent_company: this.formGroup.get('representCompany').value,
      })
      .subscribe(
        (_response) => {
          this.snackbar.success(this.translate.instant('app.panel.customerProfile.component.dataUpdated'));
        },
        (response: HttpErrorResponse) => {
          if (response.error && response.error.errors) {
            for (const error of response.error.errors) {
              if (error.field) {
                this.fieldErrors[error.field] = error.message;
                this.formGroup.get(error.field).setErrors({ invalid: true });
              }
            }
          }
        },
      );
  }

  changePassword(): void {
    const user = this.auth.whoIs();
    this.api
      .post('/users/change_password', {
        email: user.email,
        lang: this.translate.currentLang,
      })
      .subscribe(() => {
        this.snackbar.success(this.translate.instant('app.panel.customerProfile.component.mailSent'));
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CustomerProfileEmailDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackbar.message(
          this.translate.instant('app.panel.customerProfile.component.emailUpdated'),
          {},
          {
            duration: 3000,
            panelClass: 'msg-success',
          },
        );
        this.loadCustomerData();
      }
    });
  }
}

@Component({
  selector: 'customer-profile-email-dialog',
  templateUrl: 'customer-profile-email-dialog.html',
})
export class CustomerProfileEmailDialog implements OnInit {
  formGroup = new UntypedFormGroup({});
  errorStateMatcher = new CustomErrorStateMatcher();

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CustomerProfileEmailDialog>,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      {
        newEmail: ['', Validators.required],
        password: ['', CustomValidators.Password],
      },
      {
        updateOn: 'change',
        validators: CustomValidators.Form,
      },
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const newEmail = this.formGroup.get('newEmail').value;
    const password = this.formGroup.get('password').value;

    if (newEmail && password) {
      const user = this.auth.whoIs();
      this.api
        .post(`/users/${user.user_id}/update_email`, {
          email: newEmail,
          password: password,
          lang: this.translate.currentLang,
        })
        .subscribe(
          () => {
            this.dialogRef.close({ email: newEmail });
          },
          (error) => {
            if (error.status === 500) {
              this.snackbar.error(this.translate.instant('error.wrongData'));
            }
          },
        );
    }
  }
}
