// angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChangeCardComponent } from './change-card/change-card.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HelpComponent } from './help/help.component';
import { MainComponent } from './main/main.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AdminComponent } from './panel/admin/admin.component';
import { BillingComponent } from './panel/billing/billing.component';
import { CustomerProfileComponent } from './panel/customer-profile/customer-profile.component';
import { NotificationLogComponent } from './panel/notification/notification-log.component';
import { OrganizationComponent } from './panel/organization/organization.component';
import { PanelComponent } from './panel/panel.component';
import { ResendCreationLinkComponent } from './resend-creation-link/resend-creation-link.component';
import { AuthGuardService } from './shared/auth-guard.service';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { ValidateComponent } from './validate/validate.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: '', component: MainComponent },
        { path: 'not-found', component: NotFoundComponent },
        { path: 'panel', component: PanelComponent, canActivate: [AuthGuardService] },
        { path: 'panel/admin', component: AdminComponent, canActivate: [AuthGuardService], data: { staffOnly: true } },
        { path: 'panel/admin/:tab', component: AdminComponent, canActivate: [AuthGuardService], data: { staffOnly: true } },
        { path: 'panel/admin/:tab/:question', component: AdminComponent, canActivate: [AuthGuardService], data: { staffOnly: true } },
        { path: 'panel/profile', component: CustomerProfileComponent, canActivate: [AuthGuardService] },
        { path: 'panel/organization/list', component: OrganizationComponent, canActivate: [AuthGuardService] },
        { path: 'panel/organization/list/:id', component: OrganizationComponent, canActivate: [AuthGuardService] },
        { path: 'panel/billing', component: BillingComponent, canActivate: [AuthGuardService] },
        { path: 'panel/billing/:subtab', component: BillingComponent, canActivate: [AuthGuardService] },
        { path: 'panel/notifications', component: NotificationLogComponent, canActivate: [AuthGuardService] },
        { path: 'subscribe/:promiseAccountId/:token', component: SubscribeComponent },
        { path: 'validate/:userId/:token', component: ValidateComponent },
        { path: 'change-password', component: ChangePasswordComponent },
        { path: 'change-password/:userId/:token', component: ChangePasswordComponent },
        { path: 'change-card/:userId/:token', component: ChangeCardComponent },
        { path: 'resend-creation-link', component: ResendCreationLinkComponent },
        { path: 'feedback', component: FeedbackComponent },
        { path: 'help', component: HelpComponent },
        { path: 'help/:question', component: HelpComponent },
        {
          path: 'assets/termsandconditions.pdf',
          loadChildren: () =>
            new Promise(() => {
              window.location.href = 'https://parsec.cloud/cgvu';
            }),
        },
        {
          path: 'assets/privacypolicy.pdf',
          loadChildren: () =>
            new Promise(() => {
              window.location.href = 'https://parsec.cloud/privacy';
            }),
        },
        { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
      ],
      {},
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
