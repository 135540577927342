import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification.service';
import { AuthService } from '../shared/auth.service';

export interface Link {
  name: string;
  url: string;
  visibleOn: string[];
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  currentUrl = '';
  links = [];
  isShown = false;
  isAuthenticated = false;
  @ViewChild('notificationCenterToggler') notificationCenterToggler: ElementRef;
  @ViewChild('notificationCenter') notificationCenter: ElementRef;

  constructor(
    private router: Router,
    private auth: AuthService,
    public notification: NotificationService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.isAuthenticated = this.auth.isAuthenticated();
    this.links = [
      // {
      //   name: 'app.global.navbar.home',
      //   url: '/panel',
      //   visibleOn: [
      //     '/help',
      //     '/feedback',
      //     '/panel',
      //     '/panel/profile',
      //     '/panel/organization/list',
      //     '/panel/billing',
      //     '/panel/billing/invoices',
      //     '/panel/admin',
      //     '/panel/admin/?',
      //     '/panel/notifications',
      //   ],
      // },
      // {
      //   name: 'app.global.navbar.profile',
      //   url: '/panel/profile',
      //   visibleOn: [
      //     '/help',
      //     '/feedback',
      //     '/panel',
      //     '/panel/profile',
      //     '/panel/organization/list',
      //     '/panel/billing',
      //     '/panel/billing/invoices',
      //     '/panel/admin',
      //     '/panel/admin/?',
      //     '/panel/notifications',
      //   ],
      // },
      // {
      //   name: 'app.global.navbar.organizations',
      //   url: '/panel/organization/list',
      //   visibleOn: [
      //     '/help',
      //     '/feedback',
      //     '/panel',
      //     '/panel/profile',
      //     '/panel/organization/list',
      //     '/panel/billing',
      //     '/panel/billing/invoices',
      //     '/panel/admin',
      //     '/panel/admin/?',
      //     '/panel/notifications',
      //   ],
      // },
      // {
      //   name: 'app.global.navbar.billing',
      //   url: '/panel/billing',
      //   visibleOn: [
      //     '/help',
      //     '/feedback',
      //     '/panel',
      //     '/panel/profile',
      //     '/panel/organization/list',
      //     '/panel/billing',
      //     '/panel/billing/invoices',
      //     '/panel/admin',
      //     '/panel/admin/?',
      //     '/panel/notifications',
      //   ],
      // },
      {
        name: 'app.global.navbar.administration',
        url: '/panel/admin',
        visibleOn: [
          '/help',
          '/feedback',
          '/panel',
          '/panel/profile',
          '/panel/organization/list',
          '/panel/billing',
          '/panel/billing/invoices',
          '/panel/admin',
          '/panel/admin/?',
          '/panel/notifications',
        ],
      },
    ];
    if (this.isAuthenticated) {
      this.notification.update({
        reads: { enable: false },
      });
    }
    this.currentUrl = this.router.url;
    this.checkRoute();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url;
        this.checkRoute();
      }
    });
  }

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (
        this.notificationCenter &&
        event.target !== this.notificationCenterToggler.nativeElement &&
        event.target !== this.notificationCenter.nativeElement
      ) {
        this.notification.hide();
        event.stopImmediatePropagation();
      }
    });
  }

  checkRoute(): void {
    if (this.currentUrl.startsWith('/panel/organization/') && !this.currentUrl.endsWith('list')) {
      this.links.map((link) => {
        link.visibleOn.push(this.currentUrl);
      });
    }
    const user = this.auth.whoIs();
    if (user.is_staff === false) {
      this.links.map((link) => {
        if (link.url === '/panel/admin') {
          link.visibleOn = [];
        }
      });
    }
  }

  isLinkVisibleOnCurrentUrl(link: any): boolean {
    for (const visibleOnLink of link.visibleOn) {
      if (visibleOnLink.endsWith('/?') && this.currentUrl.startsWith(visibleOnLink.substring(0, visibleOnLink.length - 2))) {
        return true;
      }
      if (this.currentUrl === visibleOnLink) {
        return true;
      }
    }
    return false;
  }

  logOut(): void {
    this.auth.repudiate();
    this.router.navigate(['']);
  }

  toggleNotificationCenter(event: MouseEvent): void {
    this.notification.toggleVisibility();
    event.stopPropagation();
  }
}
