export interface Formula {
  id: string;
  title: string;
  subtitle: string;
  active: boolean;
  users: {
    title: string;
    free: string;
    paying: string;
  };
  storage: {
    title: string;
    free: string;
    paying: string;
  };
  details: {
    icon: string;
    text: string;
  }[];
}

export const formulas: Formula[] = [
  {
    id: 'standard',
    title: 'referentials.formulas.title.standard',
    subtitle: 'Saas',
    active: true,
    users: {
      title: '5 €',
      free: 'referentials.formulas.users.free',
      paying: 'referentials.formulas.users.paying',
    },
    storage: {
      title: '5 €',
      free: 'referentials.formulas.storage.free',
      paying: 'referentials.formulas.storage.paying',
    },
    details: [
      {
        icon: 'folder_shared',
        text: 'referentials.formulas.details.sharingTool',
      },
      {
        icon: 'do_disturb_alt',
        text: 'referentials.formulas.details.zeroTrust',
      },
      {
        icon: 'settings',
        text: 'referentials.formulas.details.fileManagement',
      },
    ],
  },
];
