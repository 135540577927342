<app-navbar></app-navbar>
<div class="container">
  <ng-container *ngIf="user?.is_staff; else notAStaff">
    <div class="row is-staff align-items-center justify-content-center text-center">
      <!-- <mat-card class="col-8 col-md-4">
        <a routerLink="/panel/profile">
          <mat-icon>person</mat-icon>
          <h4>{{ 'app.global.navbar.profile' | translate }}</h4>
        </a>
      </mat-card>
      <mat-card class="col-8 col-md-4 offset-md-1">
        <a routerLink="/panel/organization/list">
          <mat-icon>business_center</mat-icon>
          <h4>{{ 'app.global.navbar.organizations' | translate }}</h4>
        </a>
      </mat-card>
      <mat-card class="col-8 col-md-4">
        <a routerLink="/panel/billing">
          <mat-icon>euro</mat-icon>
          <h4>{{ 'app.global.navbar.billing' | translate }}</h4>
        </a>
      </mat-card> -->
      <mat-card class="col-8 col-md-4 offset-md-1">
        <a routerLink="/panel/admin">
          <mat-icon>admin_panel_settings</mat-icon>
          <h4>{{ 'app.global.navbar.administration' | translate }}</h4>
        </a>
      </mat-card>
    </div>
  </ng-container>
  <ng-template #notAStaff>
    <div class="row align-items-center justify-content-center text-center">
      <mat-card class="col-8 col-md-3">
        <a routerLink="/panel/profile">
          <mat-icon>person</mat-icon>
          <h4>{{ 'app.global.navbar.profile' | translate }}</h4>
        </a>
      </mat-card>
      <mat-card class="col-8 col-md-3 offset-md-1">
        <a routerLink="/panel/organization/list">
          <mat-icon>business_center</mat-icon>
          <h4>{{ 'app.global.navbar.organizations' | translate }}</h4>
        </a>
      </mat-card>
      <mat-card class="col-8 col-md-3 offset-md-1">
        <a routerLink="/panel/billing">
          <mat-icon>euro</mat-icon>
          <h4>{{ 'app.global.navbar.billing' | translate }}</h4>
        </a>
      </mat-card>
    </div>
  </ng-template>
</div>
