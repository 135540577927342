<mat-card>
  <mat-nav-list>
    <ng-container *ngFor="let notif of notifs">
      <a
        [routerLink]="notif.link"
        (click)="toggleVisibility(); notification.updateReadStatus(notif.pk)"
        *ngIf="notif.isUnread"
        mat-list-item
      >
        <mat-icon matListIcon>{{ icons[notif.type] }}</mat-icon>
        <span
          mat-line
          class="font-weight-bold"
          >{{ notif.message.code | translate: notif.message.interpolateParams }}</span
        >
        <span
          mat-line
          class="font-italic"
          >{{ date.getLocaleString(notif.date) }}</span
        >
        <button
          mat-icon-button
          (click)="notification.updateReadStatus(notif.pk); $event.preventDefault(); $event.stopPropagation()"
        >
          <mat-icon>done</mat-icon>
        </button>
      </a>
    </ng-container>
  </mat-nav-list>
  <ng-container *ngIf="notification.countUnreads() === 0">
    <div class="nothing-new d-flex justify-content-center">
      <span>{{ 'referentials.notifications.nothingNew' | translate }}</span>
    </div>
  </ng-container>
  <div class="go-to-list d-flex justify-content-between align-items-center">
    <a routerLink="/panel/notifications">
      <span>{{ 'referentials.notifications.goToList' | translate }}</span>
    </a>
    <a
      mat-icon-button
      (click)="notification.updateReadStatus()"
    >
      <mat-icon>playlist_add_check</mat-icon>
    </a>
  </div>
</mat-card>
