import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { DateService } from 'src/app/shared/date.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { Filesize, FileSizeUnits, isEmptyObject } from 'src/app/shared/tools';
import { formulas } from 'src/assets/referentials/formulas';
import {
  BillingSystem,
  CustomFieldCodes,
  CustomOrderStatus,
  SellsyBillingUnitReference,
  sellsyReferenceToOrganizationInfoType,
} from '../admin/admin.global';

export interface InvoiceElement {
  name: string;
  quantity: string;
  unitAmount: number | string;
  taxAmount: number | string;
  totalAmount: string;
}

export interface OrganizationInfo {
  status: object;
  stats: object;
  loaded: boolean;
}

export interface CustomOrderDetails {
  invoiceNumber: string;
  startDate: string;
  endDate: string;
  link: string;
  amount: string;
  licenses: {
    STANDARD: number;
    ADMIN: number;
    OUTSIDER: number;
    STORAGE: number;
  };
  rows: { string: InvoiceElement } | Record<string, never>;
  loaded: boolean;
}

export interface CustomOrderDates {
  startDate: Date;
  endDate: Date;
}

export interface CustomOrderLimits {
  STANDARD: string;
  ADMIN: string;
  OUTSIDER: string;
  STORAGE: string;
  END_DATE: string;
}

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
  public readonly BillingSystem: typeof BillingSystem = BillingSystem;
  public readonly CustomOrderStatus: typeof CustomOrderStatus = CustomOrderStatus;
  public readonly isEmptyObject = isEmptyObject;
  organizations = [];
  orgViewSet;
  selectedOrganization;
  pageIndex = 0;
  formulas = [];
  isRegistered = false;
  orgUrl = '';
  user;
  organizationsInfo: { [organization_id: string]: OrganizationInfo } = {};
  customOrderDetails: { [organization_id: string]: CustomOrderDetails | Record<string, never> } = {};
  customOrderDates: { [organization_id: string]: CustomOrderDates } = {};
  customOrderStatus = {};
  customOrderLimits: { [organization_id: string]: CustomOrderLimits } = {};
  customOrderStatusList = [
    {
      key: CustomOrderStatus.NothingLinked,
      color: 'primary',
    },
    {
      key: CustomOrderStatus.EstimateLinked,
      color: 'info',
    },
    {
      key: CustomOrderStatus.InvoiceToBePaid,
      color: 'warning',
    },
    {
      key: CustomOrderStatus.InvoicePaid,
      color: 'success',
    },
    {
      key: CustomOrderStatus.ContractEnded,
      color: 'danger',
    },
    {
      key: CustomOrderStatus.Unknown,
      color: 'danger',
    },
  ];
  organizationsLoaded = false;
  invoiceElementsDisplayedColumns: string[] = ['name', 'quantity', 'unitAmount', 'taxAmount', 'totalAmount'];
  invoiceElementsDataSources: { [organization_id: string]: InvoiceElement[] } = {};
  contractRemainingDurationAlertLimit = 30; // in days
  contractUsageDeviationLimit = 0.25; // percentage
  billingSystem: BillingSystem;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public date: DateService,
  ) {
    this.formulas = formulas;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const user = this.auth.whoIs();
      this.api.get('/users/' + user.user_id).subscribe(
        (response) => {
          this.user = response;
          this.isRegistered = response.client.stripe_customer_id ? true : false;
          this.orgUrl = `/users/${this.user.pk}/clients/${this.user.client.pk}/organizations`;
          this.billingSystem = response.client.billing_system;
          this.loadOrganizations(params.get('id'));
        },
        (_response: HttpErrorResponse) => {
          this.auth.repudiate();
          this.router.navigate(['']);
        },
      );
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.loadOrganizations();
  }

  loadOrganizations(organizationId = ''): void {
    this.selectedOrganization = null;
    let url = this.orgUrl;
    if (organizationId) {
      url = `${url}/${organizationId}`;
    } else {
      url = `${url}?page=${this.pageIndex + 1}`;
    }
    this.api.get(url).subscribe((organizations) => {
      let results;
      if (organizationId) {
        results = [organizations];
        this.selectedOrganization = organizations;
      } else {
        results = organizations.results;
        this.orgViewSet = organizations;
      }

      this.organizations = results.map((result) => {
        const timeDiff = Math.abs(new Date(result.created_at).getTime() - new Date(result.expiration_date).getTime());
        result.expiration_time = Math.ceil(timeDiff / (1000 * 3600 * 24));
        this.retrieveOrganizationInfo(result);
        return result;
      });
      this.retrieveCustomOrderStatus();
      this.retrieveCustomOrderDetails();
      this.organizationsLoaded = true;
    });
  }

  retrieveCustomOrderStatus(): any {
    const url = `${this.orgUrl}/custom_order_status`;
    this.api
      .post(url, {
        organization_ids: this.organizations.map(({ pk }) => pk),
      })
      .subscribe((custom_order_status) => {
        this.customOrderStatus = custom_order_status;
      });
  }

  retrieveOrganizationInfo(organization: any): any {
    const url = `${this.orgUrl}/${organization.pk}`;
    const formattedOrganizationInfo: OrganizationInfo = { status: {}, stats: {}, loaded: false };
    this.api.get(url + '/status').subscribe((status) => {
      formattedOrganizationInfo.status = status;
      this.api.get(url + '/stats').subscribe((stats) => {
        if (!isEmptyObject(stats)) {
          formattedOrganizationInfo.stats = {
            users: {
              total: { active: stats.active_users, revoked: stats.users - stats.active_users },
            },
            dataSize: stats.data_size,
            metadataSize: stats.metadata_size,
            readableDataSize: Filesize.toReadable(stats.data_size),
            readableMetadataSize: Filesize.toReadable(stats.metadata_size),
          };
          if ('users_per_profile_detail' in stats) {
            for (const detail of stats.users_per_profile_detail) {
              formattedOrganizationInfo.stats['users'][detail.profile] = { active: detail.active, revoked: detail.revoked };
            }
          }
        }
        formattedOrganizationInfo.loaded = true;
        this.organizationsInfo[organization.pk] = formattedOrganizationInfo;
      });
    });
  }

  async retrieveCustomOrderDetails(): Promise<any> {
    const url = `${this.orgUrl}/custom_order_details`;
    this.api
      .post(url, {
        organization_ids: this.organizations.map((organization) => {
          this.customOrderDetails[organization.pk] = {};
          return organization.pk;
        }),
      })
      .subscribe((response) => {
        for (const parsec_id in response) {
          const customOrderDetails = response[parsec_id];
          let formattedCustomOrderDetails: CustomOrderDetails;
          if (!isEmptyObject(customOrderDetails)) {
            const organization = this.organizations.find((org) => org.parsec_id === parsec_id);
            const startDate = this.getCustomFieldValue(CustomFieldCodes.StartDate, customOrderDetails) as Date;
            const endDate = this.getCustomFieldValue(CustomFieldCodes.EndDate, customOrderDetails) as Date;
            this.customOrderDates[organization.pk] = {
              startDate,
              endDate,
            };
            formattedCustomOrderDetails = {
              invoiceNumber: customOrderDetails['number'] ?? '',
              startDate: startDate ? startDate.toLocaleDateString() : '',
              endDate: endDate ? endDate.toLocaleDateString() : '',
              link: customOrderDetails['public_link']['url'] ?? '',
              amount: `${customOrderDetails['amounts']['total_incl_tax']} ${customOrderDetails['currency']}`,
              licenses: {
                STANDARD: this.getCustomFieldValue(CustomFieldCodes.StandardLicenseCount, customOrderDetails) as number,
                ADMIN: this.getCustomFieldValue(CustomFieldCodes.AdminLicenseCount, customOrderDetails) as number,
                OUTSIDER: this.getCustomFieldValue(CustomFieldCodes.OutsiderLicenseCount, customOrderDetails) as number,
                STORAGE: this.getCustomFieldValue(CustomFieldCodes.StorageLicenseCount, customOrderDetails) as number,
              },
              rows: this.getCustomOrderRows(customOrderDetails),
              loaded: true,
            };
            this.customOrderDetails[organization.pk] = formattedCustomOrderDetails;
            this.customOrderLimits[organization.pk] = {
              STANDARD: '',
              ADMIN: '',
              OUTSIDER: '',
              STORAGE: '',
              END_DATE: '',
            };
            this.invoiceElementsDataSources[organization.pk] = Object.values(formattedCustomOrderDetails.rows);
            this.manageCustomOrderLimits();
          }
        }
      });
  }

  getCustomFieldValue(customFieldCode: CustomFieldCodes, customOrderDetails: object): string | number | Date | null {
    if (!customOrderDetails || !Object.keys(customOrderDetails).includes('_embed')) {
      return null;
    }
    const customFields = customOrderDetails['_embed']['custom_fields'];
    const customField = customFields.find((cField) => cField.code === customFieldCode);
    switch (customFieldCode) {
      case CustomFieldCodes.StartDate:
      case CustomFieldCodes.EndDate:
        return customField['value'] ? new Date(customField['value']) : null;
      case CustomFieldCodes.OrganizationId:
        return customField['value'];
      case CustomFieldCodes.AdminLicenseCount:
      case CustomFieldCodes.OutsiderLicenseCount:
      case CustomFieldCodes.StandardLicenseCount:
      case CustomFieldCodes.StorageLicenseCount:
        return Number(customField['value']);
      default:
        return null;
    }
  }

  getCustomOrderRows(customOrderDetails: object): { string: InvoiceElement } | Record<string, never> {
    const customOrderRows = customOrderDetails['rows'] ?? [];
    const rows = {};
    for (const customOrderRow of customOrderRows) {
      if (Object.values(SellsyBillingUnitReference).includes(customOrderRow['reference'])) {
        const invoiceElementName = sellsyReferenceToOrganizationInfoType(customOrderRow['reference']);
        const row: InvoiceElement = {
          name: this.translate.instant(`app.panel.organization.component.invoiceElements.${invoiceElementName}`),
          quantity: customOrderRow['quantity'] ?? '0',
          unitAmount: customOrderRow['unit_amount'] ?? '0',
          taxAmount: customOrderRow['tax_amount'] ?? '0',
          totalAmount: customOrderRow['amount_tax_inc'] ?? '0',
        };
        rows[invoiceElementName] = row;
      }
    }
    return rows;
  }

  manageCustomOrderLimits(): void {
    for (const organization of this.organizations) {
      const customOrderDetails: CustomOrderDetails = this.customOrderDetails[organization.pk] as CustomOrderDetails;
      const customOrderLimits: CustomOrderLimits = this.customOrderLimits[organization.pk];
      if (!isEmptyObject(customOrderDetails) && !isEmptyObject(customOrderLimits)) {
        const endDate = this.customOrderDates[organization.pk].endDate;
        for (const customOrderLimitType of Object.keys(customOrderDetails.licenses)) {
          customOrderLimits[customOrderLimitType] = this.checkCustomOrderLimit(customOrderLimitType, organization);
        }
        const nowTime = new Date().getTime();
        if (endDate) {
          const alertDate = new Date(endDate);
          alertDate.setDate(alertDate.getDate() - this.contractRemainingDurationAlertLimit);
          if (nowTime > endDate.getTime()) {
            customOrderLimits['END_DATE'] = 'error';
          }
          if (nowTime > alertDate.getTime() && nowTime <= endDate.getTime()) {
            customOrderLimits['END_DATE'] = 'warning';
          }
        }
      }
    }
  }

  checkCustomOrderLimit(type: string, organization: any): string {
    if (this.billingSystem === BillingSystem.CustomOrder) {
      const customOrderDetails = this.customOrderDetails[organization.pk];
      const organizationInfo = this.organizationsInfo[organization.pk];
      if (!organizationInfo || isEmptyObject(organizationInfo.stats)) {
        return;
      }

      if (type in customOrderDetails['licenses']) {
        let actualValue, customOrderValue;
        switch (type) {
          case 'STORAGE':
            actualValue = organizationInfo.stats['dataSize'] + organizationInfo.stats['metadataSize'];
            customOrderValue = Number(customOrderDetails['licenses'][type]) * 100; // we multiply by 100 cause the value is slices of 100 GB
            customOrderValue = Filesize.toUnit(customOrderValue, FileSizeUnits.Gigabyte, FileSizeUnits.Byte);
            break;
          case 'STANDARD':
          case 'ADMIN':
          case 'OUTSIDER':
            actualValue = organizationInfo.stats['users'][type]['active'];
            customOrderValue = Number(customOrderDetails['licenses'][type]);
            break;
        }
        if (actualValue > customOrderValue * (1 + this.contractUsageDeviationLimit)) {
          return 'error';
        } else if (actualValue > customOrderValue && actualValue <= customOrderValue * (1 + this.contractUsageDeviationLimit)) {
          return 'warning';
        } else if (actualValue <= customOrderValue) {
          return '';
        }
        return '';
      }
    }
    return '';
  }

  getCustomOrderLimitValue(organization: any, customOrderLimit: string): string {
    if (this.customOrderLimits[organization.pk]) {
      return this.customOrderLimits[organization.pk][customOrderLimit];
    }
    return '';
  }

  isCustomOrderLimitValue(organization: any, customOrderLimit: string, value: string): boolean {
    if (this.getCustomOrderLimitValue(organization, customOrderLimit)) {
      return this.getCustomOrderLimitValue(organization, customOrderLimit) === value;
    }
    return false;
  }

  isOrganizationLoaded(organization: any): boolean {
    let loaded = false;
    loaded =
      Object.keys(this.organizationsInfo).includes(organization.pk) &&
      this.organizationsInfo[organization.pk] &&
      this.organizationsInfo[organization.pk].loaded;
    if (this.billingSystem === BillingSystem.CustomOrder) {
      loaded =
        loaded && Object.keys(this.customOrderStatus).includes(organization.parsec_id) && this.customOrderStatus[organization.parsec_id];
      if (loaded) {
        if (this.customOrderStatus[organization.parsec_id] !== CustomOrderStatus.NothingLinked) {
          loaded = loaded && this.customOrderDetails[organization.pk]['loaded'];
        } else {
          loaded = true;
        }
      }
    }
    return loaded;
  }

  getOrganizationStatus(status: any): string {
    if (status) {
      if (status.is_frozen) {
        return this.translate.instant('app.panel.organization.component.status.frozen');
      }
      return this.translate.instant('app.panel.organization.component.status.active');
    }
    return '';
  }

  getBillingSystemLabel(): string {
    let label = '';
    label = this.billingSystem
      ? this.translate.instant(`app.panel.admin.billingSystemLabels.${this.billingSystem}`)
      : this.translate.instant(`app.panel.admin.billingSystemLabels.NONE`);
    return label;
  }

  getBillingSystemTooltip(organization: any): string {
    const tooltipLines = [];
    const billingSystemLine = this.billingSystem
      ? this.translate.instant(`app.panel.admin.billingSystemTooltips.${this.billingSystem}`)
      : this.translate.instant(`app.panel.admin.billingSystemTooltips.NONE`);
    tooltipLines.push(billingSystemLine);
    if (this.billingSystem === BillingSystem.CustomOrder) {
      const customOrderStatus = this.customOrderStatus[organization.parsec_id];
      const customOrderStatusLine = customOrderStatus
        ? this.translate.instant(`app.panel.admin.customOrderStatusTooltips.${customOrderStatus}`)
        : this.translate.instant(`app.panel.admin.customOrderStatusTooltips.nothing_linked`);
      tooltipLines.push(customOrderStatusLine);
    }
    return tooltipLines.join('\n');
  }

  getCustomOrderStatusLabel(customOrderStatus: string): string {
    let label = '';
    label = customOrderStatus
      ? this.translate.instant(`app.panel.admin.customOrderStatusLabels.${customOrderStatus}`)
      : this.translate.instant(`app.panel.admin.customOrderStatusLabels.nothing_linked`);
    return label;
  }

  getCustomOrderStatusColor(customOrderStatus: string): string {
    const customOrderStatusObj = this.customOrderStatusList.find((obj) => obj.key === customOrderStatus);
    return customOrderStatusObj ? customOrderStatusObj.color : 'info';
  }

  getExpirationDateString(billingSystem: string, expirationDate: string): string {
    let expirationDateString = '';

    if (billingSystem === BillingSystem.ExperimentalCandidate) {
      if (expirationDate) {
        const prefix = this.translate.instant(`app.panel.admin.clientList.component.expirationDatePrefix`);
        expirationDateString = `${prefix} ${new Date(expirationDate).toLocaleDateString(this.translate.currentLang)}`;
      } else {
        expirationDateString = this.translate.instant(`app.panel.admin.clientList.component.noExpirationDate`);
      }
    }
    return expirationDateString;
  }

  unsubscribe(organization: any): void {
    const dialogRef = this.dialog.open(OrganizationUnsubscribeDialog, {
      data: {
        user: this.user,
        organization: organization,
      },
      maxWidth: '60vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackbar.message(
          this.translate.instant('app.panel.organization.component.organizationUnsubscribed'),
          {},
          {
            duration: 3000,
            panelClass: 'msg-success',
          },
        );
        this.loadOrganizations(this.selectedOrganization ? this.selectedOrganization.pk : '');
      }
    });
  }
}

@Component({
  selector: 'organization-unsubscribe-dialog',
  templateUrl: 'organization-unsubscribe-dialog.html',
})
export class OrganizationUnsubscribeDialog implements OnInit {
  constructor(
    private api: ApiService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<OrganizationUnsubscribeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const url = `/users/${this.data.user.pk}/clients/${this.data.user.client.pk}/organizations/${this.data.organization.pk}/unsubscribe`;
    this.api.post(url, {}).subscribe(
      () => {
        this.dialogRef.close({ organization: this.data.organization });
      },
      (error) => {
        this.dialogRef.close();
        if (error.status === 500) {
          this.snackbar.error(this.translate.instant('error.wrongData'));
        }
      },
    );
  }
}
