<h1
  mat-dialog-title
  class="row font-weight-bold"
>
  {{'app.panel.organization.unsubscribeDialog.title' | translate}} {{data.organization.suffix}}
</h1>
<div mat-dialog-content>
  <div class="row justify-content-center">
    <p>{{'app.panel.organization.unsubscribeDialog.stopPaying' | translate}}</p>
    <p>{{'app.panel.organization.unsubscribeDialog.consequences' | translate}}</p>
  </div>
</div>
<div
  mat-dialog-actions
  class="row justify-content-end"
>
  <button
    mat-raised-button
    (click)="cancel()"
  >
    {{'app.panel.organization.unsubscribeDialog.cancel' | translate}}
  </button>
  <button
    mat-raised-button
    color="accent"
    (click)="submit()"
    cdkFocusInitial
  >
    {{'app.panel.organization.unsubscribeDialog.submit' | translate}}
  </button>
</div>
