export class JWT {
  public static decode(token: string) {
    if (token !== undefined) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(window.atob(base64));
    }
    return token;
  }

  public static isExpired(token: string) {
    const exp = this.decode(token).exp;
    return Date.now() / 1000 > exp;
  }
}

export class Cookie {
  public static get(key: string): string | null {
    if (!key) {
      return null;
    }
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const keyValue = cookie.trim().split('=');
      if (keyValue[0] === key) {
        return keyValue[1];
      }
    }
    return null;
  }

  public static set(key: string, value: string, maxAge: number | null = null): void {
    let expires = '';
    if (maxAge) {
      expires = `; max-age: ${maxAge}`;
    }
    document.cookie = `${key}=${value}${expires}`;
  }

  public static delete(key: string): void {
    if (!key) {
      return;
    }
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

export enum FileSizeUnits {
  Byte = 'B',
  Kilobyte = 'KB',
  Megabyte = 'MB',
  Gigabyte = 'GB',
  Terabyte = 'TB',
  Petabyte = 'PB',
  Exabyte = 'EB',
  Zettabyte = 'ZB',
  Yottabyte = 'YB',
}

const FILE_SIZE_UNITS: FileSizeUnits[] = [
  FileSizeUnits.Byte,
  FileSizeUnits.Kilobyte,
  FileSizeUnits.Megabyte,
  FileSizeUnits.Gigabyte,
  FileSizeUnits.Terabyte,
  FileSizeUnits.Petabyte,
  FileSizeUnits.Exabyte,
  FileSizeUnits.Zettabyte,
  FileSizeUnits.Yottabyte,
];

export class Filesize {
  public static toReadable(value: number): string {
    const neg = value < 0;

    if (neg) {
      value = -value;
    }
    if (value < 1) {
      return (neg ? '-' : '') + value + ' B';
    }

    const exponent = Math.min(Math.floor(Math.log(value) / Math.log(1000)), FILE_SIZE_UNITS.length - 1);
    value = Number((value / Math.pow(1000, exponent)).toFixed(2));
    const unit = FILE_SIZE_UNITS[exponent];
    return (neg ? '-' : '') + value + ' ' + unit;
  }

  public static toUnit(value: number, from_unit: FileSizeUnits, to_unit: FileSizeUnits): number {
    if (!FILE_SIZE_UNITS.includes(from_unit) || !FILE_SIZE_UNITS.includes(to_unit)) {
      throw new Error(`Unknown unit: ${from_unit} or ${to_unit}`);
    }
    let unit_diff = FILE_SIZE_UNITS.indexOf(from_unit) - FILE_SIZE_UNITS.indexOf(to_unit);
    if (unit_diff < 0) {
      unit_diff = -unit_diff;
      return Math.round((value / Math.pow(1024, unit_diff)) * 100) / 100;
    } else {
      return Math.round(value * Math.pow(1024, unit_diff) * 100) / 100;
    }
  }
}

export function isEmptyObject(object: any): boolean {
  return Object.keys(object).length === 0;
}

export function isEmptyOrNullObject(object: any): boolean {
  return object === null || isEmptyObject(object);
}
