import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  started = false;

  constructor() {}

  reset(): void {
    this.started = false;
  }

  start(): void {
    this.started = true;
  }

  set(key: string, value: any): void {
    const stepperData = this._getData();
    stepperData[key] = value;
    localStorage.setItem('stepperData', JSON.stringify(stepperData));
  }

  get(key: string): any {
    const stepperData = this._getData();
    return stepperData[key];
  }

  clear(): void {
    localStorage.removeItem('stepperData');
  }

  private _getData(): any {
    const data = localStorage.getItem('stepperData');
    return data !== null ? JSON.parse(data) : {};
  }
}
