<app-navbar></app-navbar>
<div class="container organization">
  <mat-button-toggle-group
    [(ngModel)]="selectedTab"
    (change)="selectTab($event.value)"
  >
    <mat-button-toggle value="clients">
      <mat-icon>people</mat-icon>
      {{ 'app.panel.admin.component.clients' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="organizations">
      <mat-icon>business_center</mat-icon>
      {{ 'app.panel.admin.component.organizations' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="custom-requests">
      <mat-icon>request_quote</mat-icon>
      {{ 'app.panel.admin.component.customRequests' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="extract-invoices">
      <mat-icon>receipt</mat-icon>
      {{ 'app.panel.admin.component.extractInvoices' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="help">
      <mat-icon>help</mat-icon>
      {{ 'app.panel.admin.component.help' | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-chip-list *ngIf="['clients', 'organizations'].includes(selectedTab)">
    <mat-chip
      (removed)="removeSelectedClients()"
      class="font-weight-bold"
      *ngIf="selectedClients && selectedClients.length > 0"
    >
      <span *ngIf="selectedClients.length === 1">{{ selectedClients?.at(0).name }}</span>
      <span *ngIf="selectedClients.length > 1">{{
        'app.panel.admin.component.multipleSelectedClients' | translate: { count: selectedClients.length }
      }}</span>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <app-client-list
    *ngIf="selectedTab === 'clients'"
    (goToOrganizations)="goToOrganizations($event)"
    [client]="selectedClients"
  >
  </app-client-list>
  <app-organization-list
    *ngIf="selectedTab === 'organizations'"
    (goToClient)="goToClient($event)"
    [client]="selectedClients"
  >
  </app-organization-list>
  <app-custom-request-list *ngIf="selectedTab === 'custom-requests'"> </app-custom-request-list>
  <app-extract-invoices *ngIf="selectedTab === 'extract-invoices'"></app-extract-invoices>
  <app-admin-help
    *ngIf="selectedTab === 'help'"
    [question]="selectedQuestion"
  >
  </app-admin-help>
</div>
