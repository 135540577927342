<div class="container">
  <div class="row align-items-center justify-content-center row-container">
    <mat-card class="col-12">
      <div>
        <div class="row align-items-center">
          <div class="col-10 offset-1">
            <h3 class="text-center form-label">{{ 'app.panel.admin.extractInvoices.component.title' | translate }}</h3>
          </div>
        </div>
        <form [formGroup]="formGroup">
          <div class="row justify-content-center">
            <mat-form-field
              class="col-5"
              appearance="outline"
            >
              <mat-label>{{ 'app.panel.admin.extractInvoices.component.from' | translate }}</mat-label>
              <input
                matInput
                [max]="getMaxDate()"
                [matDatepicker]="fromPicker"
                formControlName="from"
                [errorStateMatcher]="errorStateMatcher"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fromPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #fromPicker></mat-datepicker>
              <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field
              class="col-5"
              appearance="outline"
            >
              <mat-label>{{ 'app.panel.admin.extractInvoices.component.to' | translate }}</mat-label>
              <input
                matInput
                [max]="maxDate"
                [matDatepicker]="toPicker"
                formControlName="to"
                [errorStateMatcher]="errorStateMatcher"
                (ngModelChange)="updateFromDate(formGroup.get('to').value)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="toPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #toPicker></mat-datepicker>
              <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="row justify-content-center">
            <mat-form-field
              class="col-10"
              appearance="outline"
            >
              <mat-label>{{ 'app.panel.admin.extractInvoices.component.recipients' | translate }}</mat-label>
              <input
                matInput
                formControlName="email"
                (ngModelChange)="updateEmails()"
              />
              <mat-hint>{{ 'app.panel.admin.extractInvoices.component.recipientsHint' | translate }}</mat-hint>
            </mat-form-field>
          </div>
          <div class="row justify-content-center">
            <div class="col-10 d-flex justify-content-end">
              <button
                mat-raised-button
                color="accent"
                (click)="submit()"
              >
                {{ 'app.panel.admin.extractInvoices.component.submit' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
</div>
