export const environment: {
  production: boolean;
  apiUrl: string;
  stripePublicKey: string;
  features: Record<string, boolean>;
} = {
  production: true,
  apiUrl: 'https://bms-dev.parsec.cloud',
  stripePublicKey: 'pk_test_P4dfuyoLBQtDHKjTiNDH3JH700TT3mCLbE',
  features: {},
};
