<div class="container-fluid subscribe">
  <mat-card>
    <div *ngIf="!isStepperStarted()">
      <div class="row logo justify-content-center text-center">
        <img
          src="/assets/images/parsec-vert.png"
          height="60"
          alt="My.Parsec.Cloud"
        />
      </div>
      <div class="row">
        <h4 class="text-center form-label col-10 offset-1">{{ 'app.subscribe.component.finalizeRegistration' | translate }}</h4>
      </div>
      <div class="row block-splitter">
        <div class="col-12 col-sm-10 offset-sm-1 col-lg-4 offset-lg-0">
          <div class="row no-gutters">
            <div class="form-label col-12 text-uppercase">{{ 'app.subscribe.component.stepsToFollow' | translate }}</div>
          </div>
          <p>{{ 'app.subscribe.component.accountPurpose' | translate }}</p>
          <ul>
            <li>{{ 'app.subscribe.component.consumptionTracking' | translate }},</li>
            <li>{{ 'app.subscribe.component.invoiceManagement' | translate }}.</li>
          </ul>
          <p>{{ 'app.subscribe.component.stepsDetails' | translate }}</p>
          <ul>
            <li>{{ 'app.subscribe.component.customerProfile' | translate }}</li>
            <li>{{ 'app.subscribe.component.dataValidation' | translate }}</li>
            <li>{{ 'app.subscribe.component.finalization' | translate }}</li>
          </ul>
        </div>
        <div class="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-0">
          <div class="row no-gutters">
            <div class="form-label col-12 text-uppercase">{{ 'app.subscribe.component.formulaSummary' | translate }}</div>
          </div>
          <div class="row justify-content-center text-center">
            <div class="col-12 d-flex">
              <div class="col-12">
                <div class="row justify-content-around">
                  <ng-container *ngFor="let formula of formulas">
                    <mat-card
                      class="col-12 col-sm-10 col-md-8 formula-header"
                      *ngIf="formula.active"
                    >
                      <p class="title text-uppercase font-weight-bolder">{{ formula.title | translate }}</p>
                      <p class="subtitle">{{ formula.subtitle }}</p>
                      <p class="price text-uppercase">{{ formula.users.free | translate }}</p>
                      <p class="price-details font-weight-bolder">
                        {{ formula.users.paying | translate }}
                      </p>
                      <p class="price text-uppercase">{{ formula.storage.free | translate }}</p>
                      <p class="price-details font-weight-bolder">
                        {{ formula.storage.paying | translate }}
                      </p>
                      <hr />
                      <button
                        mat-raised-button
                        class="align-self-end"
                        color="accent"
                        (click)="start()"
                      >
                        {{ 'app.subscribe.component.start' | translate }}
                      </button>
                      <mat-list>
                        <mat-list-item
                          class="details"
                          *ngFor="let detail of formula.details"
                        >
                          <mat-icon matListIcon>{{ detail.icon }}</mat-icon>
                          <span matLine>{{ detail.text | translate }}</span>
                        </mat-list-item>
                      </mat-list>
                    </mat-card>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isStepperStarted()">
      <div class="row logo justify-content-center text-center">
        <img
          src="/assets/images/parsec-vert.png"
          height="60"
          alt="My.Parsec.Cloud"
        />
      </div>
      <ng-container *ngIf="!finalized">
        <div class="row">
          <h4 class="text-center form-label col-10 offset-1">{{ 'app.subscribe.component.finalizeRegistration' | translate }}</h4>
        </div>
        <div class="row block-splitter">
          <div class="col-10 offset-1 col-lg-6 offset-lg-0">
            <form
              [formGroup]="profileFormGroup"
              (keydown.enter)="submit(); $event.preventDefault()"
              #profileForm="ngForm"
            >
              <div class="row no-gutters">
                <div class="form-label col-12 text-uppercase">
                  {{ 'app.subscribe.component.customerProfile' | translate }}{{ 'app.global.colon' | translate }} {{ email }}
                </div>
              </div>
              <div class="row">
                <mat-form-field
                  class="col-10 col-sm-6"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.subscribe.component.firstname' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="firstname"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field
                  class="col-10 col-sm-6"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.subscribe.component.lastname' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="lastname"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field
                  class="col-10 col-sm-6"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.subscribe.component.country' | translate }}</mat-label>
                  <mat-select
                    formControlName="country"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                    (ngModelChange)="checkCountry()"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.code"
                    >
                      {{ country.label }}
                    </mat-option>
                  </mat-select>
                  <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field
                  class="col-10 col-sm-6"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.subscribe.component.phone' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="phone"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ getError('phone') }}</mat-error>
                </mat-form-field>
              </div>
              <div class="row individual-or-enterprise justify-content-center">
                <mat-slide-toggle
                  color="accent"
                  class="col-12"
                  formControlName="representCompany"
                  (change)="clearCompanyFields()"
                  required
                >
                  {{ 'app.subscribe.component.representCompany' | translate }}
                </mat-slide-toggle>
              </div>
              <div
                class="row"
                *ngIf="profileFormGroup.get('representCompany').value"
              >
                <mat-form-field
                  class="col-10 col-sm-6"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.subscribe.component.company' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="company"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-hint>{{ 'app.subscribe.component.companyHint' | translate }}</mat-hint>
                  <mat-error>{{ getError('company') }}</mat-error>
                </mat-form-field>
                <mat-form-field
                  class="col-10 col-sm-6"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.subscribe.component.job' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="job"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-error>{{ 'error.mandatoryField' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </form>
          </div>
          <div class="col-10 offset-1 col-lg-6 offset-lg-0">
            <form
              [formGroup]="formulaFormGroup"
              (keydown.enter)="submit(); $event.preventDefault()"
            >
              <div class="row no-gutters">
                <div class="form-label col-12 text-uppercase">{{ 'app.subscribe.component.whenToRegisterCard' | translate }}</div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p>{{ 'app.subscribe.component.freeDisposal' | translate }}</p>
                  <p>{{ 'app.subscribe.component.exceededLimit' | translate }}</p>
                  <mat-radio-group
                    class="-flex font-weight-bold space-top"
                    formControlName="registerCardNow"
                  >
                    <mat-radio-button [value]="true">{{ 'app.subscribe.component.registerCardNow' | translate }}</mat-radio-button
                    ><br />
                    <mat-radio-button [value]="false">{{ 'app.subscribe.component.registerCardLater' | translate }}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row block-splitter">
          <div class="col-10 offset-1 col-lg-6 offset-lg-0">
            <form
              [formGroup]="finalizationFormGroup"
              *ngIf="!finalized"
              (keydown.enter)="submit(); $event.preventDefault()"
              #finalizationForm="ngForm"
            >
              <div class="row no-gutters">
                <div class="form-label col-12 text-uppercase">{{ 'app.subscribe.component.password' | translate }}</div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p>{{ 'app.subscribe.component.choosePassword' | translate }}</p>
                  <p class="font-weight-bold password-info">{{ 'app.subscribe.component.passwordHint' | translate }}</p>
                </div>
              </div>
              <div class="row">
                <mat-form-field
                  class="col-10 col-sm-6"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.subscribe.component.password' | translate }}</mat-label>
                  <input
                    type="password"
                    matInput
                    formControlName="password"
                    [attr.type]="visibility['password'] ? 'text' : 'password'"
                    required
                    (ngModelChange)="checkPassword()"
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-hint [style.color]="['', 'red', 'orange', 'green'][passwordStrength]">
                    {{
                      [
                        '',
                        'app.global.weakPassword' | translate,
                        'app.global.mediumPassword' | translate,
                        'app.global.strongPassword' | translate
                      ][passwordStrength]
                    }}
                  </mat-hint>
                  <mat-icon
                    matSuffix
                    class="visibility"
                    (click)="toggleVisibility('password')"
                  >
                    visibility{{ visibility['password'] ? '' : '_off' }}
                  </mat-icon>
                  <mat-error>{{ getFinalizationError('password') }}</mat-error>
                </mat-form-field>
                <mat-form-field
                  class="col-10 col-sm-6"
                  appearance="outline"
                >
                  <mat-label>{{ 'app.subscribe.component.confirmPassword' | translate }}</mat-label>
                  <input
                    type="password"
                    matInput
                    formControlName="confirmPassword"
                    [attr.type]="visibility['confirmPassword'] ? 'text' : 'password'"
                    required
                    (ngModelChange)="checkConfirmPassword()"
                    [errorStateMatcher]="errorStateMatcher"
                  />
                  <mat-icon
                    matSuffix
                    class="visibility"
                    (click)="toggleVisibility('confirmPassword')"
                  >
                    visibility{{ visibility['confirmPassword'] ? '' : '_off' }}
                  </mat-icon>
                  <mat-hint [style.color]="['', 'red', 'green'][confirmPasswordValidity]">
                    {{
                      [
                        'app.global.confirmPasswordValidity.mustMatch' | translate,
                        'app.global.confirmPasswordValidity.doNotMatch' | translate,
                        'app.global.confirmPasswordValidity.match' | translate
                      ][confirmPasswordValidity]
                    }}
                  </mat-hint>
                  <mat-error>{{ getFinalizationError('confirmPassword') }}</mat-error>
                </mat-form-field>
              </div>
            </form>
          </div>
          <div class="col-10 offset-1 col-lg-6 offset-lg-0">
            <form
              [formGroup]="summaryFormGroup"
              class="summary"
              (keydown.enter)="submit(); $event.preventDefault()"
            >
              <div class="row no-gutters">
                <div class="form-label col-12 text-uppercase">{{ 'app.subscribe.component.dataValidation' | translate }}</div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p>{{ 'app.subscribe.component.checkData' | translate }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-checkbox
                    class="d-flex font-weight-bold space-top"
                    formControlName="dataAccuracy"
                  >
                    {{ 'app.subscribe.component.dataAccuracy' | translate }}
                  </mat-checkbox>
                  <mat-checkbox
                    class="d-flex font-weight-bold no-margin-bottom"
                    formControlName="generalTerms"
                  >
                    {{ 'app.subscribe.component.generalTerms' | translate }}
                    <a
                      [href]="getDocumentUrl('termsAndConditions')"
                      target="_blank"
                      tabindex="0"
                      >{{ 'app.subscribe.component.termsAndConditions' | translate }}</a
                    >
                    {{ 'app.subscribe.component.and' | translate }}
                    <a
                      [href]="getDocumentUrl('privacy')"
                      target="_blank"
                      tabindex="0"
                      >{{ 'app.subscribe.component.privacyPolicy' | translate }}</a
                    >.
                  </mat-checkbox>
                </div>
              </div>
            </form>
            <div class="row justify-content-center buttons">
              <div class="col-12 d-flex justify-content-center">
                <button
                  mat-raised-button
                  color="accent"
                  (click)="previous()"
                >
                  {{ 'app.subscribe.component.abandon' | translate }}
                </button>
                <button
                  mat-raised-button
                  color="accent"
                  (click)="submit()"
                >
                  {{ 'app.subscribe.component.finalize' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="finalized">
        <div class="row">
          <div class="col-10 offset-1">
            <h3 class="text-center form-label">{{ 'app.subscribe.component.accountRegistered' | translate }}</h3>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 logo d-flex justify-content-center align-items-center">
            <mat-icon class="check-icon text-center">check_circle_outline</mat-icon>
          </div>
        </div>
        <div class="row justify-content-center text-center">
          <div class="col-10">
            <p>{{ 'app.subscribe.component.goToCustomerArea' | translate }}</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 d-flex justify-content-center">
            <button
              mat-raised-button
              color="accent"
              (click)="logIn()"
            >
              {{ 'app.subscribe.component.logIn' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
